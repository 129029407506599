// src/logging/Logger.js

class Logger {
    info(message) {
        console.log(message);
    }

    error(message, error) {
        console.error(message, error);
    }

    debug(message) {
        console.debug(message);
    }
}

export default Logger;
