import configData from './config.json';

const getConfig = () => {
  // const environment = 'development';
  const environment = 'production';
  return configData[environment];
};

export const firebaseConfig = getConfig().firebaseConfig;
export const apiBaseUrl = getConfig().apiBaseUrl;
