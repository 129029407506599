import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLanguage } from 'src/LanguageContext'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropDownAptType({ selected, setSelected, updateSelectedService, servicesList, title, choosenServiceId, selectedStaff }) {
    const customizer = useSelector((state) => state.customizer);

    const { language } = useLanguage();


    useEffect(() => {
        if (choosenServiceId) {
            updateSelectedService(choosenServiceId);
            setSelected(servicesList.filter(e => e.id === choosenServiceId)[0]);
        }
        else {
            updateSelectedService(servicesList[0].id);
            setSelected(servicesList[0]);

        }

    }, [selectedStaff]);

    return (
        <Listbox value={selected} onChange={(value) => { updateSelectedService(value.id); setSelected(value) }}>
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 mt-2">{title}</Listbox.Label>
                    <div className="relative mt-2">
                        <Listbox.Button className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-watsPrimary sm:text-sm sm:leading-6 ${customizer.activeDir === 'rtl' ? 'text-right' : 'text-left'}`}>
                            <span className="block truncate">{selected?.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {servicesList && servicesList.map((person) => (
                                    <Listbox.Option
                                        key={person.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? ' bg-watsPrimary text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className={`flex  items-center gap-1 ${language === 'en' ? 'justify-left' : 'justify-right'}`}>

                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {person?.name}
                                                    </span>

                                                    {person.description && <span
                                                        className={classNames(
                                                            ' truncate text-gray-500 text-xs',
                                                            active ? 'text-white' : 'text-gray-500'
                                                        )}
                                                    >
                                                        {person?.description}
                                                    </span>}

                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : ' text-watsPrimary',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
