import { MinusCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import Utils from 'src/book/src/data/Utils';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';

export default function BookingChargeOptions({
  userEvents,
  setUserEvents,
  handleRemoveEvent,
  currency,
}) {
  const { language } = useLanguage();
  const [editIndex, setEditIndex] = useState(null);

  // Toggle edit mode
  const handleEditClick = (index) => {
    if (editIndex === index) {
      // If already in edit mode, exit edit mode
      setEditIndex(null);
    } else {
      // Enter edit mode
      setEditIndex(index);
    }
  };

  // Handle price change
  const handlePriceChange = (index, newPrice) => {
    const updatedEvents = userEvents.map((event, idx) => {
      if (idx === index) {
        return { ...event, price: Number(newPrice) };
      }
      return event;
    });
    setUserEvents(updatedEvents);
  };

  const getCurrencySymbolLocal = (currency) => {
    if (currency === 'ILS') return `₪`;
    if (currency === 'USD') return `$`;
    if (currency === 'EUR') return `€`;
  };

  const currencySymbol = getCurrencySymbolLocal(currency);
  return (
    <div className="py-2">
      {userEvents && userEvents.length > 0 ? (
        userEvents.map((booking, index) => (
          <div key={index} className="flex items-center gap-x-3 mt-2">
            <div className="flex-grow" onClick={() => handleEditClick(index)}>
              <div className="rounded-lg border border-gray-300 bg-white px-6 py-2 shadow-sm  flex items-center space-x-3">
                <div className="flex flex-col min-w-0 flex-1">
                  <button onClick={() => {}} className="focus:outline-none w-full text-left">
                    <p
                      className={`text-sm text-gray-900 ${
                        language === 'en' ? 'text-left' : 'text-right'
                      }`}
                    >
                      {booking.eventTitle}
                    </p>
                    <div className="flex justify-between items-center w-full">
                      <p className="truncate text-xs text-gray-500">{booking.startFormatted}</p>
                      <div className="flex items-center">
                        {editIndex === index ? (
                          <input
                            type="text"
                            value={booking.price || ''}
                            onChange={(e) => handlePriceChange(index, e.target.value)}
                            className="text-xs p-0 text-center w-10 border border-watsPrimary custom-input-focus rounded-sm"
                            onClick={(e) => e.stopPropagation()} // Add this line
                            autoFocus={true}
                            onKeyDown={(e) => {
                              if (
                                !/[\d]/.test(e.key) &&
                                e.key !== 'Backspace' &&
                                e.key !== 'Tab' &&
                                e.key !== 'Escape' &&
                                e.key !== 'Enter'
                              ) {
                                e.preventDefault();
                              }
                            }}
                            // onBlur={() => handleEditClick(index)}
                          />
                        ) : (
                          <>
                            {booking.price == null ? (
                              <div
                                className="text-xs p-0 text-center w-10 border border-watsPrimary flex items-center justify-center h-5 rounded-sm" // Using Tailwind for height
                              >
                                {booking.price || ''}
                              </div>
                            ) : (
                              <p
                                className="truncate text-xs text-gray-500 cursor-pointer"
                                // onClick={() => handleEditClick(index)}
                              >
                                {booking.price}
                              </p>
                            )}
                          </>
                        )}
                        <span className={` ${language === 'en' ? ' ml-1' : 'mr-1'}`}>
                          {currencySymbol}{' '}
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0">
              <button onClick={() => handleRemoveEvent(index)}>
                <div className="rounded-full  flex justify-center items-center ">
                  <MinusCircleIcon className="h-5 w-5  text-red-800 " />
                </div>
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-gray-700 text-center py-0">
          {translate('noChargeableApts', language)}
        </div>
      )}
    </div>
  );
}
