import React, { useState } from 'react';
import { Autocomplete, TextField, Avatar, Chip, styled } from '@mui/material';
import { Box } from '@mui/system';
import { useProducts } from './ProductsContext';
import { translate } from 'src/translate';
import Utils from 'src/book/src/data/Utils';
import { IconX } from '@tabler/icons';
import { useLanguage } from 'src/LanguageContext';
import { Cancel } from '@mui/icons-material';

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#126656 !important', // Ensuring this color takes precedence
    },
    '&:focus': {
      outline: 'none !important', // Force the browser to apply no outline on focus
    },
  },
});

export default function ChooseProducts({
  selectedItems,
  setSelectedItems,
  choosenLang,
  allowBundle = false,
  greyOutOutOfStock = true,
}) {
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null); // Add this line

  const { products } = useProducts();
  const { language } = useLanguage();

  const productsFiltered = allowBundle ? products : products.filter((product) => !product.isBundle);

  const handleSelect = (event, newValue) => {
    if (greyOutOutOfStock && newValue.currentInventory === 0) return;
    setSelectedValue(null); // Set the selected value

    setInputValue(''); // Reset the input field after selection

    if (newValue && !selectedItems.some((item) => item.id === newValue.id)) {
      setSelectedItems([...selectedItems, newValue]);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleDelete = (itemToDelete) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== itemToDelete.id));
  };

  const handleQuantityChange = (event, item) => {
    const newQuantity = parseInt(event.target.value, 10);
    // if (newQuantity === '' || Number.isNaN(newQuantity) || newQuantity < 1) {
    //   return; // Prevent setting non-numerical or negative values
    // }
    setSelectedItems(
      selectedItems.map((selectedItem) =>
        selectedItem.id === item.id ? { ...selectedItem, quantity: newQuantity } : selectedItem,
      ),
    );
  };

  return (
    <div>
      <Autocomplete
        value={selectedValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelect}
        options={productsFiltered.filter((person) => !selectedItems.includes(person))}
        getOptionLabel={(option) => Utils.getLanguageOrDefault(option.title, choosenLang)}
        renderInput={(params) => (
          <CustomTextField {...params} className="w-full bg-white text-lg " variant="outlined" />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id} className={`flex items-center p-2 `}>
            {/* <div className=' opacity-50'> */}

            <Avatar
              className={` ${language === 'en' ? 'mr-2' : 'ml-2'} ${
                greyOutOutOfStock && option.currentInventory === 0 ? ' opacity-30' : ''
              } `}
              src={Utils.getLanguageOrDefault(option.img, choosenLang)}
              alt=""
            />
            <span
              className={`${greyOutOutOfStock && option.currentInventory === 0 ? ' opacity-30' : ''}
              ${language === 'en' ? 'mr-2' : 'ml-2'}
              `}
            >
              {Utils.getLanguageOrDefault(option.title, choosenLang)}
            </span>

            {/* </div> */}

            {greyOutOutOfStock && option.currentInventory === 0 && (
              <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                {translate('soldOut', language)}
              </span>
            )}
          </li>
        )}
        className="mt-2"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        // clearOnEscape

        disableClearable
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 2 }}>
        {selectedItems.map((item) => (
          <div className="flex items-center gap-1.5" key={item.id}>
            <div className="flex justify-between items-center border border-gray-200 rounded-md bg-white  p-2 flex-grow">
              <div className="flex items-center  gap-x-2">
                <img
                  src={Utils.getLanguageOrDefault(item.img, choosenLang)}
                  alt={Utils.getLanguageOrDefault(item.title, choosenLang)}
                  className="w-6 h-6 rounded-full" // Adjust width and height as needed
                />
                <span className="flex-grow text-ellipsis overflow-hidden whitespace-nowrap max-w-52">
                  {Utils.getLanguageOrDefault(item.title, choosenLang)}
                </span>
              </div>
            </div>

            <input
              dir="ltr"
              type="text"
              value={item.quantity || ''}
              min="1" // Minimum quantity
              className="  hide-spinner   rounded-md
                             border-gray-200 w-16 text-center
                             focus:ring-2 focus:ring-inset focus:ring-watsPrimary custom-input-focus "
              placeholder={translate('qty', language)}
              autoComplete="off"
              onChange={(e) => {
                handleQuantityChange(e, item);
                e.stopPropagation();
                // handleQuantityChange(e, item) Uncomment and use as needed
              }}
            />

            <button
              className="bg-transparent border-none"
              onClick={(e) => {
                e.stopPropagation(); // Prevent onClick of the div from firing
                handleDelete(item);
              }}
            >
              <span className="text-gray-500 hover:text-gray-700">
                <IconX className="h-4 w-4" />
              </span>
            </button>
          </div>
        ))}
      </Box>
    </div>
  );
}
