import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { LanguageProvider } from './LanguageContext';
import { AuthProvider } from './views/authentication/AuthContext';
import { NotificationProvider } from './layouts/full/vertical/header/NotificationContext';
import { StatusProvider } from './layouts/full/vertical/header/StatusContext';
import { ChargeProvider } from './components/pages/payments/ChargeContext';
import { ProductsProvider } from './components/pages/Products/ProductsContext';
import { StatsProvider } from './components/pages/stats/StatsContext';

function App() {
  const routing = useRoutes(Router);
  const customizer = useSelector((state) => state.customizer);
  const location = useLocation();

  const theme = ThemeSettings();
  const isAuthRoute = location.pathname === '/auth/login' || location.pathname === '/auth/enterOtp';

  return (
    <AuthProvider>
      <LanguageProvider>
        <NotificationProvider>
          <StatusProvider>
            <ProductsProvider>
              <ChargeProvider>
                <StatsProvider>
                  <ThemeProvider theme={theme}>
                    <RTL direction={customizer.activeDir}>
                      <CssBaseline />
                      {isAuthRoute && (
                        <div
                          id="recaptcha-container-second"
                          className="flex flex-col items-center justify-center"
                        ></div>
                      )}
                      <ScrollToTop>{routing}</ScrollToTop>
                    </RTL>
                  </ThemeProvider>
                </StatsProvider>
              </ChargeProvider>
            </ProductsProvider>
          </StatusProvider>
        </NotificationProvider>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
