import { CheckIcon, HandThumbUpIcon, UserIcon, CalendarDaysIcon } from '@heroicons/react/20/solid'
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';
import { useEvents } from 'src/views/authentication/CustomersContext'
import Scrollbar from '../custom-scroll/Scrollbar';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Bookings({ events }) {

  const { staffInfo } = useEvents();
  const { language } = useLanguage();

  events = events.sort((a, b) => b.startTimestamp - a.startTimestamp);


  return (
    <div className="flow-root">
      <Scrollbar sx={{ maxHeight: { lg: '250px', md: '100vh' } }}>


        {events.length === 0 && <div className='flex items-center justify-center'>
          <p className="text-lg font-medium text-gray-500">{translate('noEventsMessage', language)}</p>
        </div>}

        {events.length > 0 && <ul role="list" className="-mb-8">
          {events.map((event, eventIdx) => (
            <li key={event.eventId}>
              <div className={`relative ${event.type === "upcomingEvent" ? 'pb-14' : 'pb-8'}`}>
                {eventIdx !== events.length - 1 ? (
                  <>
                    <span className={`absolute ${language === 'en' ? 'left-4' : 'right-4'} top-8 -ml-px h-full w-0.5 bg-gray-200`} aria-hidden="true" />
                  </>
                ) : null}
                <div className="relative flex">
                  <div>
                    <span


                      className={classNames(
                        event.type === "upcomingEvent" && 'bg-watsPrimary',
                        event.type === "pastEvent" && 'bg-watsGreyed',
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      {event.type === "upcomingEvent" ?
                        (<CalendarDaysIcon className="h-5 w-5 text-white" aria-hidden="true" />) :
                        (<CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />)
                      }
                      {/* <event.icon className="h-5 w-5 text-white" aria-hidden="true" /> */}
                    </span>
                  </div>
                  <div className={`flex min-w-0 flex-1 justify-between space-x-4 pt-1.5 ${language === 'en' ? 'ml-2' : 'mr-2'}`}>
                    <div>
                      <p className="text-sm text-gray-500">
                        {staffInfo[event.staffId]?.aptOptions[event.aptTypeId]?.title[language]}{' '}{translate('with', language)}{' '}
                        <span className="font-medium text-gray-900">
                          {staffInfo[event.staffId]?.title[language]}</span>
                      </p>
                    </div>
                    <div className={`whitespace-nowrap ${language === 'en' ? 'text-right' : 'text-left'} text-sm text-gray-500`}>
                      <time>{event.startFormatted}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>}
      </Scrollbar>

    </div>
  )
}
