import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { translate } from 'src/translate';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DropdownMenu({ options, selectedOptionId, onChange, language }) {
  if (!options) return <></>;
  const selectedOptionObject = options.find((option) => option.id === selectedOptionId);

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        type="button"
        className="flex items-center gap-x-1.5 rounded-md bg-white px-2 py-1.5 sm:px-3 sm:py-2 text-xs sm:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <span className="max-w-[100px] sm:max-w-none overflow-hidden text-ellipsis whitespace-nowrap">
          {selectedOptionObject
            ? selectedOptionObject.title[language]
              ? selectedOptionObject.title[language]
              : selectedOptionObject.title['en']
            : translate('select', language)}
        </span>
        <ChevronDownIcon className="-mr-1 h-4 w-4 sm:h-5 sm:w-5 text-gray-400" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            language === 'en' ? 'left-0' : 'right-0'
          } z-50 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {options.map((option) => (
              <Menu.Item key={option.id}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-center',
                    )}
                    onClick={() => onChange(option.id)}
                  >
                    {option.title[language] ? option.title[language] : option.title['en']}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
