import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useState, useRef, useEffect } from 'react';
import ColorPickerDropdown from './ColorPickerDropdown';
import { translate } from 'src/translate';
import { useLanguage } from 'src/LanguageContext';
import { PlusCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { useQuestionnaires } from 'src/views/authentication/QuestionnairesContex';
import { Dialog } from '@mui/material';
import { TrashIcon } from '@heroicons/react/24/solid';
import AlertStyled from '../AlertStyled';

function AddEditTag({
  setEditTagOpen,
  customerId,
  currentCustomerTags,
  answerId,
  updateTags,
  addTag,

  // for modify only
  modifyTag,
  tagToModify = null,

  addEditDialogOpen,
  setAddEditDialogOpen,
}) {
  const colors = [
    { id: 1, hex: '#B06161' },
    { id: 2, hex: '#FFCF81' },
    { id: 3, hex: '#A5DD9B' },
    { id: 4, hex: '#7BD3EA' },
    { id: 5, hex: '#756AB6' },
    { id: 6, hex: '#618264' },
  ];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!tagName || tagName === '') {
        return;
      }

      //  const modifyTag = async (operation, tagId, tagName = null, tagColor = null) => {

      if (tagToModify) {
        modifyTag('edit', tagToModify.value, tagName, selectedColor.hex);
        setAddEditDialogOpen(false);
      } else {
        const tagId = addTag(tagName, selectedColor.hex);
        updateTags(customerId, answerId, tagId, currentCustomerTags, 'add');

        setAddEditDialogOpen(false);

        setEditTagOpen(false);
      }
    }
  };

  const [editMode, setEditMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    // tagToModify
    //   ? colors.filter((color) => color.hex === tagToModify.color)[0]
    //   :
    { id: 6, hex: '#618264' },
  );

  const editContainerRef = useRef(null);
  const [tagName, setTagName] = useState('');
  const [removeTagAlertOpen, setRemoveTagAlertOpen] = useState(false);

  useEffect(() => {
    if (tagToModify) {
      setTagName(tagToModify.label);

      const choseenColor = colors.filter((color) => color.hex === tagToModify.color);
      setSelectedColor(choseenColor.length === 1 ? choseenColor[0] : { id: 6, hex: '#618264' });
    } else {
      setTagName('');
      setSelectedColor({ id: 6, hex: '#618264' });
    }
  }, [tagToModify]);

  //   const [addEditOpen, setAddEditOpen] = useState(true);

  const { language } = useLanguage();

  return (
    <Dialog
      open={addEditDialogOpen}
      onClose={() => setAddEditDialogOpen(false)}
      maxWidth="sm"
      PaperProps={{
        style: {
          // minHeight: '25vh', // Adjust this value as needed
          padding: '15px',
        },
      }}
    >
      <AlertStyled
        title={translate('confirmAction', language)}
        description={translate('deleteTagWarning', language)}
        primaryButtonLabel={translate('yes', language)}
        secondaryButtonLabel={translate('no', language)}
        onPrimaryButtonClick={async () => {
          modifyTag('delete', tagToModify.value, tagName, selectedColor.hex);
          setAddEditDialogOpen(false);
        }}
        onSecondaryButtonClick={() => console.log('Action cancelled.')}
        open={removeTagAlertOpen}
        setOpen={setRemoveTagAlertOpen}
      />

      <div className="relative pt-0">
        <div className="flex justify-center font-bold">
          {tagToModify ? translate('modifyTag', language) : translate('addTag', language)}
        </div>
        {tagToModify && tagToModify.isEditable && (
          <div
            className="absolute left-40 top-0 transform translate-x-full"
            // style={{ transform: 'translateX(100%) translateY(-50%)', top: '50%' }}
          >
            <TrashIcon
              onClick={() => {
                setRemoveTagAlertOpen(true);
              }}
              className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
            />
          </div>
        )}
      </div>
      <div className=" px-0 pl-2">
        <div className="p-2 pb-0 flex justify-center items-center">
          <div className="flex justify-center items-center px-2" dir="rtl">
            <ColorPickerDropdown
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
            <input
              type="text"
              className="block  py-2 text-xs rounded-md border-0 ring-1 ring-inset text-center ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary text-gray-900"
              autoFocus
              placeholder={translate('tagName', language)}
              value={tagName}
              onChange={(event) => {
                setTagName(event.target.value);
              }}
              onKeyDown={handleKeyDown}
            />

            <button
              className=" cursor-pointer flex justify-center mr-2"
              onClick={() => {
                if (!tagName || tagName === '') {
                  return;
                }

                if (tagToModify) {
                  modifyTag('edit', tagToModify.value, tagName, selectedColor.hex);
                  setAddEditDialogOpen(false);
                } else {
                  const tagId = addTag(tagName, selectedColor.hex);
                  updateTags(customerId, answerId, tagId, currentCustomerTags, 'add');

                  setAddEditDialogOpen(false);
                  setEditTagOpen(false);
                }
              }}
            >
              <CheckCircleIcon className="h-6 w-6 text-gray-400" />
            </button>
          </div>
        </div>

        {/* <div className=" flex justify-center pt-2">
          <button
            className=" cursor-pointer flex justify-center pb-1"
            onClick={() => {
              if (!tagName || tagName === '') {
                return;
              }

              if (tagToModify) {
                modifyTag('edit', tagToModify.value, tagName, selectedColor.hex);
                setAddEditDialogOpen(false);
              } else {
                const tagId = addTag(tagName, selectedColor.hex);
                updateTags(customerId, answerId, tagId, currentCustomerTags, 'add');

                setAddEditDialogOpen(false);
                setEditTagOpen(false);
              }
            }}
          >
            <CheckCircleIcon className="h-6 w-6 text-gray-400" />
          </button>
        </div> */}
      </div>
    </Dialog>
  );
}

export default AddEditTag;
