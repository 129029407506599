import { useState } from 'react';
import {
  CreditCardIcon,
  KeyIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import BirthdayPicker from './BirthdayPicker';
import { useAuth } from 'src/views/authentication/AuthContext';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';
// import ProfileCard from '../profile/ProfileCard';

export default function CustomerDetails({ user, handleClose, setProfileCardDialogOpen }) {
  const [customerName, setCustomerName] = useState(user.name);

  const { language } = useLanguage();

  const {
    changeCustomerName,
    updateCustomerGenericDetails,
    getGenericDetailsColumnHeader,
    genericDetailsColumns,
  } = useAuth();

  const [genericDetails, setGenericDetails] = useState(user.genericDetails);

  const [errors, setErrors] = useState({});

  // const [profileCardDialogOpen, setProfileCardDialogOpen] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (validateInputs()) {
        if (user.name !== customerName) {
          changeCustomerName(user.id, customerName);
        }
        if (genericDetails) {
          updateCustomerGenericDetails(user.id, genericDetails);
        }
        handleClose();
        setProfileCardDialogOpen(true);
      }
    }
  };

  const handleInputChange = (key, value) => {
    setGenericDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: !value.trim(),
    }));
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!customerName.trim()) {
      newErrors.name = true;
    }
    if (genericDetails) {
      Object.keys(genericDetails).forEach((key) => {
        if (!genericDetails[key].trim()) {
          newErrors[key] = true;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatPhoneNumber = (number) => {
    if (number.startsWith('9725')) {
      number = number.replace(/^972/, '0');
    }
    return number;
  };

  return (
    <>
      <div>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-4">
            <div className="divide-gray-900 border-b border-gray-900/10 pb-2">
              <h3 className=" text-lg text-center font-semibold leading-6 text-gray-900">
                {translate('personalInfo', language)}
              </h3>
            </div>

            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-6 sm:col-span-1 ">
                <label
                  htmlFor="phone-name"
                  className="block text-sm font-medium leading-4 text-gray-900"
                >
                  {translate('phoneNumber', language)}
                </label>

                <input
                  disabled={true}
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  placeholder={formatPhoneNumber(user.number)}
                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary   sm:text-sm sm:leading-6"
                />
              </div>

              <div className="col-span-6 sm:col-span-1">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-4 text-gray-900"
                >
                  {translate('name', language)}
                </label>
                <input
                  onChange={(e) => {
                    setCustomerName(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, name: !e.target.value.trim() }));
                  }}
                  onKeyDown={handleKeyDown}
                  value={customerName}
                  dir="ltr"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className={` ${
                    errors.name ? 'ring-2 ring-red-500' : ''
                  } mt-2 block w-full text-center rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6`}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 border-b border-gray-900/10"></div>

              {/* <div className="col-span-3 sm:col-span-2">

                            <label htmlFor="company-website" className="block text-sm font-medium leading-6 text-gray-900">
                                Birthday
                            </label>
                            <div className=' w-4/5'>

                                <BirthdayPicker />
                            </div>


                        </div>

                        <div className="col-span-6 sm:col-span-3 border-b border-gray-900/10">
                        </div> */}

              {genericDetailsColumns &&
                Object.keys(genericDetailsColumns).map((key) => (
                  <div className="col-span-6 sm:col-span-1" key={key}>
                    <label
                      htmlFor={key}
                      className={`block text-sm ${
                        language === 'en' ? ' text-left' : 'text-right'
                      }  font-medium leading-4 text-gray-900`}
                    >
                      {getGenericDetailsColumnHeader(key, language)}
                    </label>
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      name={key}
                      id={key}
                      value={genericDetails?.[key] ?? ' - '}
                      className={` ${
                        errors[key] ? 'ring-2 ring-red-500' : ''
                      } mt-2 block w-full text-center rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6`}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div
            className={`bg-gray-50 px-4 py-3 ${
              language === 'en' ? 'text-center' : 'text-center'
            }  sm:px-6`}
          >
            <button
              onClick={() => {
                if (validateInputs()) {
                  if (user.name !== customerName) {
                    changeCustomerName(user.id, customerName);
                  }

                  if (genericDetails) {
                    updateCustomerGenericDetails(user.id, genericDetails);
                  }

                  handleClose();

                  setProfileCardDialogOpen(true);
                }
              }}
              type="submit"
              className="inline-flex justify-center rounded-md bg-watsPrimary px-12 py-2 text-sm font-semibold text-white shadow-sm hover:bg-watsPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-watsPrimary"
            >
              {translate('save', language)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
