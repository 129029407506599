import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'; // Adjust import path based on your setup
import { useLanguage } from 'src/LanguageContext';

// DropdownMenu component
const DropDownCircle = ({ buttonClassName = '', items }) => {
  // Helper function to combine classNames
  const classNames = (...classes) => classes.filter(Boolean).join(' ');
  const {language} = useLanguage(); 

  return (
    <Menu as="div" className="relative inline-block text-center">
      <Menu.Button className={`relative inline-flex items-center rounded-md bg-white p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${buttonClassName}`}>
        <span className="sr-only">Open options menu</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute ${language === 'en' ? 'left-0' : 'right-0'}  z-[1500] mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className="py-1">
            {items.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    onClick={item.action}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-left',
                      language === 'en' && 'text-left',
                      language !== 'en' && 'text-right'
                    )}
                  >
                    {item.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDownCircle;
