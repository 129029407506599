import { format } from 'date-fns';
import { translate } from 'src/translate';
import Utils from './Utils';

export default class ScheduleManager {
  constructor(data, language = null) {
    this.data = data;
    this.language = language;
  }

  getStaffList() {
    return Object.keys(this.data.staff).map((staffMember) => ({
      id: staffMember,
      name: this.data.availableOptions[staffMember].title[this.language],
    }));
  }

  getStaffServices(staffMember) {
    const staff = this.data.availableOptions[staffMember];

    return Object.keys(staff.aptOptions).map((serviceId) => {
      // Extract the title with fallback
      const title = staff?.aptOptions[serviceId]?.title[this.language]
        ? staff?.aptOptions[serviceId]?.title[this.language]
        : staff?.aptOptions[serviceId]?.title[
            Object.keys(staff?.aptOptions[serviceId]?.title)[0]
          ] || 'No title available';

      // Check and extract the description
      const descriptionObj = staff?.aptOptions[serviceId]?.description;
      let description = null; // Default to null if description is unavailable
      if (descriptionObj) {
        if (descriptionObj[this.language]) {
          description = descriptionObj[this.language];
        } else {
          description = descriptionObj[Object.keys(descriptionObj)[0]] || null;
        }
      }

      // description = 'Lorem Ipsum'

      return {
        id: serviceId,
        name: title,
        duration: staff?.aptOptions[serviceId]?.durationMins,
        description: description,
      };
    });
  }

  getServiceDetails(staffMember, serviceKey) {
    const services = this.getStaffServices(staffMember);
    return services ? services[serviceKey] : null;
  }

  getServiceTimeSlots(staffMember, serviceKey) {
    const service = this.data.staff[staffMember].apts[serviceKey];
    let schedule = {};

    if (service && service.dates) {
      Object.entries(service.dates).forEach(([date, dateDetails]) => {
        const availableTimes = dateDetails.availableOptions
          ? Object.entries(dateDetails.availableOptions).map(([time, timeDetails]) => ({
              time,
              title: timeDetails.title.en,
            }))
          : [];
        schedule[date] = availableTimes;
      });
    }
    return schedule;
  }

  getServiceDaysSlots(staffMember, serviceKey, language) {
    const availableSlots = this.getServiceTimeSlots(staffMember, serviceKey);
    return Object.keys(availableSlots).map((key) => ({
      key: key.toString(),
      date: new Date(parseInt(key)),
      dateFormatted: format(new Date(parseInt(key)), 'yyyy-MM-dd'),
      dayText:
        language === 'en'
          ? new Date(parseInt(key)).toLocaleDateString(language, { weekday: 'short' })
          : new Date(parseInt(key)).toLocaleDateString(language, { weekday: 'long' }),
    }));
  }

  static getChosenDateFormatted(chosenDate, language) {
    if (/^\d+$/.test(chosenDate)) {
      // If it's a valid number in string format, convert it to a number
      chosenDate = Number(chosenDate);
    }

    const date = new Date(chosenDate);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    // Formatting the date to include Arabic day names but with English numbers
    const formattedDate = new Intl.DateTimeFormat(`en-IL`, options).format(date);
    return formattedDate;
  }

  static getCustomerAlreadyScheduledTxt(chosenDate, language) {
    // Formatting the date to include day names in English with specified format and time
    const formattedDate = Utils.formatMillis(chosenDate, language);

    // Return the message with the formatted date

    return (
      <span>
        <p>
          {translate('alreadyScheduledCustomerMessageP1', language)} <b>{formattedDate}</b>.
        </p>
        <p> {translate('alreadyScheduledCustomerMessageP2', language)}</p>
      </span>
    );
  }

  static getFooterText(data, staffMember, service, language) {
    if (data === null || staffMember === null || service === null) return '';

    const staffName = data.availableOptions[staffMember]?.title[language];
    const serviceName = data.availableOptions[staffMember]?.aptOptions[service]?.title[language];

    return `${staffName} - ${serviceName}`;
  }

  static getChosenTimeFormatted(chosenTime) {
    // Convert the timestamp to a number and create a Date object
    if (/^\d+$/.test(chosenTime)) {
      // If it's a valid number in string format, convert it to a number
      chosenTime = Number(chosenTime);
    }

    const date = new Date(chosenTime);
    // Options for formatting the time
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    // Formatting the time
    const formattedTime = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedTime;
  }

  static isCustomerScheduled(upcomingEvents, customers, customer) {
    const { DateTime } = require('luxon');

    const customerObj = customers.filter((e) => e.number === customer);

    if (customerObj.length === 0 || upcomingEvents === null) {
      return null;
    }

    const customerEvent = upcomingEvents.filter((event) => event.uid === customerObj[0].id);

    if (customerEvent.length === 0) {
      return null;
    }

    // Get current time in Asia/Jerusalem time zone
    const now = DateTime.now().setZone('Asia/Jerusalem');

    // Get event start time from ISO string
    const eventStartTime = DateTime.fromISO(customerEvent[0].startISO, { zone: 'Asia/Jerusalem' });

    // Check if current time is after the event start time
    if (now >= eventStartTime) {
      return null;
    }

    return customerEvent;
  }

  static addOOOHoursToEvents = (eventsByDay, oooHours, specificDatesExceptions) => {
    if (!oooHours) return eventsByDay;
    const { DateTime } = require('luxon');

    Object.keys(eventsByDay).forEach((date) => {
      const dayOfWeek = DateTime.fromISO(date).toFormat('cccc');
      const oooHoursForDay = oooHours[dayOfWeek];

      if (specificDatesExceptions[date]) {
        let specificOOOHours = specificDatesExceptions[date];
        // if (specificDatesExceptions[date].length === 0) {
        //   specificOOOHours.push({ start: '00:00', end: '23:59' })
        // } else {
        //   specificOOOHours = ScheduleManager.findOOOHours(specificDatesExceptions[date]);
        // }

        specificOOOHours.forEach((oooHour) => {
          // Convert start time to UTC ISO string
          const startDateTimeLocal = DateTime.fromISO(`${date}T${oooHour.start}`, {
            zone: 'Asia/Jerusalem',
          });
          const startISO = startDateTimeLocal.toUTC().toISO();

          const oooEvent = {
            start: { dateTime: oooHour.start },
            end: { dateTime: oooHour.end },
            startFormatted: oooHour.start,
            endFormatted: oooHour.end,
            startISO: startISO,
            color: 'pink',
            summary: 'OOO',
          };
          // Add the OOO event to the day's events
          eventsByDay[date].push(oooEvent);
        });
      } else if (oooHoursForDay) {
        oooHoursForDay.forEach((oooHour) => {
          // Convert start time to UTC ISO string
          const startDateTimeLocal = DateTime.fromISO(`${date}T${oooHour.start}`, {
            zone: 'Asia/Jerusalem',
          });
          const startISO = startDateTimeLocal.toUTC().toISO();

          const oooEvent = {
            start: { dateTime: oooHour.start },
            end: { dateTime: oooHour.end },
            startFormatted: oooHour.start,
            endFormatted: oooHour.end,
            startISO: startISO,
            color: 'pink',
            summary: 'OOO',
          };
          // Add the OOO event to the day's events
          eventsByDay[date].push(oooEvent);
        });
      }
    });

    return eventsByDay;
  };

  // given working hours e.g. {start: "9:00", end: "20:00"}
  // this functions finds the complementary, in this example:
  // [{start: "00:00", end: "09:00"}, {start: "20:00", end: "23:59"} ]
  static findOOOHours = (dailyWorkingHours) => {
    dailyWorkingHours.sort((a, b) => a.start.localeCompare(b.start));

    const oooHours = [];
    let prevEnd = '00:00';

    dailyWorkingHours.forEach((interval, index) => {
      if (prevEnd < interval.start) {
        oooHours.push({ start: prevEnd, end: interval.start });
      }
      prevEnd = interval.end;

      if (index === dailyWorkingHours.length - 1 && prevEnd < '23:59' && prevEnd !== '00:00') {
        oooHours.push({ start: prevEnd, end: '23:59' });
      }
    });

    if (dailyWorkingHours.length === 0) {
      oooHours.push({ start: '00:00', end: '23:59' });
    }

    return oooHours;
  };

  static aggregateEventsByDay = (flattenedEvents, staffCalendarInfo) => {
    const { DateTime } = require('luxon');

    let eventsByDay = {};

    // Aggregate events by day
    flattenedEvents.forEach((event) => {
      const eventDay = DateTime.fromISO(event.startISO).toFormat('yyyy-MM-dd');

      if (!eventsByDay[eventDay]) {
        eventsByDay[eventDay] = [];
      }

      eventsByDay[eventDay].push(event);
    });

    eventsByDay = ScheduleManager.addOOOHoursToEvents(
      eventsByDay,
      staffCalendarInfo.oooHours,
      ScheduleManager.formatSpecificDates(staffCalendarInfo.specificOOOHours),
    );

    // Sort events within each day
    Object.keys(eventsByDay).forEach((day) => {
      eventsByDay[day].sort((a, b) => {
        const timeA = DateTime.fromISO(a.startISO);
        const timeB = DateTime.fromISO(b.startISO);
        return timeA - timeB;
      });
    });

    // find first event for each day
    Object.keys(eventsByDay).forEach((day) => {
      const events = eventsByDay[day];
      for (let event of events) {
        if (event.eventId || event.aptTypeId) {
          // Assuming 'id' refers to 'eventId' or 'aptTypeId'
          event.isFirstEvent = true;
          break; // Stop after adding isFirstEvent to the first event with an id
        }
      }
    });

    return eventsByDay;
  };

  static flattenEvents(events, type) {
    const flattenEventsHelper = (events, type) => {
      return Object.values(events).flatMap((userEvents) =>
        Object.values(userEvents).flatMap((event) => {
          // Assuming each event is an object, we're adding or updating the 'type' property
          return { ...event, type: type };
        }),
      );
    };

    let flattenedEventsFuture = flattenEventsHelper(events, type);
    // let flattenedEventsPast = flattenEventsHelper(eventsPast);

    return flattenedEventsFuture;
  }

  // converts from 14/05/2024 to 2024-05-14
  static formatSpecificDates(dateObject) {
    const newObject = {};

    for (const key in dateObject) {
      if (dateObject.hasOwnProperty(key)) {
        const [day, month, year] = key.split('/');
        const newKey = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        newObject[newKey] = dateObject[key];
      }
    }

    return newObject;
  }

  static getCalendarWeeklyEvents(
    events,
    pastEvents,
    selectedStaff,
    staffCalendarInfo,
    selectedWeek,
    customEvents,
  ) {
    const weeklyEvents = [];
    for (const day of selectedWeek) {
      let dayEvents = ScheduleManager.getCalendarEvents(
        events,
        pastEvents,
        selectedStaff,
        staffCalendarInfo,
        format(day.date, 'yyyy-MM-dd'),
        customEvents,
      );

      dayEvents = dayEvents.map((event) => ({
        ...event,
        col: `col-start-${day.col}`,
        hoverBgColor: `hover:bg-${event.color}-50`,
        bgColor: `bg-${event.color}-100`,
        titleColor: `text-${event.color}-700`,
        hoverTimeColor: `text-${event.color}-700`,
      }));

      weeklyEvents.push(...dayEvents);
    }

    return weeklyEvents;
  }

  static getCalendarEvents(
    events,
    pastEvents,
    selectedStaff,
    staffCalendarInfo,
    selectedDay,
    customEvents,
  ) {
    const { DateTime } = require('luxon');

    // let flattenedEvents = ScheduleManager.flattenEvents(events);

    let flattenedEvents = [...(events || []), ...(pastEvents || []), ...(customEvents || [])];

    flattenedEvents = flattenedEvents.filter((event) => event.staffId === selectedStaff);

    flattenedEvents.forEach((event) => {
      if (
        typeof event.startFormatted !== 'undefined' &&
        typeof event.endFormatted !== 'undefined'
      ) {
        return; // Skip to the next iteration
      }

      // Convert start and end times from ISO string to Israel time zone using Luxon
      const startDate = DateTime.fromISO(event.start).setZone('Asia/Jerusalem');
      const endDate = DateTime.fromISO(event.end).setZone('Asia/Jerusalem');

      // Format hours and minutes for startFormatted and endFormatted
      event.startFormatted = startDate.toFormat('HH:mm');
      event.endFormatted = endDate.toFormat('HH:mm');
      event.summary = event.eventTitle;
      event.startISO = event.start;
      event.endISO = event.end;

      event.start = { dateTime: event.startFormatted };
      event.end = { dateTime: event.endFormatted };
      event.description = '';
      event.color = event.eventType === 'breaks' ? 'gray' : 'blue';
    });

    const aggregateEventsByDay = ScheduleManager.aggregateEventsByDay(
      flattenedEvents,
      staffCalendarInfo[selectedStaff],
    );

    // this is a patch in case a day doesnt have any event, we would still show the ooo hours events
    if (!aggregateEventsByDay[selectedDay]) {
      return ScheduleManager.addOOOHoursToEvents(
        { [selectedDay]: [] },
        staffCalendarInfo[selectedStaff].oooHours,
        ScheduleManager.formatSpecificDates(staffCalendarInfo[selectedStaff].specificOOOHours),
      )[selectedDay];
    }
    return aggregateEventsByDay[selectedDay];
  }
}
