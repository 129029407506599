import { Fragment, useEffect, useState } from 'react';
import DialogWats from '../DialogWats';
import DropDownCircle from '../DropDownCircle';
import { Tabs, Tab, Dialog, DialogContent } from '@mui/material';
import { translate } from 'src/translate';
import PaymentsTable from '../PaymentsTable';
import Bookings from '../Bookings';
import { useLanguage } from 'src/LanguageContext';
import { get, off, onValue, ref } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useAuth } from 'src/views/authentication/AuthContext';
import AlertStyled from '../AlertStyled';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import Charge from '../payments/Charge';
import Utils from 'src/book/src/data/Utils';
import QuestionnairesTags from '../Questionnaires/QuestionnairesTags';
import { useQuestionnaires } from 'src/views/authentication/QuestionnairesContex';
import { constant } from 'lodash';
import PendingUserActions from './PendingUserActions';
import { useStatus } from 'src/layouts/full/vertical/header/StatusContext';
import NeedsHelpUserActions from './NeedsHelpUserActions';
import NotesProfileCard from '../Notes/NotesProfileCard';
import Scrollbar from 'src/components/custom-scroll/Scrollbar';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useEvents } from 'src/views/authentication/CustomersContext';
import ScheduleAppointmentWrapper from '../ScheduleAppointmentWrapper';
import EditDetailsDialog from '../editDetials/EditDetails';
import PendingOrder from './PendingOrder';
import { useProducts } from '../Products/ProductsContext';
import ScheduleManager from 'src/book/src/data/ScheduleManager';
import Confetti from 'react-confetti';
import NeedsStatusChange from './NeedsStatusChange';

export default function ProfileCard({ setDialogOpen, dialogOpen, selectedUser, notificationType }) {
  const { language } = useLanguage();
  const { bizId, user, isFeatureEnabled, customers } = useAuth();

  const { calculateCustomerStatus, isAdvancedStatusEnabled } = useStatus();

  const { currentEventsByUser, pastEventsByUser } = useEvents();

  const { isOrderStatusPending } = useProducts();

  const isRamiMollaCustomer = (customerData) => {
    if (
      customerData.tags &&
      customerData.tags.length > 0 &&
      customerData.tags.filter((id) => Object.keys(id)[0] === '-Nz3JXotGkaVoB-yeSiz')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getPreSelectedTab = () => {
    let selectedTab;

    if (notificationType === 'booking') {
      selectedTab = 'bookings';
    } else if (notificationType === 'questionnaire') {
      selectedTab = 'questionnaires';
    } else if (notificationType === 'payment') {
      selectedTab = 'payments';
    } else {
      selectedTab = 'bookings'; // Default fallback
    }

    // console.log('getPreSelectedTab:', notificationType, selectedTab);
    return selectedTab;
  };

  // if we come from notifications, we want to select a tab (questionarries, bookings, payments) base on the notification type

  const [currentTab, setCurrentTab] = useState(getPreSelectedTab());

  useEffect(() => {
    const newTab = getPreSelectedTab();
    // console.log('Updating tab to:', newTab);
    setCurrentTab(newTab);
  }, [notificationType]);

  const [userStatus, setUserStatus] = useState(null);
  const [userFirstAppearance, setUserFirstAppearance] = useState(null);
  const [userLastActivity, setUserLastActivity] = useState(null);

  const [blockUserDialogOpen, setBlockUserDialogOpen] = useState(false);

  const [chargeDialogOpen, setChargeDialogOpen] = useState(false);
  const { getAllUserAvailableTags, tags, availableQuestionnaires } = useQuestionnaires();

  const [showUserNeedsHelp, setShowUserNeedsHelp] = useState(true);

  // const [appoitmentDialogOpen, setAppoitmentDialogOpen] = useState(false);

  // const toggleAppoitmentDialog = () => {
  //     setAppoitmentDialogOpen(!appoitmentDialogOpen);
  // }

  const handleViewChange = (event, newValue) => {
    // console.log('handling view change' + newValue);
    setCurrentTab(newValue);
  };

  const mergeEvents = () => {
    const current =
      currentEventsByUser && selectedUser && currentEventsByUser[selectedUser.id]
        ? currentEventsByUser[selectedUser.id]
        : [];
    const past =
      pastEventsByUser && selectedUser && pastEventsByUser[selectedUser.id]
        ? pastEventsByUser[selectedUser.id]
        : [];

    return [...current, ...past];
  };

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const updatedEvents = mergeEvents();
    setEvents(updatedEvents);
  }, [currentEventsByUser, pastEventsByUser, selectedUser]); // Dependencies array

  // const events = mergeEvents();

  const isUserBlocked = () => userStatus === 'BLACKLISTED';
  const getUserName = () => (selectedUser ? selectedUser.name : '');

  useEffect(() => {
    if (!selectedUser || !selectedUser.id) {
      setUserStatus(null);
      return;
    }
    const dbRef = ref(database, `/users/businesses/${selectedUser.id}/${bizId}/blacklist`);

    const handleChange = (snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val();
        setUserStatus(val.status);
        setUserLastActivity(val.lastChanged);
      } else {
        setUserStatus(null);
      }
    };

    const handleError = (error) => {
      console.error(error);
    };

    if (selectedUser && selectedUser.id && bizId) {
      onValue(dbRef, handleChange, handleError);
    }

    return () => {
      if (selectedUser && selectedUser.id && bizId) {
        off(dbRef, 'value', handleChange);
      }
    };
  }, [selectedUser, bizId]);

  useEffect(() => {
    if (!selectedUser || !selectedUser.id) {
      setUserFirstAppearance(null);
      return;
    }
    const dbRef = ref(database, `/users/businesses/${selectedUser.id}/${bizId}/firstAppearance`);

    const handleChange = (snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val();
        setUserFirstAppearance(val);
      } else {
        setUserFirstAppearance(null);
      }
    };

    const handleError = (error) => {
      console.error(error);
    };

    if (selectedUser && selectedUser.id && bizId) {
      onValue(dbRef, handleChange, handleError);
    }

    return () => {
      if (selectedUser && selectedUser.id && bizId) {
        off(dbRef, 'value', handleChange);
      }
    };
  }, [selectedUser, bizId]);

  function getUserCssClass(userStatus) {
    switch (userStatus) {
      case 'WHITELISTED':
        return 'text-watsPrimary';
      case 'BLACKLISTED':
        return 'text-red-600';

      case 'needsAssistance':
        return 'text-red-600';

      case 'active':
        return 'text-watsPrimary';

      case 'slightlyActive':
        return 'text-orange-600';

      case 'notActive':
        return 'text-red-600';

      default:
        return 'text-watsPrimary';
    }
  }

  function getUserStatusLabel(userStatus, language) {
    switch (userStatus) {
      case 'WHITELISTED':
        return translate('active', language);
      case 'BLACKLISTED':
        return translate('blocked', language);
      default:
        return translate('pending', language);
    }
  }

  const [editUserDialogOpen, seteditUserDialogOpen] = useState(false);

  const [appoitmentDialogOpen, setAppoitmentDialogOpen] = useState(false);

  const toggleAppoitmentDialog = () => {
    setAppoitmentDialogOpen(!appoitmentDialogOpen);
  };

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [showConfetti, setShowConfetti] = useState(false);
  const [hideConfetti, setHideConfetti] = useState(false);

  useEffect(() => {
    if (showConfetti) {
      setHideConfetti(false); // Ensure it's visible without opacity change at start
      const timer = setTimeout(() => {
        setHideConfetti(true); // Begin to fade out after 5 seconds
        setTimeout(() => setShowConfetti(false), 1000); // Hide completely after fade out
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  if (!selectedUser) return <></>;

  const customerScheduledEvents = ScheduleManager.isCustomerScheduled(
    currentEventsByUser && selectedUser && currentEventsByUser[selectedUser.id]
      ? currentEventsByUser[selectedUser.id]
      : [],
    customers,
    selectedUser.number,
  );

  return (
    <>
      {showConfetti && (
        <div className={`confetti-wrapper ${hideConfetti ? 'hide' : ''}`}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}

      <ScheduleAppointmentWrapper
        appoitmentDialogOpen={appoitmentDialogOpen}
        toggleAppoitmentDialog={toggleAppoitmentDialog}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
      />

      <EditDetailsDialog
        open={editUserDialogOpen}
        setOpen={seteditUserDialogOpen}
        user={selectedUser}
        setProfileCardDialogOpen={setDialogOpen}
      />

      {/* <DialogWats isOpen={dialogOpen} setOpen={setDialogOpen}> */}

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="user-profile-title"
        aria-describedby="user-profile-description"
        fullWidth
        maxWidth="xs"
        style={{ zIndex: 1200 }}
        PaperProps={{
          style: {
            minHeight: '25vh', // Adjust this value as needed
            padding: '5px',
          },
        }}
      >
        <DialogContent>
          <Charge
            setDialogOpen={setChargeDialogOpen}
            dialogOpen={chargeDialogOpen}
            customer={selectedUser}
            customerEvents={events}
          />

          <AlertStyled
            title={translate('confirmAction', language)}
            description={
              translate('blockConfirmAction', language) +
              ' ' +
              getUserName() +
              translate('questionMark', language)
            }
            primaryButtonLabel={translate('yes', language)}
            secondaryButtonLabel={translate('no', language)}
            onPrimaryButtonClick={() => {
              executeApiRequest(
                `${apiBaseUrl}/changeUserWhitelistStatus`,
                'POST',
                {
                  bizId: bizId,
                  uid: selectedUser.id,
                  shouldBlock: isUserBlocked() ? false : true,
                },
                user.accessToken,
              )
                .then(() => {
                  const endTime = performance.now();
                })
                .catch((error) => {
                  // Handle error
                  console.error('API call failed: ', error);
                });
            }}
            onSecondaryButtonClick={() => console.log('Action cancelled.')}
            open={blockUserDialogOpen}
            setOpen={setBlockUserDialogOpen}
          />
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center relative">
              <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                {selectedUser ? selectedUser.name : ''}
              </h3>
              <button
                onClick={() => {
                  setDialogOpen(false);
                  seteditUserDialogOpen(true);
                }}
                type="button"
                className="absolute left-full ml-2 h-8 w-8 rounded-full bg-white text-gray-400 focus:outline-none"
                style={{ transform: 'translateY(-50%)', top: '50%' }}
              >
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <p className="text-sm leading-6 text-gray-600">
              {selectedUser ? selectedUser.numberLocal : ''}
            </p>
          </div>

          <div className="flex justify-center gap-2 mt-3">
            <DropDownCircle
              items={[
                {
                  name: translate('whatsapp', language),
                  action: () => {
                    const whatsappURL = `https://wa.me/${selectedUser.number}`;
                    window.open(whatsappURL, '_blank');
                  },
                },
                {
                  name: isUserBlocked()
                    ? translate('unblock', language)
                    : translate('block', language),
                  action: () => {
                    setBlockUserDialogOpen(true);
                  },
                },
                {
                  name: translate('call', language),
                  action: () => {
                    const phoneUrl = `tel:${selectedUser.number}`;
                    window.open(phoneUrl, '_blank');
                  },
                },
              ]}
            />

            {isFeatureEnabled('payments') && (
              <div className=" min-w-24">
                <button
                  type="button"
                  onClick={() => {
                    setChargeDialogOpen(true);
                    // setDialogOpen(false);
                  }}
                  className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  {/* <IconCurrencyDollar className="h-4 w-4 text-watsPrimary font-bold " aria-hidden="true" /> */}
                  {translate('charge', language)}
                </button>
              </div>
            )}

            {isFeatureEnabled('appointments') && (
              <div className="w-32">
                <button
                  onClick={() => {
                    setDialogOpen(false);
                    setSelectedCustomer(selectedUser.number);
                    toggleAppoitmentDialog();
                    // toggleAppoitmentDialog();
                    // setDialogOpen(false);
                  }}
                  type="button"
                  className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-watsPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-watsPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-watsPrimary sm:flex-1"
                >
                  {customerScheduledEvents === null
                    ? translate('book', language)
                    : translate('reschedule', language)}
                </button>
              </div>
            )}
          </div>

          <div className="h-full overflow-y-auto bg-white px-4 py-4">
            <div className="space-y-6">
              {/* <div>
                  
                        <div className="mt-4 flex items-start justify-between">
                            <div>
                                <h2 className="text-base font-semibold leading-6 text-gray-900">
                                    <span className="sr-only">Details for </span>IMG_4985.HEIC
                                </h2>
                                <p className="text-sm font-medium text-gray-500">3.9 MB</p>
                            </div>
                            <button
                                type="button"
                                className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                <span className="absolute -inset-1.5" />
                                <HeartIcon className="h-6 w-6" aria-hidden="true" />
                                <span className="sr-only">Favorite</span>
                            </button>
                        </div>
                    </div> */}
              <div className="px-4">
                {/* <h3 className="font-medium text-gray-900">Information</h3> */}
                <dl className="mt-0 divide-y divide-gray-200 border-b  border-gray-200 ">
                  {
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">{translate('joined', language)}</dt>
                      <dd className="text-gray-900">
                        {userFirstAppearance
                          ? Utils.formatUnixTimestamp(userFirstAppearance, language)
                          : ''}
                      </dd>
                    </div>
                  }

                  {
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">{translate('lastConversation', language)}</dt>
                      <dd className="text-gray-900">
                        {userLastActivity
                          ? Utils.formatUnixTimestamp(selectedUser.latestActivity, language)
                          : ''}
                      </dd>
                    </div>
                  }

                  {
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">{translate('status', language)}</dt>
                      <dd
                        className={` ${
                          isAdvancedStatusEnabled()
                            ? getUserCssClass(calculateCustomerStatus(selectedUser))
                            : getUserCssClass(userStatus)
                        } `}
                      >
                        {isAdvancedStatusEnabled()
                          ? translate(calculateCustomerStatus(selectedUser), language)
                          : getUserStatusLabel(userStatus, language)}
                      </dd>
                    </div>
                  }

                  {/* <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Last modified</dt>
                                <dd className="text-gray-900">June 8, 2020</dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Dimensions</dt>
                                <dd className="text-gray-900">4032 x 3024</dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Resolution</dt>
                                <dd className="text-gray-900">72 x 72</dd>
                            </div> */}
                </dl>
              </div>

              {userStatus === 'PENDING' && (
                <div>
                  <PendingUserActions customer={selectedUser} />
                </div>
              )}

              {isOrderStatusPending(selectedUser.id) && (
                <div>
                  <PendingOrder setProfileCardDialogOpen={setDialogOpen} customer={selectedUser} />
                </div>
              )}

              {calculateCustomerStatus(selectedUser) === 'needsAssistance' && showUserNeedsHelp && (
                <div>
                  {/* <PendingUserActions customer={selectedUser} /> */}
                  <NeedsHelpUserActions
                    customer={selectedUser}
                    setShowUserNeedsHelp={setShowUserNeedsHelp}
                    setShowConfetti={setShowConfetti}
                    setDialogOpen={setDialogOpen}
                  />
                </div>
              )}

              {isRamiMollaCustomer(selectedUser) === true &&
                calculateCustomerStatus(selectedUser) === 'slightlyActive' && (
                  <div>
                    {/* <PendingUserActions customer={selectedUser} /> */}
                    <NeedsStatusChange
                      customer={selectedUser}
                      setShowUserNeedsHelp={setShowUserNeedsHelp}
                      setShowConfetti={setShowConfetti}
                      setDialogOpen={setDialogOpen}
                    />
                  </div>
                )}

              <div className="pt-0">
                {/* <Tabs /> */}

                <div className="bg-white pb-0  rounded-xl w-full flex justify-center">
                  {/* <Box> */}
                  <Tabs
                    value={currentTab}
                    onChange={handleViewChange}
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                    variant="scrollable"
                  >
                    <Tab
                      id="bookings"
                      value="bookings"
                      iconPosition="start"
                      // icon={<IconCalendar size="22" />}
                      label={translate('bookings', language)}
                    />

                    <Tab
                      id="notes"
                      value="notes"
                      iconPosition="start"
                      // icon={<IconCalendar size="22" />}
                      label={translate('notes', language)}
                    />

                    {getAllUserAvailableTags(selectedUser.id).length > 0 && (
                      <Tab
                        id="questionnaires"
                        value="questionnaires"
                        iconPosition="start"
                        // icon={<IconBeach size="22" />}
                        label={translate('questionnaires', language)}
                      />
                    )}

                    <Tab
                      iconPosition="start"
                      id="payments"
                      value="payments"
                      // icon={<IconBeach size="22" />}
                      label={translate('payments', language)}
                    />
                  </Tabs>

                  {/* </Box> */}
                </div>
              </div>
              {currentTab === 'bookings' && <Bookings events={events} />}

              {currentTab === 'payments' && <PaymentsTable customer={selectedUser} />}

              {currentTab === 'notes' && (
                <NotesProfileCard notes={selectedUser.notes || []} customerId={selectedUser.id} />
              )}

              {currentTab === 'questionnaires' && (
                <QuestionnairesTags
                  userTags={getAllUserAvailableTags(selectedUser.id)}
                  tags={tags}
                  availableQuestionnaires={availableQuestionnaires}
                />
              )}
            </div>
          </div>

          {/* </DialogWats> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
