import React, { useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { translate } from 'src/translate';
// import { useLanguage } from 'src/LanguageContext';

const SpinnerOverlay = ({ showWaitingMessage = false, language }) => {
  const loadingRef = useRef(null);
  // const { language } = useLanguage();

  // useEffect(() => {
  //   let count = 0;
  //   const dots = ['.', '..', '...'];
  //   const interval = setInterval(() => {
  //     if (loadingRef.current) {
  //       loadingRef.current.textContent = `${translate('updating', language)}${dots[count % dots.length]}`.padEnd(10, ' '); // Update text content, pad with spaces to maintain width
  //       count++;
  //     }
  //   }, 500);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="fixed top-0 left-0 z-[1500] w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-50">
      {/* <div className={`px-16 py-6 bg-white rounded-xl flex flex-col justify-center items-center w-80`}> */}
      <div className=" z-[1600]">
        <CircularProgress style={{ color: '#126656' }} size="5rem" />
      </div>

      {showWaitingMessage && (
        <span className=" text-white text-xl mt-2">{translate('updatingText', language)}</span>
      )}
      {/* Set a fixed width or use a monospace font to ensure consistent spacing */}
      {/* <span ref={loadingRef} className="mt-4 font-mono" style={{ ...(language === 'en' ? { width: '75px'} : { width: '45px'}) }} >{translate('updating', language)}</span> */}
      {/* <span>ssnsjsjsjsj</span> */}
      {/* </div> */}
    </div>
  );
};

export default SpinnerOverlay;
