import { PencilIcon, MagnifyingGlassIcon, DocumentIcon, ReceiptRefundIcon } from "@heroicons/react/24/solid";
import { useCharge } from "./payments/ChargeContext"
import Scrollbar from "../custom-scroll/Scrollbar";
import Utils from "src/book/src/data/Utils";
import { useLanguage } from "src/LanguageContext";
import { translate } from "src/translate";
import { useState } from "react";
import AlertStyled from "./AlertStyled";
import { executeApiRequest } from "src/book/src/utilities/utilities";
import { apiBaseUrl } from "src/getConfig";
import { useAuth } from "src/views/authentication/AuthContext";

const people = [
  { staff: 'Rabia', service: 'Dog Haircut', date: 'Feb 12, 2024 at 16:00', price: '200₪', status: 'Charge' },
  { staff: 'Rabia', service: 'Dog Haircut', date: 'Jan 7, 2024 at 12:00', price: '200₪', status: 'Paid' },
  { staff: 'Rabia', service: 'Dog Haircut', date: 'Dec 1, 20243 at 08:00', price: '200₪', status: 'Paid' },
  { staff: 'Rabia', service: 'Dog Haircut', date: 'Nov 12, 2023 at 19:00', price: '200₪', status: 'Paid' },
  { staff: 'Rabia', service: 'Dog Haircut', date: 'Jul 22, 2023 at 19:00', price: '200₪', status: 'Paid' },
]

export default function PaymentsTable({ customer }) {

  const { getCustomerInvoices } = useCharge();
  const { bizId, user } = useAuth();

  const { language } = useLanguage();

  const customerInvoices = getCustomerInvoices(customer.id);

  // Refund dialog
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [refundInvoiceId, setRefundInvoiceId] = useState(null);

  const [refundCustomerLoading, setRefundCustomerLoading] = useState(false);



  return (

    <>

      <AlertStyled
        isLoading={refundCustomerLoading}
        setLoading={setRefundCustomerLoading}
        title={translate('refundCustomerTitle', language)}
        description={translate('refundCustomerText', language) + ' '
          + (refundInvoiceId && customerInvoices[refundInvoiceId]
            ? customerInvoices[refundInvoiceId].priceRes + ' ' + '₪' + ' ' + translate('to', language) + ' ' + customer.name + ' ?' : '')

        }
        primaryButtonLabel={translate('yes', language)}
        secondaryButtonLabel={translate('cancel', language)}
        onPrimaryButtonClick={async () => {
          try {
            setRefundCustomerLoading(true);

            const response = await executeApiRequest(`${apiBaseUrl}/staffRefundUser`, 'POST', {
              bizId: bizId,
              uid: customer.id,
              staffUid: user.uid,
              invoiceId: refundInvoiceId,
            }, user.accessToken);

            setRefundCustomerLoading(false);


            // toggleAppoitmentDialog();
            // setSelectedCustomer(null);
            // setSelectedCustomerBooked(null);

            // Handle the response as needed
          } catch (error) {
            // logger.error(error);
            console.log(error)
          } finally {
            const xx = 'dd';
          }
        }}
        onSecondaryButtonClick={() => console.log('Action cancelled.')}
        open={refundDialogOpen}
        setOpen={setRefundDialogOpen}
      />

      <div>

        <Scrollbar sx={{ maxHeight: '40vh' }}>

          <div className="mt-0 flow-root overflow-hidden">
            <div className="mx-auto max-w-7xl">
              <table className="w-full text-left">
                <thead className="bg-white  border-b border-gray-200">
                  <tr>
                    {/* <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-xs font-semibold text-gray-900">
                  Staff
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:table-cell"
                >
                  Service
                </th> */}


                    <th scope="col" className={` ${language === 'en' ? 'text-center' : 'text-center'}  px-3 py-3.5  text-xs font-semibold text-gray-900 table-cell`}
                    >
                      {translate('status', language)}
                    </th>

                    <th scope="col" className={` ${language === 'en' ? 'text-center' : 'text-center'} px-3 py-3.5 text-left text-xs font-semibold text-gray-900 table-cell`}
                    >

                      {translate('priceWord', language)}

                    </th>


                    <th
                      scope="col"
                      className={` ${language === 'en' ? 'text-center' : 'text-center'} px-3 py-3.5 text-left text-xs font-semibold text-gray-900 table-cell`}
                    >
                      {translate('time', language)}

                    </th>

                    <th
                      scope="col"
                      className={` ${language === 'en' ? 'text-center' : 'text-center'} px-3 py-3.5 text-left text-xs font-semibold text-gray-900 table-cell`}
                    >
                      {translate('view', language)}

                    </th>

                    <th
                      scope="col"
                      className={` ${language === 'en' ? 'text-center' : 'text-center'} px-3 py-3.5 text-left text-xs font-semibold text-gray-900 table-cell`}
                    >
                      {translate('refund', language)}

                    </th>




                  </tr>
                </thead>
                <tbody>

                  {

                    !customerInvoices || customerInvoices && Object.keys(customerInvoices).length === 0 ?
                      <tr>
                        <td colSpan="100%" className="text-center py-3  text-gray-500">
                          {translate('noResults', language)}
                        </td>
                      </tr>
                      :
                      customerInvoices && Object.keys(customerInvoices).length > 0 && Object.keys(customerInvoices).map((invoiceId) => (
                        <tr key={customerInvoices[invoiceId].created} className=" border-b border-gray-100">



                          {/* <td className="relative py-4 pr-3 text-xs font-medium text-gray-900">
                    {person.staff}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="hidden px-3 py-4 text-xs text-gray-500 sm:table-cell">{person.service}</td> */}


                          <td className={` ${language === 'en' ? 'text-center' : 'text-center'}  px-3 py-4 text-xs text-gray-500 table-cell`}>

                            <span className={`inline-flex items-center rounded-md 
                      ${customerInvoices[invoiceId].status === 'paid' ? 'bg-green-500/10 text-green-400 ring-green-500/20'
                                : customerInvoices[invoiceId].status === 'refunded' ? 'bg-yellow-500/10 text-yellow-400 ring-yellow-500/20' : 'bg-blue-500/10 text-blue-400 ring-blue-500/20'}  
                      px-2 py-1 text-xs font-medium  ring-1 ring-inset `}>

                              {translate(customerInvoices[invoiceId].status, language)}


                            </span>

                          </td>

                          <td className={` ${language === 'en' ? 'text-center' : 'text-center'} px-3 py-4 text-xs text-gray-500 table-cell`}>

                            {customerInvoices[invoiceId].status === 'refunded' && customerInvoices[invoiceId].priceRes + '₪' + translate('outOf', language) + customerInvoices[invoiceId].totalRes + '₪'}
                            {customerInvoices[invoiceId].status !== 'refunded' && customerInvoices[invoiceId].priceRes + '₪'}

                          </td>


                          <td className={` ${language === 'en' ? 'text-center' : 'text-center'} px-3 py-4 text-xs text-gray-500 table-cell`}>
                            {Utils.formatUnixTimestamp(customerInvoices[invoiceId].time * 1000, language)}</td>

                          <td className="table-cell justify-center items-center px-3 py-4 text-xs text-gray-500 cursor-pointer "
                            onClick={() => {
                              window.open(customerInvoices[invoiceId].hosted_invoice_url, '_blank');


                            }}
                            style={{ textAlign: 'center', verticalAlign: 'middle' }}>


                            <DocumentIcon className="h-5 w-5 mx-auto" aria-hidden="true" />

                          </td>

                          <td className=" justify-center items-center px-3 py-4 text-xs text-gray-500  table-cell "
                            onClick={() => {

                              if (customerInvoices[invoiceId].status === 'paid') {
                                setRefundInvoiceId(invoiceId);
                                setRefundDialogOpen(true);

                              }



                            }} style={{ textAlign: 'center', verticalAlign: 'middle' }}>


                            {customerInvoices[invoiceId].status === 'paid'
                              && <ReceiptRefundIcon className="h-5 w-5 mx-auto cursor-pointer" aria-hidden="true" />}

                          </td>





                        </tr>
                      ))
                  }

                </tbody>
              </table>
            </div>
          </div>

        </Scrollbar>
      </div>
    </>

  )
}
