import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from '../../book/src/firebase';
import SpinnerOverlay from '../SpinnerOverlay';
import { ref, get, onValue, off, update, push, onChildChanged, remove } from 'firebase/database';
import ScheduleManager from 'src/book/src/data/ScheduleManager';
import { useAuth } from './AuthContext';
import Utils from 'src/book/src/data/Utils';
import { useLanguage } from 'src/LanguageContext';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { translate } from 'src/translate';

const QuestionnairesContext = createContext();

function aggregateByUid(events) {
  return events.reduce((acc, currentEvent) => {
    if (!currentEvent.uid) {
      return acc;
    }
    if (!acc[currentEvent.uid]) {
      acc[currentEvent.uid] = [];
    }
    acc[currentEvent.uid].push(currentEvent);

    return acc;
  }, {});
}

function processAnswers(data, language) {
  // Extract the questions from the provided data
  const questions = data.questions;

  // Iterate over each customer
  for (const customerId in data.answers) {
    const customerData = data.answers[customerId];

    // Iterate over each questionnaire for the customer
    for (const questionnaireId in customerData) {
      const questionnaireData = customerData[questionnaireId];

      // Iterate over each set of responses in the questionnaire
      for (const responseSetId in questionnaireData) {
        const responseSet = questionnaireData[responseSetId].questions;

        // Iterate over each question response in the set
        for (const questionId in responseSet) {
          const response = responseSet[questionId];
          const question = questions[questionId];

          // Check if this is an informational message
          if (question?.isInfoMessage) {
            // Delete this answer because it's an informational message
            delete responseSet[questionId];
          } else {
            // Check if there's a value (btn1, btn2, etc.) for the question
            if (response?.hasOwnProperty('val')) {
              // Check if the question is the type with button options
              if (question?.btnOptions) {
                // Find the button option that matches the answer value
                const btnOption = question.btnOptions.find(
                  (option) => option.btnId === response.val,
                );

                // If a matching button option is found, update the response value
                if (btnOption) {
                  response.isButton = true;
                  response.btnOption = response.val;
                  response.val = btnOption.lstr.strings[language]
                    ? btnOption.lstr.strings[language]
                    : btnOption.lstr.strings[Object.keys(btnOption.lstr.strings)[0]];
                }
              } else {
                response.isButton = false;
              }
            }
          }
        }
      }
    }
  }

  // Optionally, you can return the modified data if needed
  return data;
}

function mergeCustomerIDIntoResponses(simplifiedData) {
  // Create a new object to store the final merged data
  let mergedData = {};

  // Iterate over each customer in the simplified data
  for (const customerId in simplifiedData) {
    const responseSets = simplifiedData[customerId];

    // Iterate over each response set for the customer
    for (const responseSetId in responseSets) {
      // Copy the response set to the new structure
      mergedData[responseSetId] = {
        ...responseSets[responseSetId], // Spread the contents of the original response set
        customerId: customerId, // Add the customer ID directly into the response set
      };
    }
  }

  // Return the merged data structure, where each response set includes the customer ID
  return mergedData;
}

function filterParseQuestions(data, questions) {
  // Step 1: Filter out info messages from data
  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => !value.isInfoMessage),
  );

  // Step 2: Simplify the data structure to only contain question strings
  const simplifiedData = Object.fromEntries(
    Object.entries(filteredData).map(
      ([
        key,
        {
          questionName: { strings },
        },
      ]) => [key, strings],
    ),
  );

  // Step 3: Create an empty array and object to hold the results
  const orderedKeys = [];
  const filteredQuestions = {};

  // Step 4: Add results to the array and object in the specified order
  for (const key of questions) {
    if (simplifiedData.hasOwnProperty(key)) {
      orderedKeys.push(key);
      filteredQuestions[key] = simplifiedData[key];
    }
  }

  // Log the ordered keys to verify the order
  // console.log('Ordered Keys:', orderedKeys);

  return { filteredQuestions, orderedKeys };
}

function filterByQuestionnaire(data, questionnaireId) {
  // Create a new object to store the filtered data
  let filteredData = {};

  // Iterate over each customer in the data
  for (const customerId in data.answers) {
    const customerData = data.answers[customerId];

    // Check if the specified questionnaire ID is present for the current customer
    if (customerData.hasOwnProperty(questionnaireId)) {
      // Initialize the customer's filtered data structure if not already done
      if (!filteredData[customerId]) {
        filteredData[customerId] = {};
      }

      // Assign only the relevant questionnaire data to the new filtered data structure
      filteredData[customerId][questionnaireId] = customerData[questionnaireId];
    }
  }

  let simplifiedData = {};

  // Iterate over each customer in the data
  for (const customerId in data.answers) {
    const customerData = data.answers[customerId];

    // Check if the specified questionnaire ID is present for the current customer
    if (customerData.hasOwnProperty(questionnaireId)) {
      // Directly assign the questionnaire data to the customer ID in the new simplified structure
      simplifiedData[customerId] = customerData[questionnaireId];
    }
  }

  // Return the simplified data structure, which omits the questionnaire ID layer
  const answersFinal = mergeCustomerIDIntoResponses(simplifiedData);

  const answersFinalarray = Object.keys(answersFinal).map((key) => ({
    ...answersFinal[key],
    questionnaireId: key,
  }));

  return answersFinalarray;
}

export const QuestionnairesProvider = ({ children }) => {
  const [pastEvents, setPastEvents] = useState(null);
  const [currentEvents, setCurrentEvents] = useState(null);

  const [currentEventsByUser, setCurrentEventsByUser] = useState(null);
  const [pastEventsByUser, setPastEventsByUser] = useState(null);

  const [staffInfo, setStaffInfo] = useState(null);

  const { bizId, accessType, user, getCustomer } = useAuth();
  const { language } = useLanguage();
  const loading = false;

  // Start: Questionarrie
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [orderedQuestions, setOrderedQuestions] = useState([]);

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [availableQuestionnaires, setAvailableQuestionnaires] = useState([]);
  const [tagsPerUser, setTagsPerUser] = useState([]);

  const [tags, setTags] = useState([]);

  const [allQuestionnairesData, setAllQuestionnairesData] = useState({});

  const [answersTagsCount, setAnswersTagsCount] = useState({});

  const [hiddenTagsKeys, setHiddenTagsKeys] = useState({});

  const enrichEvents = (events) => {
    return events.map((event) => {
      const startFormatted = Utils.formatUnixTimestamp(event.startTimestamp, language);
      return {
        ...event,
        startFormatted: startFormatted,
      };
    });
  };

  const updateTags = (customerId, answerId, tagId, tags, operation) => {
    // if there isnt any tag, init an empty array
    if (!tags) {
      tags = [];
    }

    let filteredTags;
    const timeAdded = new Date();

    // if its delete op, we delete the relevant tag
    if (operation === 'delete') {
      filteredTags = tags.filter((tag) => !(tagId in tag));
    }
    // if its add operation, we add to the tags
    else {
      tags.push({ [tagId]: timeAdded.getTime() });
      filteredTags = tags;
    }

    const dbRef = ref(
      database,
      `/questionnaires/${bizId}/answers/${customerId}/${selectedQuestionnaire}/${answerId}/`,
    );

    try {
      update(dbRef, { tags: filteredTags });
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to update tag', error);
    }
  };

  const addTag = (tagName, tagColor) => {
    const timeAdded = new Date();
    const dbRef = ref(database, `/questionnaires/${bizId}/tags`);

    try {
      const tagRef = push(dbRef, {
        createdTimestamp: timeAdded.getTime(),
        name: tagName,
        color: tagColor,
      });
      const tagId = tagRef.key; // Getting the tag ID
      return tagId;
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to update tag', error);
    }
  };

  const modifyTag = async (operation, tagId, tagName = null, tagColor = null) => {
    const dbRef = ref(database, `/questionnaires/${bizId}/tags/${tagId}`);

    try {
      if (operation === 'edit') {
        // Check if both tagName and tagColor are provided for editing
        if (tagName === null || tagColor === null) {
          console.error('Both tag name and color must be provided for editing.');
          return;
        }
        // Perform update operation
        await update(dbRef, { name: tagName, color: tagColor });
        console.log('Tag updated successfully.');
      } else if (operation === 'delete') {
        await update(dbRef, { name: tagName, color: tagColor, isHidden: true });
      } else {
        console.error('Invalid operation. Please specify "edit" or "delete".');
      }
    } catch (error) {
      console.error(`Failed to ${operation} tag`, error);
    }
  };

  const getUserAvailableTags = (tags, answer) => {
    if (!tags) {
      return [];
    }

    const answerKeys = answer ? answer?.map((obj) => Object.keys(obj)[0]) : [];

    if (!answerKeys) {
      return [];
    }

    // Filter tags not in answer and format them in an array of objects
    return tags?.reduce((acc, tag) => {
      if (!answerKeys.includes(tag.value)) {
        const obj = {};
        obj[tag.value] = 1; // Set timestamp to 1
        acc.push(obj);
      }
      return acc;
    }, []);
  };

  const convertQuestionnaireAnswersToDbFormat = (newAnswers) => {
    const converted = {};
    Object.keys(newAnswers).forEach((answerKey) => {
      const answer = newAnswers[answerKey];
      if (answer.isButton) {
        converted[answerKey] = {
          timestamp: answer.timestamp,
          val: answer.btnOption,
        };
      } else {
        converted[answerKey] = {
          timestamp: answer.timestamp,
          val: answer.val,
        };
      }
    });
    return converted;
  };

  const updateCustomerQuestionnaireAnswer = (customerId, questionnaireId, answerId, newAnswers) => {
    const dbRef = ref(
      database,
      `/questionnaires/${bizId}/answers/${customerId}/${questionnaireId}/${answerId}`,
    );

    const convertedAnswers = convertQuestionnaireAnswersToDbFormat(newAnswers);

    try {
      update(dbRef, { questions: convertedAnswers });
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to customer name', error);
    }
  };

  const getUserCurrentTags = (tags, answer) => {
    if (!tags || !answer) {
      return [];
    }

    const answerKeys = answer ? answer.map((obj) => Object.keys(obj)[0]) : [];

    // Filter tags not in answer and format them in an array of objects
    return tags?.reduce((acc, tag) => {
      if (answerKeys.includes(tag.value)) {
        const obj = {};
        obj[tag.value] = {};
        obj['label'] = tag.label;
        obj['color'] = tag.color;
        acc.push(obj);
      }
      return acc;
    }, []);
  };

  const getAllUserAvailableTags = (customerId) => {
    if (!tagsPerUser || tagsPerUser.length === 0) {
      return [];
    }

    const data = tagsPerUser[customerId];

    if (!tagsPerUser || !data) {
      return [];
    }

    const answers = [];

    // Iterate through all questionnaires, ignoring the IDs
    Object.keys(data).forEach((questionnaireKey) => {
      const questionnaire = data[questionnaireKey];

      // Iterate through all entries in the questionnaire, ignoring the IDs
      Object.values(questionnaire).forEach((entry) => {
        // Add all answers from the questions object to the answers array
        if (entry.tags) {
          answers.push({
            tags: entry.tags,
            questions: entry.questions,
            timestamp: entry.timestamp,
            questionnaireKey: questionnaireKey,
          });
        }
        // else {
        //     answers.push({
        //         questions: entry.questions,
        //         timestamp: entry.timestamp,
        //         questionnaireKey: questionnaireKey,
        //     });

        // }
      });
    });

    return answers;

    // const zz = answers;
    // const result = [];
    // const customerAnswers = answers.filter(customer => customer.customerId === customerId);

    // for (const custAnswer of customerAnswers) {
    //     const avlTags = getUserCurrentTags(tags, custAnswer.tags ? custAnswer.tags : []);
    //     result.push({
    //         questionnaireId: custAnswer.questionnaireId,
    //         timestamp: custAnswer.Datetimestamp,
    //         tags: avlTags
    //     });
    // }

    // const xx = availableQuestionnaires;

    // return result;
  };

  React.useEffect(() => {
    const fetchStaffInfo = () => {
      const dbRef = ref(database, `/biz/configs/${bizId}/staff`);

      // Setting up the onValue listener
      onValue(
        dbRef,
        (snapshot) => {
          try {
            if (snapshot.exists()) {
              const info = snapshot.val();
              const daysOfWeek = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ];

              for (const day of daysOfWeek) {
                for (const staff of Object.keys(info)) {
                  if (!info[staff]['workingHours']) {
                    info[staff]['workingHours'] = {};
                  }
                  if (!info[staff]['workingHours'][day]) {
                    info[staff]['workingHours'][day] = [];
                  }
                }
              }

              const yestrday = new Date();
              yestrday.setUTCHours(0, 0, 0, 0);
              yestrday.setDate(yestrday.getDate() - 1);

              for (const staff of Object.keys(info)) {
                if (!info[staff]['specificOOOHours']) {
                  info[staff]['specificOOOHours'] = {};
                } else {
                  info[staff]['specificOOOHours'] = Object.keys(
                    info[staff]['specificOOOHours'],
                  ).reduce((acc, timestamp) => {
                    const utcDate = DateTime.fromMillis(parseInt(timestamp), { zone: 'utc' });

                    const date = utcDate.setZone('Asia/Jerusalem');

                    const formattedDate = date.toFormat('dd/MM/yyyy');

                    if (info[staff]['specificOOOHours'][timestamp].closed) {
                      acc[formattedDate] = [];
                      return acc;
                    }

                    if (date <= yestrday || !info[staff]['specificOOOHours'][timestamp]) {
                      return acc;
                    }

                    acc[formattedDate] = info[staff]['specificOOOHours'][timestamp].map((slot) => ({
                      start: slot.start,
                      end: slot.end,
                    }));

                    return acc;
                  }, {});
                }
              }

              const selectedStaff =
                Object.entries(info).find(([key, value]) => value.uid === user.uid) === undefined
                  ? Object.entries(info)[0][0]
                  : Object.entries(info).find(([key, value]) => value.uid === user.uid)[0];

              info['selectedStaff'] = selectedStaff;

              setStaffInfo(info);
            }
          } catch (error) {
            console.error(error);
          }
        },
        {
          onlyOnce: false,
        },
      );
    };

    if (bizId) {
      fetchStaffInfo();
    }

    // Cleanup function to remove the listener when the component unmounts or bizId changes
    return () => {
      const dbRef = ref(database, `/biz/configs/${bizId}/staff`);
      off(dbRef, 'value');
    };
  }, [bizId, accessType]);

  React.useEffect(() => {
    const fetchStaffInfo = () => {
      const dbRef = ref(database, `/questionnaires/${bizId}/`);

      const handleSnapshot = (snapshot) => {
        // console.log('Parent changed:');

        if (snapshot.exists()) {
          processSnapshot(snapshot);
        }
      };

      const handleChildChanged = (childSnapshot, prevChildKey) => {
        // Assuming you want to process the entire dataset when a child changes:
        // Trigger a fetch or a refresh of the entire dataset.
        // console.log('Child changed:', childSnapshot.key);
        // This could be done by simply calling 'fetchStaffInfo' again, or:
        get(dbRef).then((fullSnapshot) => {
          if (fullSnapshot.exists()) {
            processSnapshot(fullSnapshot);
          }
        });
      };

      const processSnapshot = (snapshot) => {
        try {
          const info = snapshot.val();

          const parsedInfo = processAnswers(info, language);

          setAllQuestionnairesData(parsedInfo);

          const questionarieesIds = Object.keys(info.questionnaireDetails).map(
            (questionnaireId) => ({
              title: info.questionnaireDetails[questionnaireId].questionnaireName.strings,
              id: questionnaireId,
              defaultLocal:
                info.questionnaireDetails[questionnaireId].questionnaireName.defaultLocal,
            }),
          );

          const filteredByQuestionarrie = filterByQuestionnaire(
            parsedInfo,
            selectedQuestionnaire === null ? questionarieesIds[0].id : selectedQuestionnaire,
          );

          setTagsPerUser(parsedInfo.answers);
          let transformedTags = null;

          if (info.tags) {
            transformedTags = Object.keys(info.tags).map((key) => ({
              value: key,
              label: info.tags[key].name,
              color: info.tags[key].color,
              isHidden: info.tags[key].isHidden ?? false,

              isEditable: info.tags[key].isEditable ?? true,
            }));
          }

          setTags(transformedTags);

          if (transformedTags && transformedTags.length > 0) {
            const hiddenTags = transformedTags.filter((tag) => tag.isHidden);

            const hiddenTagsKeys = hiddenTags.reduce((obj, tag) => {
              obj[tag.value] = true; // Set each key to true or could be the tag object itself
              return obj;
            }, {});

            setHiddenTagsKeys(hiddenTagsKeys);
          }

          setAvailableQuestionnaires(questionarieesIds);
          const selectedQId =
            selectedQuestionnaire === null ? questionarieesIds[0].id : selectedQuestionnaire;
          setSelectedQuestionnaire(selectedQId);

          const { filteredQuestions, orderedKeys } = filterParseQuestions(
            parsedInfo.questions,
            parsedInfo.questionnaireDetails[selectedQId].questions,
          );
          const questionsParsed = filteredQuestions;

          setQuestions(questionsParsed);

          setOrderedQuestions(orderedKeys);

          const newAnswers = [...filteredByQuestionarrie];

          setAnswers(newAnswers);
        } catch (error) {
          console.error(error);
        }
      };

      onValue(dbRef, handleSnapshot, {
        onlyOnce: false,
      });

      onChildChanged(dbRef, handleChildChanged, {
        onlyOnce: false,
      });
    };

    if (bizId) {
      fetchStaffInfo();
    }

    return () => {
      const dbRef = ref(database, `/questionnaires/${bizId}/`);
      off(dbRef, 'value');
      off(dbRef, 'child_changed');
    };
  }, [bizId, selectedQuestionnaire]);

  const getCustomerQuestionnaire = (customerId, questionnaireId, answerId) => {
    if (!allQuestionnairesData.answers) return null;

    return allQuestionnairesData?.answers[customerId][questionnaireId][answerId].questions;
  };

  useEffect(() => {
    if (bizId) {
      // Define path references for current and past events
      const currentEventsRef = ref(database, `/events/biz/${bizId}`);
      const pastEventsRef = ref(database, `/events/past/biz/${bizId}`);

      const currentEventListener = onValue(
        currentEventsRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const currentEventsData = ScheduleManager.flattenEvents(
              snapshot.val(),
              'upcomingEvent',
            );
            const enrichedCurrrentEvents = enrichEvents(currentEventsData);
            const currenEventsAggregatedEnriched = aggregateByUid(enrichedCurrrentEvents);

            setCurrentEvents(currentEventsData);
            setCurrentEventsByUser(currenEventsAggregatedEnriched);
          }
        },
        { onlyOnce: false },
      );

      const pastEventListener = onValue(
        pastEventsRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const pastEventsData = ScheduleManager.flattenEvents(snapshot.val(), 'pastEvent');
            const enrichedPastEvents = enrichEvents(pastEventsData);
            const pastEventsAggregated = aggregateByUid(enrichedPastEvents);

            setPastEvents(pastEventsData);
            setPastEventsByUser(pastEventsAggregated);
          }
        },
        { onlyOnce: false },
      );

      return () => {
        off(currentEventsRef, 'value', currentEventListener);
        off(pastEventsRef, 'value', pastEventListener);
      };
    }
  }, [bizId, database]);

  const exportToCsv = (sortedAnswers) => {
    try {
      // Function to collect all unique question keys from the dataset
      const getAllQuestionKeys = () => {
        const allKeys = new Set();
        sortedAnswers.forEach((item) => {
          Object.keys(item.questions).forEach((key) => {
            allKeys.add(key);
          });
        });
        return allKeys;
      };

      const allQuestionKeys = Array.from(getAllQuestionKeys());

      const processedData = sortedAnswers.map((item) => {
        // Fill in each question, using a dash "-" where data is missing

        const filledQuestions = allQuestionKeys.reduce((acc, key) => {
          const headerKey = Utils.getLanguageOrDefault({ strings: questions[key] }, language);

          acc[headerKey] = item.questions[key] ? item.questions[key].val : '-';
          return acc;
        }, {});

        return {
          ...filledQuestions,

          [translate('phoneNumber', language)]: getCustomer(item.customerId)?.number,
          [translate('name', language)]: getCustomer(item.customerId)?.name,
        };
      });

      const csv = Papa.unparse(processedData, {
        header: true,
        delimiter: ',', // You can specify a custom delimiter
      });
      
      const BOM = '\uFEFF';
      const csvBlob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvBlob);

      return csvUrl;
    } catch (err) {
      return null;
    }
  };

  // const [pastEvents, setPastEvents] = useState(null);
  // const [currentEvents, setCurrentEvents] = useState(null);

  // const [currentEventsByUser, setCurrentEventsByUser] = useState(null);
  // const [pastEventsByUser, setPastEventsByUser] = useState(null);

  // const [staffInfo, setStaffInfo] = useState(null);

  // const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  // const [availableQuestionnaires, setAvailableQuestionnaires] = useState(null);

  // count tags

  useEffect(() => {
    const counterObject = {};

    if (tags && tags.length > 0 && answers) {
      tags.forEach((tag) => {
        counterObject[tag.value] = 0;
      });

      answers.forEach((answer) => {
        if (answer.tags && answer.tags.length > 0) {
          answer.tags.forEach((tag) => {
            const tagKey = Object.keys(tag)[0];
            counterObject[tagKey] = counterObject[tagKey] + 1;
          });
        }
      });

      setAnswersTagsCount(counterObject);
    }
  }, [tags, answers]);

  return (
    <QuestionnairesContext.Provider
      value={{
        answers,
        questions,
        pastEvents,
        currentEvents,
        currentEventsByUser,
        pastEventsByUser,
        staffInfo,
        availableQuestionnaires,
        selectedQuestionnaire,
        setSelectedQuestionnaire,
        tags,
        updateTags,
        addTag,
        getUserAvailableTags,
        getAllUserAvailableTags,
        getCustomerQuestionnaire,
        allQuestionnairesData,
        updateCustomerQuestionnaireAnswer,
        exportToCsv,
        answersTagsCount,
        modifyTag,
        hiddenTagsKeys,
        orderedQuestions,
      }}
    >
      {!loading ? children : <SpinnerOverlay />}
    </QuestionnairesContext.Provider>
  );
};

export const useQuestionnaires = () => useContext(QuestionnairesContext);
