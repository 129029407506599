import { useNavigate } from "react-router";
import { useLanguage } from "src/LanguageContext"
import { executeApiRequest } from "src/book/src/utilities/utilities";
import { apiBaseUrl } from "src/getConfig";
import { translate } from "src/translate";
import { useAuth } from "src/views/authentication/AuthContext";

export default function PendingOrder({ customer, setProfileCardDialogOpen }) {
    const { language } = useLanguage();
    const { bizId, user } = useAuth();

    const navigate = useNavigate();

    const handleRedirect = (searchValue) => {
        // Navigate to the /search route and include the search parameter
        navigate(`/manage/orders?search=${encodeURIComponent(searchValue)}`);
    };


    return (
        <div className='py-3 text-sm rounded-lg border-2 border-dashed border-gray-300'>

            <div className="flex justify-center ">
                <p className="text-sm ">
                    <span className="font-medium text-gray-900">
                        {' '} {customer?.name}
                    </span>
                    <span className="text-gray-500">
                        {' '} {translate('viewPendingOrder', language)}
                    </span>

                </p>
            </div>

            <div className="flex justify-center gap-2 mt-3">
                <div className=' w-24'>
                    <button
                        onClick={() => {
                            handleRedirect(customer.number);
                            setProfileCardDialogOpen(false);


                        }}
                        type="button"
                        className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-watsPrimary px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-watsPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-watsPrimary sm:flex-1"
                    >
                        {translate('view', language)}
                    </button>
                </div>

                {/* <div className='w-16'>
                    <button
                        type="button"
                        onClick={() => {
                            executeApiRequest(`${apiBaseUrl}/changeUserWhitelistStatus`, 'POST', {
                                bizId: bizId,
                                uid: customer?.id,
                                shouldBlock: true
                            }, user.accessToken)
                                .then(() => {
                                    const endTime = performance.now();
                                })
                                .catch(error => {
                                    console.error("API call failed: ", error);
                                });

                        }}
                        className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        {translate('block', language)}

                    </button>
                </div> */}
            </div>

        </div>
    );
}
