import React, { createContext, useContext, useEffect, useState } from 'react';
import { ref, set, get, update, off, onValue } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useAuth } from 'src/views/authentication/AuthContext';
import axios from 'axios'; // Assuming axios for HTTP requests
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import Utils from 'src/book/src/data/Utils';
import { useLanguage } from 'src/LanguageContext';

const ChargeContext = createContext();

export const ChargeProvider = ({ children }) => {
  const { bizId, user } = useAuth();

  const [invoices, setInvoices] = useState({});
  const [bizInvoices, setBizInvoices] = useState({});
  const [pendingInvoices, setPendingInvoices] = useState({});

  const { language } = useLanguage();

  // Function to charge a user
  const staffChargeUser = async (uid, lineItems) => {
    try {
      console.log(JSON.stringify(lineItems, 2));
      const response = await executeApiRequest(
        `${apiBaseUrl}/staffChargeUser`,
        'POST',
        {
          uid,
          bizId,
          staffUid: user.uid,
          lineItems,
        },
        user.accessToken,
      );

      const { invoiceId, url } = response;

      return { invoiceId, url };
    } catch (error) {
      console.error('Failed to charge user:', error);
      throw error;
    }
  };

  const getAppoitmentsLineItems = (events, currency) => {
    if (!events || events.length === 0) {
      return [];
    }

    const appointmentsLineItmes = events.map((event) => {
      return {
        description: event.eventTitle,
        quantity: 1,
        unitPrice: event.price,
        currency: currency,
        itemType: 'booking',
        eventId: event.eventId,
      };
    });
    return appointmentsLineItmes;
  };

  const getProductsLineItmes = (products, currency) => {
    if (!products || products.length === 0) {
      return [];
    }

    const productsLineItmes = products.map((product) => {
      return {
        description: Utils.getLanguageOrDefault(product.title),
        quantity: product.quantity || 1,
        unitPrice: product.price,
        currency: currency,
        itemType: 'product',
        prodId: product.id,
      };
    });
    return productsLineItmes;
  };

  const getCustomLineItems = (customProducts, currency) => {
    if (!customProducts || customProducts.length === 0) {
      return [];
    }

    const customLineItmes = customProducts.map((product) => {
      return {
        description: product.description,
        quantity: product.quantity,
        unitPrice: product.price,
        currency: currency,
        itemType: 'custom',
      };
    });
    return customLineItmes;
  };

  const enrichInvoice = (invoice) => {
    invoice.time = invoice.created;

    // Determine the status based on conditions
    if (invoice.amount_refunded > 0) {
      invoice.status = 'refunded';
      invoice.priceRes = invoice.amount_refunded / 100;

      invoice.totalRes = invoice.total / 100;
      invoice.time = invoice.refundTimestamp;
    } else if (invoice.paid) {
      invoice.status = 'paid';
      invoice.priceRes = invoice.total / 100;
    } else {
      invoice.status = 'paid'; // default or existing statu
      invoice.priceRes = invoice.total / 100;
    }

    // Additional properties can be added here in the future
  };

  useEffect(() => {
    const fetchInvoices = (path, setInvoices, enrich = true) => {
      const dbRef = ref(database, path);

      const onValueChange = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const newInvoicesData = snapshot.val();
            const updatedInvoicesData = {};
            Object.keys(newInvoicesData).forEach((customerId) => {
              updatedInvoicesData[customerId] = {};
              const customerInvoices = newInvoicesData[customerId];
              Object.keys(customerInvoices).forEach((invoiceId) => {
                const invoice = customerInvoices[invoiceId];
                if (enrich) {
                  enrichInvoice(invoice);
                } else {
                  // Update status without overriding other properties
                  invoice.status = 'pending';
                  invoice.priceRes = invoice.total / 100;
                  invoice.time = invoice.created;
                }
                updatedInvoicesData[customerId][invoiceId] = invoice;
              });
            });
            setInvoices(updatedInvoicesData);
          }
        },
        (error) => {
          console.error(error);
        },
      );

      return () => off(dbRef, 'value', onValueChange);
    };

    let unsubscribeBiz;
    let unsubscribePending;

    if (bizId) {
      // Fetching business-specific invoices
      unsubscribeBiz = fetchInvoices(`/billing/invoices/biz/${bizId}`, setBizInvoices);

      // Fetching pending invoices
      unsubscribePending = fetchInvoices(
        `/billing/invoices/pending/${bizId}`,
        setPendingInvoices,
        false,
      );
    }

    // Cleanup function for useEffect
    return () => {
      if (unsubscribeBiz) unsubscribeBiz();
      if (unsubscribePending) unsubscribePending();
    };
  }, [bizId]);

  const getAllInvoices = () => {
    const combinedInvoices = { ...bizInvoices };

    for (const [customerId, customerInvoices] of Object.entries(pendingInvoices)) {
      if (!combinedInvoices[customerId]) {
        combinedInvoices[customerId] = {};
      }
      combinedInvoices[customerId] = {
        ...combinedInvoices[customerId],
        ...customerInvoices,
      };
    }

    return combinedInvoices;
  };

  const getInvoice = (customerId, invoiceId) => {
    const allInvoices = getAllInvoices();

    return allInvoices[customerId][invoiceId];
  };

  function sortInvoicesByTime(invoices) {
    // Convert object to array
    const entries = Object.entries(invoices);

    // Sort by 'time' attribute
    entries.sort((a, b) => b[1].time - a[1].time);

    // Convert back to object and return
    return Object.fromEntries(entries);
  }

  const getCustomerInvoices = (customerId) => {
    const allInvs = getAllInvoices();
    if (allInvs[customerId]) {
      return sortInvoicesByTime(allInvs[customerId]);
    }
    return null;
  };

  // const staffRefundUser = async (uid, invoiceId) => {
  //     try {
  //         const response = await axios.post('/api/staffRefundUser', {
  //             uid,
  //             bizId,
  //             staffUid,
  //             invoiceId
  //         });
  //         const invoiceRef = ref(database, `billing/invoices/biz/${bizId}/${uid}/${invoiceId}`);
  //         const invoiceSnapshot = await get(invoiceRef);
  //         if (invoiceSnapshot.exists()) {
  //             const invoiceData = invoiceSnapshot.val();
  //             await update(invoiceRef, {
  //                 amount_refunded: invoiceData.total,
  //                 refundTimestamp: Date.now()
  //             });
  //         }
  //     } catch (error) {
  //         console.error('Failed to refund user:', error);
  //         throw error;
  //     }
  // };

  return (
    <ChargeContext.Provider
      value={{
        staffChargeUser,
        getAppoitmentsLineItems,
        getCustomLineItems,
        getAllInvoices,
        getInvoice,
        getCustomerInvoices,
        getProductsLineItmes,
      }}
    >
      {children}
    </ChargeContext.Provider>
  );
};

export const useCharge = () => useContext(ChargeContext);
