import { Fragment, useEffect, useRef, useState } from 'react';
import DialogWats from '../DialogWats';
import DropDownCircle from '../DropDownCircle';
import { Tabs, Tab } from '@mui/material';
import { translate } from 'src/translate';
import Bookings from '../Bookings';
import { useLanguage } from 'src/LanguageContext';
import { get, off, onValue, ref } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useAuth } from 'src/views/authentication/AuthContext';
import AlertStyled from '../AlertStyled';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import Toggle from 'src/components/material-ui/components/Toggle';
import { Transition } from '@headlessui/react';
import BookingChargeOptions from './BookingChargeOptions';
import CheckBox from 'src/components/material-ui/components/CheckBox';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ChargeBooking({
  bookingToggle,
  setBookingToggle,
  userEvents,
  handleRemoveEvent,
  setUserEvents,
  currency,
}) {
  const { language } = useLanguage();
  const ref = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  useEffect(() => {
    if (bookingToggle && ref.current) {
      setMaxHeight(`${ref.current.scrollHeight}px`);
    } else {
      setMaxHeight('0px');
    }
  }, [bookingToggle]);

  return (
    <div
      className={classNames(
        'rounded-lg   p-4 transition-all',
        !bookingToggle && 'border border-gray-200',
        bookingToggle && 'bg-gray-100',
      )}
      style={{ maxHeight: `calc(${maxHeight} + 4rem)` }} // 4rem accounts for padding and any other static height
    >
      <CheckBox
        enabled={bookingToggle}
        setEnabled={setBookingToggle}
        title={translate('bookings', language)}
      />

      <div
        ref={ref}
        className="overflow-hidden transition-all duration-600 ease-in-out"
        style={{ maxHeight }}
      >
        {bookingToggle && (
          <BookingChargeOptions
            handleRemoveEvent={handleRemoveEvent}
            setUserEvents={setUserEvents}
            userEvents={userEvents}
            currency={currency}
          />
        )}
      </div>
    </div>
  );
}
