import React, { createContext, useContext, useEffect, useState } from 'react';
import { ref, onValue, get, set, remove, off, update } from 'firebase/database';
import { useAuth } from 'src/views/authentication/AuthContext';
import { database } from 'src/book/src/firebase';
import { CalendarDaysIcon, NewspaperIcon, UsersIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useLanguage } from 'src/LanguageContext';
import { enUS, ar, he } from 'date-fns/locale';

const StatsContext = createContext();

export const StatsProvider = ({ children }) => {
  const { bizId } = useAuth();
  const [stats, setStats] = useState([]);

  const [availableDates, setAvailableDates] = useState([]);

  const { language } = useLanguage();

  useEffect(() => {
    if (!bizId) {
      setStats([]);
      setAvailableDates([]); // Also reset availableDates when there's no business ID

      return;
    }

    let chosenLocale = enUS;

    // Adjust locale based on language context
    switch (language) {
      case 'en':
        chosenLocale = enUS;
        break;
      case 'ar':
        chosenLocale = ar;
        break;
      case 'heb':
        chosenLocale = he;
        break;
      default:
        chosenLocale = enUS; // Default to English if language is unsupported
    }

    const statsRef = ref(database, `/biz/stats/${bizId}/mainPage`);
    const dateEntries = [];

    const onValueChange = onValue(
      statsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const statsVal = snapshot.val();
          const formattedStats = Object.keys(statsVal).reduce((acc, key) => {
            const monthData = statsVal[key];

            if (!monthData.yearMonth) {
              return acc;
            }

            // Extract year and month from the yearMonth field
            const year = monthData.yearMonth.substring(0, 4);
            const month = monthData.yearMonth.substring(4, 6);
            const date = new Date(year, month - 1); // Month is 0-indexed in JavaScript Date

            // Get current month names
            const dateStringEn = format(date, 'MMM', { locale: enUS });
            const dateStringAr = format(date, 'MMM', { locale: ar });
            const dateStringHeb = format(date, 'MMM', { locale: he });

            // Calculate previous month by subtracting one month
            date.setMonth(date.getMonth() - 1);

            // Get previous month names
            const prevDateStringEn = format(date, 'MMM', { locale: enUS });
            const prevDateStringAr = format(date, 'MMM', { locale: ar });
            const prevDateStringHeb = format(date, 'MMM', { locale: he });

            dateEntries.push({
              id: monthData.yearMonth,
              defaultLocal: 'en',
              title: {
                ar: dateStringAr,
                en: dateStringEn,
                heb: dateStringHeb,
              },
            });

            const monthStats = [
              {
                id: 'newUsers',
                isHidden: monthData.new_users ? false : true,
                name: 'newUsers',
                title: 'usersTotalNumber',

                stat: monthData.new_users ? monthData.new_users?.toString() : null,
                icon: UsersIcon, // Make sure to import these icons
                change: monthData.new_users_percent_change
                  ? monthData.new_users_percent_change?.toFixed(2) + '%'
                  : null,
                changeType: monthData.new_users_percent_change
                  ? monthData.new_users_percent_change >= 0
                    ? 'increase'
                    : 'decrease'
                  : null,
                prevMonthStat: monthData.prev_month_new_users
                  ? monthData.prev_month_new_users
                  : null,
                  total: monthData.total_users ? monthData.total_users : null,

                year,
                month,
                yearMonth: monthData.yearMonth,
                monthName: {
                  ar: dateStringAr,
                  en: dateStringEn,
                  heb: dateStringHeb,
                },
                prevMonthName: {
                  ar: prevDateStringAr,
                  en: prevDateStringEn,
                  heb: prevDateStringHeb,
                },
              },
              {
                id: 'bookings',
                isHidden: monthData.num_bookings ? false : true,
                name: 'bookings',
                title: 'bookingsTotalNumber',
                stat: monthData.num_bookings ? monthData.num_bookings?.toString() : null,
                icon: CalendarDaysIcon,
                change: monthData.num_bookings_percent_change
                  ? monthData.num_bookings_percent_change?.toFixed(2) + '%'
                  : null,
                changeType: monthData.num_bookings_percent_change
                  ? monthData.num_bookings_percent_change >= 0
                    ? 'increase'
                    : 'decrease'
                  : null,
                prevMonthStat: monthData.prev_month_num_bookings
                  ? monthData.prev_month_num_bookings
                  : null,
                total: monthData.total_bookings ? monthData.total_bookings : null,
                year,
                month,
                yearMonth: monthData.yearMonth,
                monthName: {
                  ar: dateStringAr,
                  en: dateStringEn,
                  heb: dateStringHeb,
                },
                prevMonthName: {
                  ar: prevDateStringAr,
                  en: prevDateStringEn,
                  heb: prevDateStringHeb,
                },
              },
              {
                id: 'questionnairesAns',
                isHidden: monthData.num_questionnaire_answers ? false : true,
                name: 'questionnairesAns',
                title: 'questionnairesTotalNumber',
                stat: monthData.num_questionnaire_answers
                  ? monthData.num_questionnaire_answers?.toString()
                  : null,
                icon: NewspaperIcon,
                change: monthData.num_questionnaire_answers_percent_change
                  ? monthData.num_questionnaire_answers_percent_change?.toFixed(2) + '%'
                  : null,
                changeType: monthData.num_questionnaire_answers_percent_change
                  ? monthData.num_questionnaire_answers_percent_change >= 0
                    ? 'increase'
                    : 'decrease'
                  : null,
                prevMonthStat: monthData.prev_month_num_questionnaire_answers
                  ? monthData.prev_month_num_questionnaire_answers
                  : null,
                total: monthData.total_questionnaires ? monthData.total_questionnaires : 5,

                year,
                month,
                yearMonth: monthData.yearMonth,
                monthName: {
                  ar: dateStringAr,
                  en: dateStringEn,
                  heb: dateStringHeb,
                },
                prevMonthName: {
                  ar: prevDateStringAr,
                  en: prevDateStringEn,
                  heb: prevDateStringHeb,
                },
              },
            ];
            return [...acc, ...monthStats];
          }, []);
          setStats(formattedStats);
          setAvailableDates(dateEntries);
        } else {
          setStats([]);
          setAvailableDates([]);
        }
      },
      (error) => {
        console.error('Firebase read failed: ', error);
        setStats([]);
      },
    );

    // Return function to clean up the subscription
    return () => {
      off(statsRef, 'value', onValueChange);
    };
  }, [bizId, database, language]);

  const getStatsPerMonth = (yearMonth) => {
    const res = stats.filter((stat) => stat.yearMonth === yearMonth);
    return res;
  };

  return (
    <StatsContext.Provider value={{ stats, availableDates, getStatsPerMonth }}>
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => useContext(StatsContext);
