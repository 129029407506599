import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useLanguage } from "src/LanguageContext";
import { translate } from "src/translate";

export default function DateNavigator({ next, previous, today }) {

    const { language } = useLanguage();
    return (
        <div className="relative flex items-center rounded-md bg-white shadow-sm">
            <button
                onClick={previous}
                type="button"
                className="flex h-7 sm:h-9 w-7 sm:w-9 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            >
                <span className="sr-only">Previous week</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
                onClick={today}
                type="button"
                className="flex h-7  sm:h-9 w-auto items-center justify-center border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative"
            >
                {translate('today', language)}
            </button>
            <button
                onClick={() => next()}
                type="button"
                className="flex h-7 sm:h-9 w-7 sm:w-9 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            >
                <span className="sr-only">Next week</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    )

}