import { Fragment, useState } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { ShieldCheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon, QuestionMarkCircleIcon, StarIcon } from '@heroicons/react/20/solid'
import { translate } from 'src/translate'
import { useLanguage } from 'src/LanguageContext'
import { executeApiRequest } from 'src/book/src/utilities/utilities'
import { useAuth } from 'src/views/authentication/AuthContext'
import { apiBaseUrl } from 'src/getConfig'
import { CircularProgress } from '@mui/material'
import DialogWats from './DialogWats'
import AlertStyled from './AlertStyled'
import Utils from 'src/book/src/data/Utils'
import SpinnerOverlay from 'src/views/SpinnerOverlay'

export default function AddCustomer({ open, setOpen, setSelectedCustomer, toggleAppoitmentDialog }) {

    const { language } = useLanguage();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('IL');

    const [name, setName] = useState('');

    const { bizId, user } = useAuth();

    const [invalidNumber, setInvalidNumber] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    return (
        <DialogWats isOpen={open} setOpen={setOpen}>

            {isLoading && <SpinnerOverlay />
            }
            {/* <AlertStyled
                title={translate('invalidPhoneNumberTitle', language)}
                description={translate('invalidPhoneNumberMessage', language)}
                primaryButtonLabel={translate('ok', language)}
                secondaryButtonLabel={null}
                onPrimaryButtonClick={() => console.log('Primary action selected.')}
                onSecondaryButtonClick={() => console.log('Action cancelled.')}
                open={invalidNumber}
                setOpen={setInvalidNumber}
            /> */}


            <div className="grid w-full grid-cols-1 items-center gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8 px-5">

                <div className="sm:col-span-12 lg:col-start-2 lg:col-span-10">

                    <div className="relative">
                        <label
                            htmlFor="name"
                            className={`absolute -top-2 ${language === 'en' ? 'left-2' : 'right-2'} inline-block bg-white px-1 text-xs font-medium text-gray-900`}
                        >
                            {translate('name', language)}
                        </label>
                        <input
                            type="text"
                            name="naaame"
                            id="naaame"
                            className="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6"
                            placeholder={translate('jane', language)}
                            value={name}
                            autoComplete='off'
                            onChange={(event) => {
                                setName(event.target.value)
                            }}
                        />
                    </div>


                    <div className='mt-2'>
                        <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                            {translate('phoneNumber', language)}
                        </label>

                        <div className="relative  rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="country" className="sr-only">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    autoComplete="country"
                                    onChange={(event) => {
                                        setCountryCode(event.target.value);
                                    }}
                                    className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm"
                                >
                                    <option>IL</option>
                                    <option>US</option>
                                </select>
                            </div>
                            <input
                                type="tel"
                                name="fooooone"
                                id="fooooone"
                                className={`block w-full rounded-md border-0 py-1.5  text-center text-gray-900 ring-1 ring-inset ${invalidNumber ? 'ring-red-400' : 'ring-gray-300'}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6`}
                                placeholder="0506469989"
                                value={phoneNumber}
                                onChange={(event) => {
                                    setPhoneNumber(event.target.value);
                                    if (invalidNumber) {
                                        setInvalidNumber(false);
                                    }
                                }}
                            />

                      

                        </div>

                        {invalidNumber && <div className='mt-0'>
                                <label htmlFor="invalidPhone" className={`block text-xs ${language === 'en' ? 'text-left' : 'text-right'}  font-medium  text-red-400`}>
                                    {translate('invalidPhoneNumberTitle', language)}
                                </label>
                            </div>}
                    </div>

                    <div className='mt-4 flex justify-center'>
                        <button
                            onClick={
                                async () => {
                                    setIsLoading(true);
                                    const code = countryCode === 'IL' ? '972' : '1';
                                    const validationRes = Utils.validatePhoneNumber('+' + code + phoneNumber);

                                    if (!validationRes) {
                                        setInvalidNumber(true);
                                        setIsLoading(false);

                                        return;
                                    }

                                    const response = await executeApiRequest(`${apiBaseUrl}/staffRegisteredUser`, 'POST', {
                                        phoneNumber: code + phoneNumber.substring(1),
                                        displayName: name,
                                        bizId: bizId,
                                    }, user.accessToken);

                                    setSelectedCustomer(code + phoneNumber.substring(1));

                                    setOpen(false);
                                    setPhoneNumber('');
                                    setName('');

                                    setIsLoading(false);

                                    toggleAppoitmentDialog();



                                    // Button click handler logic here
                                }}
                            type="button"
                            className="flex items-center rounded-md bg-watsPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                            {translate("addCustomer", language)}
                        </button>
                    </div>

                </div>
            </div>

        </DialogWats>
    )




}
