import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  IconButton,
  Box,
  Badge,
  Menu,
  MenuItem,
  Avatar,
  Typography,
  Button,
  Chip,
  Divider, // Import the Divider component
} from '@mui/material';
import * as dropdownData from './data';
import Scrollbar from 'src/components/custom-scroll/Scrollbar';

import { IconBellRinging, IconArchive } from '@tabler/icons';
import { Stack } from '@mui/system';
import { useNotifications } from './NotificationContext';
import Utils from 'src/book/src/data/Utils';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';
import { IconBell } from '@tabler/icons';
import ProfileCard from 'src/components/pages/profile/ProfileCard';
import { useEvents } from 'src/views/authentication/CustomersContext';
import { useAbility } from '@casl/react';
import { useAuth } from 'src/views/authentication/AuthContext';
import { ArchiveBoxArrowDownIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import ScheduleAppointmentWrapper from 'src/components/pages/ScheduleAppointmentWrapper';
import { useProducts } from 'src/components/pages/Products/ProductsContext';

const Notifications = () => {
  const { notifications, archive, archiveNotification, markNotificationAsRead } =
    useNotifications();

  const { language } = useLanguage();
  const { getOrder } = useProducts();

  const [anchorEl2, setAnchorEl2] = useState(null);

  const [profileCardDialogOpen, setProfileCardDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  const { currentEventsByUser, pastEventsByUser } = useEvents();
  const { customers } = useAuth();

  // modes: 'notifications', 'archive'
  const [mode, setMode] = useState('notifications');

  const isNotificationsMode = () => mode === 'notifications';

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const dataToDisplay = mode === 'notifications' ? notifications : archive;

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'notifications' ? 'archive' : 'notifications'));
  };

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [appoitmentDialogOpen, setAppoitmentDialogOpen] = useState(false);
  const toggleAppoitmentDialog = () => {
    setAppoitmentDialogOpen(!appoitmentDialogOpen);
  };

  const navigate = useNavigate();

  const handleRedirectOrders = (searchValue) => {
    // Navigate to the /search route and include the search parameter
    navigate(`/manage/orders?search=${encodeURIComponent(searchValue)}`);
  };

  const handleRedirectProducts = (searchValue) => {
    // Navigate to the /search route and include the search parameter
    navigate(`/manage/products?search=${encodeURIComponent(searchValue)}`);
  };

  return (
    <>
      {/* <ScheduleAppointmentWrapper
      appoitmentDialogOpen={appoitmentDialogOpen}
      toggleAppoitmentDialog={toggleAppoitmentDialog}
      selectedCustomer={selectedCustomer}
      setSelectedCustomer={setSelectedCustomer}
    /> */}

      <ProfileCard
        // setSelectedCustomer={setSelectedCustomer}
        // toggleAppoitmentDialog={toggleAppoitmentDialog}

        dialogOpen={profileCardDialogOpen}
        setDialogOpen={setProfileCardDialogOpen}
        selectedUser={
          customers && selectedUserId
            ? customers.filter((cust) => cust.id === selectedUserId)[0]
            : null
        }
        notificationType={notificationType}
      />

      <Box>
        <IconButton
          size="large"
          aria-label="show new notifications"
          color="inherit"
          aria-controls="msgs-menu"
          aria-haspopup="true"
          sx={{
            ...(anchorEl2 && {
              color: 'primary.main',
            }),
          }}
          onClick={handleClick2}
        >
          <div className="relative inline-block mt-1">
            <IconBell />
            {notifications.filter((notification) => !notification.readTimestamp).length > 0 && (
              <span
                className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 inline-flex items-center justify-center text-xs font-bold text-white bg-watsPrimary rounded-full"
                style={{ minWidth: '1.25rem', minHeight: '1.25rem', padding: '0 0.05rem' }}
              >
                {notifications.filter((notification) => !notification.readTimestamp).length > 0
                  ? notifications.filter((notification) => !notification.readTimestamp).length
                  : ''}
              </span>
            )}
          </div>
        </IconButton>

        <Menu
          id="msgs-menu"
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          sx={{
            '& .MuiMenu-paper': {
              width: '360px',
            },
          }}
        >
          <Stack direction="row" py={2} px={4} justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {translate(isNotificationsMode() ? 'notifications' : 'theArchive', language)}
            </Typography>
            <a
              onClick={(e) => {
                toggleMode();
              }}
              className="flex items-center text-none font-medium text-xs text-[#126656] p-1 gap-1 my-2 hover:underline cursor-pointer"
            >
              {isNotificationsMode() ? (
                <>
                  <ArchiveBoxArrowDownIcon
                    className="h-5 w-5 text-watsPrimary "
                    aria-hidden="true"
                  />
                  {translate('theArchive', language)}
                </>
              ) : (
                <>
                  <EnvelopeIcon className="h-5 w-5 text-watsPrimary" aria-hidden="true" />
                  {translate('notifications', language)}
                </>
              )}

              {/* <ArchiveBoxArrowDownIcon/> */}
              {/* <IconArchive /> */}
            </a>

            {/* <Chip label={notifications.length + ' ' + translate('new', language)} color="primary" size="small" /> */}
          </Stack>
          <Scrollbar sx={{ maxHeight: '385px' }}>
            {dataToDisplay &&
              dataToDisplay.length > 0 &&
              dataToDisplay.map((notification, index) => (
                <div
                  key={index}
                  className={`${
                    isNotificationsMode() && !notification.readTimestamp ? 'bg-blue-50' : ''
                  }`}
                  onClick={() => {
                    setSelectedUserId(notification.customerId);
                    setNotificationType(notification.notificationType);
                    // ggg

                    if (isNotificationsMode() && !notification.readTimestamp) {
                      markNotificationAsRead(notification.time);
                    }

                    if (notification.notificationType === 'order') {
                      const order = getOrder(notification.details.invoiceId);

                      handleRedirectOrders(order.humanOrderId);

                      // navigate to
                      // humanOrderId
                      // const xx = 'dfdf';
                    } else if (notification.notificationType === 'product') {
                      handleRedirectProducts(
                        Utils.getLanguageOrDefault(
                          notification.details.additionalInfo.productName,
                          language,
                        ),
                      );
                    } else {
                      setProfileCardDialogOpen(true);
                    }
                  }}
                >
                  <MenuItem sx={{ py: 2, px: 4 }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          fontWeight={600}
                          noWrap
                          sx={{
                            width: '240px',
                            whiteSpace: 'normal',
                          }}
                        >
                          {Utils.getLanguageOrDefault(notification.body, language)}
                        </Typography>

                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{
                            width: '240px',
                            fontSize: {
                              xs: '0.75rem',
                              sm: '0.675rem',
                            },
                          }}
                          noWrap
                        >
                          {Utils.formatMillis(notification.time, language)}
                        </Typography>
                      </Box>

                      {isNotificationsMode() && (
                        <div className=" w-full flex justify-end">
                          <a
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents click event from bubbling up to the Box
                              archiveNotification(notification.notificationId);
                            }}
                            className="text-none font-medium text-xs text-[#126656] p-1 my-2 hover:underline"
                          >
                            {translate('archive', language)}
                          </a>
                        </div>
                      )}
                    </Stack>
                    {/* {index < dropdownData.notifications.length - 1 && <Divider />} */}
                  </MenuItem>
                </div>
              ))}
          </Scrollbar>
        </Menu>
      </Box>
    </>
  );
};

export default Notifications;
