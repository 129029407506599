import { Fragment, useState } from 'react'
import { translate } from 'src/translate'
import { useLanguage } from 'src/LanguageContext'
import Utils from 'src/book/src/data/Utils'
import SpinnerOverlay from 'src/views/SpinnerOverlay'
import DialogWats from '../DialogWats'
import AddTagColorDropDown from './AddTagColorDropDown'
import ColorPickerDropdown from './ColorPickerDropdown'
import { useQuestionnaires } from 'src/views/authentication/QuestionnairesContex'

export default function Addtag({ open, setOpen }) {

    const { language } = useLanguage();

    const [isLoading, setIsLoading] = useState(false);
    const [tagName, setTagName] = useState(null);

    const { addTag } = useQuestionnaires();
    const [selectedColor, setSelectedColor] = useState({ id: 6, hex: '#618264' });



    return (
        <DialogWats isOpen={open} setOpen={setOpen}>

            {isLoading && <SpinnerOverlay />}

            <div className='flex justify-center pb-4'>

                <h1 className="text-base font-semibold leading-6 text-gray-900">
                    {translate('addTag', language)}
                </h1>


            </div>

            <div className='px-4'>


                <div className="relative">

                    <label
                        htmlFor="name"
                        className={`absolute -top-2 ${language === 'en' ? 'left-2' : 'right-2'} inline-block bg-white px-1 text-xs font-medium text-gray-900`}
                    >
                        {translate('tagName', language)}
                    </label>

                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6"
                        // placeholder={translate('jane', language)}
                        value={tagName}
                        onChange={(event) => {
                            setTagName(event.target.value)
                        }}
                    />

                </div>

                <div className='mt-2'>
                    {/* <AddTagColorDropDown /> */}
                    <ColorPickerDropdown selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                </div>

                <div className='flex justify-center mt-5'>

                    <button
                        type="button"
                        className="inline-flex  justify-center rounded-md max-w-16 bg-watsPrimary px-4 py-2 text-sm font-medium text-white shadow-sm  "
                        onClick={async () => {

                            setIsLoading(true);

                            addTag(tagName, selectedColor.hex);
                            setOpen(false);

                            setIsLoading(false);


                        }}
                    >
                        {translate('add', language)}
                    </button>

                </div>
            </div>


            {/* <AlertStyled
                title={translate('invalidPhoneNumberTitle', language)}
                description={translate('invalidPhoneNumberMessage', language)}
                primaryButtonLabel={translate('ok', language)}
                secondaryButtonLabel={null}
                onPrimaryButtonClick={() => console.log('Primary action selected.')}
                onSecondaryButtonClick={() => console.log('Action cancelled.')}
                open={invalidNumber}
                setOpen={setInvalidNumber}
            /> */}


        </DialogWats>
    )


}
