import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';

const URLQRCode = ({ url, phoneNumber }) => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const qrSize = isMobile ? 200 : 200;
    
    const [tooltipText, setTooltipText] = useState('Copy to clipboard');
    const { language } = useLanguage();

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setTooltipText('Copied');
            setTimeout(() => setTooltipText('Copy to clipboard'), 1500);
        } catch (err) {
            setTooltipText('Failed to copy');
        }
    };

    const handleWhatsAppSend = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(url)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className='flex flex-col items-center justify-center '>
            {/* <span>Your Invoice is ready!</span> */}

            
            <div className=' text-md'>{translate('invoiceSent', language)}</div>
            {/* <h1>{translate('qrMessage', language)}</h1> */}
            <div className='mt-1'>

                <QRCode value={url} size={qrSize} />

            </div>
            <div className='flex gap-3 mt-1 '>
                <Tooltip title={tooltipText}>
                    <IconButton onClick={handleCopy} color="primary" size="small">
                        <FileCopyOutlined />

                        {translate('copy', language)}

                    </IconButton>
                </Tooltip>
                <Tooltip title="Send via WhatsApp" >
                    <IconButton onClick={handleWhatsAppSend} color="primary" size="small">
                        <WhatsApp />

                        {translate('whatsapp', language)}
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

export default URLQRCode;
