/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    PaperClipIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import Utils from 'src/book/src/data/Utils'
import { useLanguage } from 'src/LanguageContext'
import { formatRelativeWithOptions } from 'date-fns/fp'
import RelativeDateWithOptions from 'src/book/src/data/RelativeDateWithOptions'
import { translate } from 'src/translate'
import { useAuth } from 'src/views/authentication/AuthContext'
import Scrollbar from 'src/components/custom-scroll/Scrollbar'
import { TrashIcon } from '@heroicons/react/24/outline'

const activity = [
    {
        id: 4,
        type: 'commented',
        person: {
            name: 'פתיחת/בניית תלתלים',
            imageUrl:
                'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        comment: 'Called client, they reassured me the invoice would be paid by the 25th.',
        date: '3d ago',
        dateTime: '2023-01-23T15:56',
    },
    // { id: 5, type: 'viewed', person: { name: 'Alex Curren' }, date: '2d ago', dateTime: '2023-01-24T09:12' },
    // { id: 6, type: 'paid', person: { name: 'Alex Curren' }, date: '1d ago', dateTime: '2023-01-24T09:20' },
]
const moods = [
    { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
    { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NotesProfileCard({ notes, customerId }) {
    const [selected, setSelected] = useState(moods[5]);

    const { language } = useLanguage();

    const { addNote, removeNote } = useAuth();

    const [noteText, setNoteText] = useState('');

    const handleNoteChange = (event) => {
        setNoteText(event.target.value);
    };

    const sortedNotes = notes.sort((a, b) => b.timestamp - a.timestamp);




    return (
        <>
            <Scrollbar sx={{ maxHeight: { lg: '200px', md: '100vh' } }}>

                <ul role="list" className="p-1">
                    {sortedNotes.map((activityItem, activityItemIdx) => {


                        return (
                            <div key={activityItem.timestamp} className='flex items-center justify-between  mb-6'>

                                <div className=' w-full '>

                                    <li className={`relative flex gap-x-1 w-full ${activityItemIdx === notes.length - 1 ? '' : ''}`}>
                                        <div
                                            className={classNames(
                                                activityItemIdx === sortedNotes.length - 1 ? '-bottom-6' : '-bottom-6',
                                                'absolute top-0 flex w-6 justify-center',
                                                language === 'en' && 'left-0',
                                                language !== 'en' && 'right-0',

                                            )}
                                        >
                                            {activityItemIdx !== sortedNotes.length - 1 && <div className="w-px bg-gray-200" />}
                                        </div>


                                        {(
                                            <>
                                                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                                    {activityItem.type === 'paid' ? (
                                                        <CheckCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                                    ) : (
                                                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                                    )}
                                                </div>
                                                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-blue-50 ">

                                                    <div className="flex justify-between gap-x-4">

                                                        <div className={`${language === 'en' ? 'text-left' : 'text-right'}`}
                                                              dangerouslySetInnerHTML={{ __html: activityItem.note }}
                                                              >

                                                            {/* {activityItem.note} */}

                                                            {/* {activityItem.tags.map((tag, tagIdx) => {
                                                const tagKey = Object.keys(tag)[0];

                                                const currentTag = tags.filter(tageneral => tageneral.value === tagKey)[0];




                                                return (<span key={tagKey} style={{ backgroundColor: currentTag.color }}
                                                    className="inline-flex items-center rounded-full px-3 py-1 text-xs font-semibold text-white mr-1 mt-1">
                                                    {currentTag.label}
                                                </span>)
                                            }
                                            )} */}

                                                        </div>
                                                    </div>

                                                    <div className="relative w-full flex items-end justify-end text-xs leading-5 text-gray-500">
                                                        <div className="group">
                                                            <div>
                                                                <RelativeDateWithOptions date={activityItem.timestamp} />
                                                            </div>
                                                            {/* Tooltip */}
                                                            <div className="absolute bottom-full mb-0 hidden whitespace-nowrap text-xs px-2 py-1 text-white bg-black rounded-md group-hover:block">
                                                                {Utils.formatUnixTimestamp(activityItem.timestamp, language)}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/* <p className="text-sm leading-6 text-gray-500">{activityItem.comment}</p> */}
                                                </div>




                                            </>
                                        )}
                                    </li>

                                </div>


                                <div className={` flex px-1 `} onClick={() => {
                                    removeNote(customerId, activityItem.timestamp);

                                }}>
                                    <TrashIcon className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" />
                                </div>

                            </div>
                        )

                    })}
                </ul>
            </Scrollbar>


            <div className="mt-2 flex gap-x-3">

                <div className=' h-5 w-4'></div>
                <div className="relative flex-auto">
                    <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-watsPrimary">

                        <textarea
                            rows={2}
                            name="comment"
                            id="comment"
                            className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={translate('addNote', language)}
                            value={noteText}
                            onChange={handleNoteChange}
                        />

                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                        <div className="flex items-center space-x-5">
                            <div className="flex items-center">

                            </div>

                        </div>
                        <button
                            onClick={() => {
                                if (noteText === '') {
                                    return;
                                }

                                addNote(customerId, noteText);
                                setNoteText('');
                            }}
                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            {translate('add', language)}
                        </button>
                    </div>
                </div>
                <div className=' h-5 w-4'></div>

            </div>
        </>
    )
}



