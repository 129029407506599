import { Fragment, useEffect, useId, useState } from 'react';
import DashboardCard from '../shared/DashboardCard';
import { useAuth } from 'src/views/authentication/AuthContext';
import { Search } from '@mui/icons-material';
import { get, ref } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';
import ProfileCard from './profile/ProfileCard';
import { useEvents } from 'src/views/authentication/CustomersContext';
import { useQuestionnaires } from 'src/views/authentication/QuestionnairesContex';
import DropdownMenu from 'src/layouts/full/horizontal/DropdownMenu';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Utils from 'src/book/src/data/Utils';
import Filters from 'src/layouts/full/horizontal/Filters';
import TagsDropDown from './Questionnaires/TagsDropDown';
import Addtag from './Questionnaires/AddTag';
import { PencilIcon, PlusIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import EditTagsDropDown from './Questionnaires/EditTagsDropDown';
import { Button, useMediaQuery } from '@mui/material';
import SentryLogger from '../logging/SentryLogger';
import ScheduleAppointmentWrapper from './ScheduleAppointmentWrapper';
import EditAnswerDialog from './editDetials/EditAnswerDialog';
import AddEditTag from './Questionnaires/AddEditTag';

const formatPhoneNumber = (number) => {
  if (number?.startsWith('9725')) {
    number = number.replace(/^972/, '0');
  }
  return number;
};

const sortFunctions = {
  // Example custom sort function for the 'role' column
  dogName: (a, b) => {
    return a?.genericDetails?.dogName < b?.genericDetails?.dogName;
    // Custom logic here
    // For example, sorting roles based on a predefined role hierarchy
  },
  tags: (a, b) => {
    const lengthA = a?.tags?.length || 0;
    const lengthB = b?.tags?.length || 0;

    // Compare the lengths of the tags arrays
    if (lengthA < lengthB) {
      return -1; // a comes before b
    } else if (lengthA > lengthB) {
      return 1; // a comes after b
    } else {
      return 0; // a and b are equivalent in number of tags
    }
  },
  // Add other custom sort functions as needed
};

const genericSort = (array, columnName, direction, customers, getCustomer) => {
  const sortedArray = [...array].sort((a, b) => {
    if (sortFunctions[columnName]) {
      return sortFunctions[columnName](a, b) * (direction === 'asc' ? 1 : -1);
    }

    let itemA = a[columnName];
    let itemB = b[columnName];

    if (columnName !== 'name' && columnName !== 'number') {
      if (!itemA && a.questions) {
        itemA = a.questions[columnName]?.val;
      }

      if (!itemB && b.questions) {
        itemB = b.questions[columnName]?.val;
      }
    }

    // this is for questionariees
    try {
      if (!itemA && a.customerId) {
        itemA = getCustomer(a.customerId)[columnName];
      }

      if (!itemB && b.customerId) {
        itemB = getCustomer(b.customerId)[columnName];
      }
    } catch (e) {
      const logger = new SentryLogger();
      logger.error(e);
    }

    if (itemA === null && itemB !== null) return 1; // Always send A to the bottom if it's null
    if (itemB === null && itemA !== null) return -1; // Always send B to the bottom if it's null
    if (itemA === null && itemB === null) return 0; // Both are null, they are equal

    if (itemA < itemB) return direction === 'asc' ? -1 : 1;
    if (itemA > itemB) return direction === 'asc' ? 1 : -1;
    return 0;
  });
  return sortedArray;
};

const formatUnixTimestamp = (unixTimestamp, language) => {
  if (!unixTimestamp) return unixTimestamp;
  const { DateTime } = require('luxon');

  const now = DateTime.now().startOf('day');
  const inputDate = DateTime.fromMillis(parseInt(unixTimestamp));

  const inputDateStart = inputDate.startOf('day');
  const diffDays = inputDateStart.diff(now, 'days').days;

  // Time formatting part
  const timeFormat = inputDate.toFormat('HH:mm');

  if (diffDays === 0) {
    return translate('today', language) + ' ' + timeFormat;
  } else if (diffDays === 1) {
    return translate('tomorrow', language) + ' ' + timeFormat;
  } else if (diffDays > 1 && diffDays < 7) {
    return inputDate.toFormat(`cccc HH:mm`, { locale: language });
  } else {
    return inputDate.toFormat(`dd/MM/yyyy HH:mm`);
  }
};

const UserRow = ({
  answer,
  genericDetailsColumns,
  language,
  setProfileCardDialogOpen,
  setUserId,
  person,
  setTagToModify,
  setModifyTagOpen,
  genericDetailsColumnsOrdered,
}) => {
  const {
    tags,
    updateTags,
    selectedQuestionnaire,
    addTag,
    getUserAvailableTags,
    modifyTag,
    hiddenTagsKeys,
  } = useQuestionnaires();

  const [editAnswerDialogOpen, setEditAnswerDialogOpen] = useState(false);

  return (
    <>
      <EditAnswerDialog
        open={editAnswerDialogOpen}
        setOpen={setEditAnswerDialogOpen}
        user={person}
        answerId={answer.questionnaireId}
        questionnaireId={selectedQuestionnaire}
      />
      <tr
        key={answer.questionnaireId}
        className=""
        onClick={() => {
          setUserId(person.id);
          setProfileCardDialogOpen(true);
        }}
      >
        <td
          className={`w-full max-w-0 py-4 sticky bg-white ${
            language === 'en' ? 'left-0' : 'right-0'
          } pl-4 pr-3 text-xs md:text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0`}
        >
          {person?.name}
        </td>
        {/* <td className="hidden px-3 py-4 text-xs md:text-sm text-gray-500 lg:table-cell">{formatPhoneNumber(person.number)}</td> */}
        {/* <td className=" px-3 py-4 text-xs md:text-sm text-gray-500 ">{formatUnixTimestamp(person.prevEventTime, language)}</td>
        <td className="px-3 py-4 text-xs md:text-sm text-gray-500">{formatUnixTimestamp(person.nextEventTime, language)}</td> */}

        {genericDetailsColumnsOrdered &&
          genericDetailsColumnsOrdered.length > 0 &&
          genericDetailsColumns &&
          genericDetailsColumnsOrdered.map((column) => (
            <td
              key={column}
              className="px-3 py-4 text-xs md:text-sm text-gray-500 overflow-hidden whitespace-nowrap truncate"
              style={{ maxWidth: '137px' }} // Explicitly defining the width
            >
              {answer &&
              answer.questions &&
              answer.questions[column] &&
              answer.questions[column].val
                ? answer.questions[column].val
                : '-'}
            </td>
          ))}

        <td className="px-3 py-4 text-xs md:text-sm text-gray-500">
          <div
            className="space-y-1"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {answer?.tags?.length > 0 &&
              answer.tags
                .filter((tag) => {
                  const tagKey = Object.keys(tag)[0];

                  if (hiddenTagsKeys[tagKey]) return false;
                  return true;
                })
                .map((tag, index) => {
                  const tagKey = Object.keys(tag)[0];
                  const tagData = tags?.find((tagD) => tagD.value === tagKey);

                  if (!tagData) {
                    return <></>;
                  }

                  return (
                    <span
                      key={index}
                      style={{ backgroundColor: tagData?.color }}
                      className="inline-flex items-center rounded-full px-3 py-1 text-xs font-semibold text-white mr-1"
                      onClick={() => {
                        setModifyTagOpen(true);
                        setTagToModify(tagData);
                      }}
                    >
                      {tagData?.label}

                      <button
                        type="button"
                        className={`group relative ${
                          language === 'en' ? '-mr-1' : '-ml-1'
                        } h-3.5 w-3.5 rounded-sm `}
                        onClick={(e) => {
                          e.stopPropagation();
                          updateTags(
                            person?.id,
                            answer.questionnaireId,
                            tagKey,
                            answer.tags,
                            'delete',
                          );
                        }}
                      >
                        <span className="sr-only">Remove</span>
                        <svg
                          viewBox="0 0 14 14"
                          className="h-3.5 w-3.5 stroke-current sm:hidden group-hover:block"
                        >
                          <path d="M4 4l6 6m0-6l-6 6" />
                        </svg>

                        <span className="absolute -inset-1" />
                      </button>
                    </span>
                  );
                })}

            {/* {answer?.tags?.length > 0 ? ( */}
            <div>
              <EditTagsDropDown
                answer={answer}
                tags={tags}
                customerId={person?.id}
                answerId={answer.questionnaireId}
                currentCustomerTags={Utils.filterOutHiddenTags(answer.tags, hiddenTagsKeys)}
                updateTags={updateTags}
                userAvailableTags={Utils.filterOutHiddenTags(
                  getUserAvailableTags(tags, answer.tags),
                  hiddenTagsKeys,
                )}
                addTag={addTag}
              />
            </div>
            {/* )
                        : (<TagsDropDown
                            customerId={person.id}
                            answerId={answer.questionnaireId}
                            currentCustomerTags={answer.tags}
                            allTags={tags}
                        />)} */}
          </div>
        </td>

        <td className="px-3 py-4 text-xs md:text-sm text-gray-500">
          {formatUnixTimestamp(answer.timestamp, language)}
        </td>

        <td
          className="px-3 py-4 text-xs md:text-sm text-gray-500 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            setEditAnswerDialogOpen(true);
          }}
        >
          <PencilIcon className="h-5 w-5" aria-hidden="true" />
        </td>

        {/* <td className="hidden py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 lg:table-cell">
            <a href="#" className=" text-watsPrimary hover:text-watsPrimary">
                Edit<span className="sr-only">, {person.name}</span>
            </a>
        </td> */}
      </tr>
    </>
  );
};

const Header = ({
  handleSearch,
  availableQuestionnaires,
  selectedQuestionnaire,
  setSelectedQuestionnaire,
  sortedAnswers,
}) => {
  const { language } = useLanguage();

  const { exportToCsv } = useQuestionnaires();

  return (
    <>
      <div className="w-full flex justify-between gap-2 ">
        {/* This div is for the left side in LTR and right side in RTL */}
        <div>
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {translate('questionnaires', language)}
          </h1>

          <div className="relative mt-3 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="h-3 w-3 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="email"
              name="email"
              id="email-original"
              className="flex custom-placeholder w-full rounded-md border-0 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary text-sm sm:text-xs md:text-sm lg:text-sm xl:text-sm h-7 sm:h-9 md:h-9 lg:h-9 xl:h-9"
              placeholder={translate('searchEverything', language)}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* className="flex items-center gap-x-1.5 rounded-md bg-white px-2 py-1.5 sm:px-3 sm:py-2 text-xs sm:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" */}

        {/* div on the right */}
        <div className="flex gap-2">
          <div>
            <h1 className="text-base font-semibold leading-6 text-white">.</h1>

            <div className="relative mt-3 rounded-md shadow-sm">
              {availableQuestionnaires.length > 1 && (
                <DropdownMenu
                  options={availableQuestionnaires}
                  selectedOptionId={selectedQuestionnaire}
                  onChange={setSelectedQuestionnaire}
                  language={language}
                />
              )}
            </div>
          </div>

          <div>
            <h1 className="text-base font-semibold leading-6 text-white">.</h1>

            <div className="relative mt-3 ">
              {/* <button
                onClick={() => {
                  const url = exportToCsv(sortedAnswers);
                  window.open(url, '_blank');
                }}
                type="button"
                className=" flex items-center rounded-md bg-watsPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              > */}
              {/* {translate('download', language)} */}

              {/* <Button> */}
              <ArrowDownTrayIcon
                onClick={() => {
                  const url = exportToCsv(sortedAnswers);
                  if (url === null) {
                    return;
                  }
                  window.open(url, '_blank');
                }}
                className=" mx-1 h-7 w-7 text-gray-700 p-1 rounded-md"
              />
              {/* </Button> */}

              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserTable = ({
  answers,
  onSort,
  sortColumn,
  sortDirection,
  genericDetailsColumns,
  language,
  setProfileCardDialogOpen,
  setUserId,
  getCustomer,
  genericDetailsColumnsOrdered,
}) => {
  const { tags, modifyTag } = useQuestionnaires();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    // When the component mounts, disable scrolling on the body
    document.body.style.overflow = 'hidden';

    // When the component unmounts, re-enable scrolling
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const [tagToModify, setTagToModify] = useState(null);
  const [modifyTagOpen, setModifyTagOpen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <AddEditTag
        modifyTag={modifyTag}
        tagToModify={tagToModify}
        addEditDialogOpen={modifyTagOpen}
        setAddEditDialogOpen={setModifyTagOpen}
      />

      <div className="-mx-2 mt-2 sm:-mx-0">
        <div className="inline-block min-w-full py-2 align-middle ">
          <div style={{ maxHeight: `${windowHeight - (isMobile ? 290 : 290)}px` }}>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    onClick={() => onSort('name')}
                    scope="col"
                    className={`sticky top-0 ${
                      language === 'en' ? 'left-0' : 'right-0'
                    } z-20 border-b border-gray-300 bg-white bg-opacity-85 py-3.5 pl-4 pr-3 ${
                      language === 'en' ? 'text-left' : 'text-right'
                    } text-xs md:text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer`}
                  >
                    {translate('nameShort', language)}{' '}
                    {sortColumn === 'name' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                  </th>
                  {/* <th onClick={() => onSort('number')} scope="col" className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85 hidden px-3 py-3.5 ${language === 'en' ? 'text-left' : 'text-right'} text-xs md:text-sm font-semibold text-gray-900 lg:table-cell cursor-pointer`}>{translate('phoneNumber', language)} {sortColumn === 'number' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}</th> */}

                  {/* <th onClick={() => onSort('prevEventTime')} scope="col" className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85 px-3 py-3.5 ${language === 'en' ? 'text-left' : 'text-right'} text-xs md:text-sm font-semibold text-gray-900  cursor-pointer`}>{translate('previousAppointment', language)} {sortColumn === 'prevEventTime' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}</th>
                                <th onClick={() => onSort('nextEventTime')} scope="col" className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85 px-3 py-3.5 ${language === 'en' ? 'text-left' : 'text-right'} text-xs md:text-sm font-semibold text-gray-900 cursor-pointer`}>{translate('nextAppointment', language)} {sortColumn === 'nextEventTime' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}</th> */}

                  {genericDetailsColumnsOrdered &&
                    genericDetailsColumnsOrdered.length > 0 &&
                    genericDetailsColumns &&
                    genericDetailsColumnsOrdered.map((column) => (
                      <th
                        key={column}
                        onClick={() => onSort(column)}
                        className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85 px-3 py-3.5 ${
                          language === 'en' ? 'text-left' : 'text-right'
                        } text-xs md:text-sm font-semibold text-gray-900 cursor-pointer`}
                      >
                        {genericDetailsColumns[column][language]
                          ? genericDetailsColumns[column][language]
                          : genericDetailsColumns[column][
                              Object.keys(genericDetailsColumns[column])[0]
                            ]}

                        {sortColumn === column ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                      </th>
                    ))}

                  {
                    <th
                      onClick={() => onSort('tags')}
                      scope="col"
                      className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85  px-3 py-3.5 ${
                        language === 'en' ? 'text-left' : 'text-right'
                      } text-xs md:text-sm font-semibold text-gray-900 lg:table-cell cursor-pointer`}
                    >
                      {translate('tag', language)}{' '}
                      {sortColumn === 'tags' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                    </th>
                  }

                  <th
                    onClick={() => onSort('timestamp')}
                    scope="col"
                    className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85  px-3 py-3.5 ${
                      language === 'en' ? 'text-left' : 'text-right'
                    } text-xs md:text-sm font-semibold text-gray-900 lg:table-cell cursor-pointer`}
                  >
                    {translate('time', language)}{' '}
                    {sortColumn === 'timestamp' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                  </th>

                  <th
                    scope="col"
                    className={`sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-85  px-3 py-3.5 ${
                      language === 'en' ? 'text-left' : 'text-right'
                    } text-xs md:text-sm font-semibold text-gray-900 lg:table-cell cursor-pointer`}
                  >
                    {translate('edit', language)}{' '}
                  </th>

                  {/* <th scope="col" className="hidden relative py-3.5 pl-3 pr-4 sm:pr-0 lg:table-cell"><span className="sr-only">Edit</span></th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {!answers || answers.length === 0 ? (
                  <tr>
                    <td colSpan="100%" className="text-center py-3  text-gray-500">
                      {translate('noResults', language)}
                    </td>
                  </tr>
                ) : (
                  <>
                    {answers.map((answer) => (
                      <UserRow
                        key={answer.questionnaireId}
                        person={getCustomer(answer.customerId)}
                        answer={answer}
                        genericDetailsColumns={genericDetailsColumns}
                        genericDetailsColumnsOrdered={genericDetailsColumnsOrdered}
                        setUserId={setUserId}
                        setProfileCardDialogOpen={setProfileCardDialogOpen}
                        language={language}
                        setModifyTagOpen={setModifyTagOpen}
                        setTagToModify={setTagToModify}
                      />
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Questionnaires() {
  const [sortColumn, setSortColumn] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');

  const { customers, bizId, getCustomer } = useAuth();
  const { language } = useLanguage();

  const {
    answers,
    questions,
    availableQuestionnaires,
    selectedQuestionnaire,
    setSelectedQuestionnaire,
    tags,
    answersTagsCount,
    orderedQuestions,
  } = useQuestionnaires();

  const { currentEventsByUser, pastEventsByUser } = useEvents();

  const [profileCardDialogOpen, setProfileCardDialogOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const savedFilters = localStorage.getItem('selectedFilters');
    return savedFilters ? JSON.parse(savedFilters) : null;
  });

  const logger = new SentryLogger();

  const [appoitmentDialogOpen, setAppoitmentDialogOpen] = useState(false);
  const toggleAppoitmentDialog = () => {
    setAppoitmentDialogOpen(!appoitmentDialogOpen);
  };

  // Fetch tags from Firebase and initialize selectedFilters if not set
  useEffect(() => {
    const fetchTags = async () => {
      try {
        // Ensure there are tags available and there are no pre-selected filters
        if (!selectedFilters && tags) {
          setSelectedFilters(tags);
        } else if (selectedFilters && tags) {
          // Create a Set from the 'value' properties of tags for efficient lookup
          const tagValues = new Set(tags.map((tag) => tag.value));

          // Check if any selected filter is not found in tags
          const isFilterInvalid = selectedFilters.some((filter) => !tagValues.has(filter.value));

          // If any selected filter is invalid, update selectedFilters to tags
          if (isFilterInvalid) {
            setSelectedFilters(tags);
          }
        }
      } catch (error) {
        logger.error(error);
      }
    };

    if (tags && tags.length > 0) {
      fetchTags();
    }
  }, [tags, selectedFilters]);

  // Update local storage whenever selectedFilters changes
  useEffect(() => {
    if (selectedFilters) {
      localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
    }
  }, [selectedFilters]);

  // dialog for adding a new tag
  const [addTagDialog, setAddTagDialog] = useState(false);

  useEffect(() => {
    const fetshSelectedUser = async () => {
      try {
        const customer = customers.filter((cust) => cust.id === userId)[0];
        setSelectedUser(customer);
      } catch (error) {
        logger.error(error);
      }
    };

    if (userId && customers) {
      fetshSelectedUser();
    }
  }, [userId, customers]);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const sortedAnswers = genericSort(
    customers && answers !== null
      ? searchQuery === ''
        ? answers.filter((answer) => {
            // Check if no tags are selected; if true, include entries with no tags.
            if (!selectedFilters || selectedFilters.length === 0) {
              // return true;
              return true;
            }

            // If there are selected tags and the answer has tags, check if all answer tags are in the selected tags.
            if (answer.tags) {
              // Extract all keys from the answer tags.
              let answerTags = Object.keys(answer.tags).flatMap((key) =>
                Object.keys(answer.tags[key]),
              );

              // Check if every tag in answerTags is included in selectedFilters.
              return answerTags?.some((tag) =>
                selectedFilters?.some((selectedTag) => selectedTag.value === tag),
              );
            }

            // Exclude entries with tags if there are selected tags.
            return false;
          })
        : answers.filter(
            (answer) =>
              (Object.entries(answer.questions)?.some(([key, value]) => {
                if (value.val?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
                  return true;
                }
                return false;
              }) ||
                (getCustomer(answer.customerId) &&
                  Object.entries(getCustomer(answer.customerId))?.some(([key, value]) =>
                    value?.toString()?.toLowerCase()?.includes(searchQuery.toLowerCase()),
                  ))) &&
              (selectedFilters.length === 0 ||
                (answer.tags &&
                  Object.entries(answer.tags)?.some(([key, value]) => {
                    return selectedFilters?.some((tag) => tag.value === Object.keys(value)[0]);
                  }))),
          )
      : [],
    sortColumn,
    sortDirection,
    customers,
    getCustomer,
  );

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
    <>
      {/* <ScheduleAppointmentWrapper
                appoitmentDialogOpen={appoitmentDialogOpen}
                toggleAppoitmentDialog={toggleAppoitmentDialog} 
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
            /> */}
      <ProfileCard
        // setSelectedCustomer={setSelectedCustomer}
        // toggleAppoitmentDialog={toggleAppoitmentDialog}
        selectedUser={selectedUser}
        setDialogOpen={setProfileCardDialogOpen}
        dialogOpen={profileCardDialogOpen}
      />
      <DashboardCard>
        {/* <div style={{ height: '80vh', overflowY: 'auto' }}> */}

        <Addtag open={false} setOpen={setAddTagDialog} />

        <div className={`px-0 sm:px-0 lg:px-8 ${language === 'ar' ? 'font-cairo' : ''} `}>
          <div className="sm:flex sm:items-center ">
            <Header
              handleSearch={handleSearch}
              language={language}
              availableQuestionnaires={availableQuestionnaires}
              selectedQuestionnaire={selectedQuestionnaire}
              setSelectedQuestionnaire={setSelectedQuestionnaire}
              sortedAnswers={sortedAnswers}
            />
            {/* <AddUserButton /> */}
          </div>

          {
            <div className="mt-4 border-t border-b  border-gray-200 p-2 flex justify-between items-center">
              <div>
                <Filters
                  setAddTagDialog={setAddTagDialog}
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                  filtersCount={answersTagsCount}
                  filters={[
                    {
                      id: 'tags',
                      name: 'tag',
                      options: tags?.filter((tag) => !tag.isHidden),
                    },
                  ]}
                />
              </div>

              <div>
                <span className="  font-semibold">{sortedAnswers.length}</span>{' '}
                {translate('outOf', language)}{' '}
                <span className="  font-semibold">{answers.length}</span>
              </div>
            </div>
          }

          <div className=" overflow-x-auto ">
            {customers && (
              <UserTable
                language={language}
                getCustomer={getCustomer}
                genericDetailsColumns={questions}
                genericDetailsColumnsOrdered={orderedQuestions}
                answers={sortedAnswers}
                onSort={handleSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                setUserId={setUserId}
                setProfileCardDialogOpen={setProfileCardDialogOpen}
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </DashboardCard>{' '}
    </>
  );
}
