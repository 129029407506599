import React, { useState } from 'react';
import { PencilIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import TagsView from './TagsView';
import { Popover, Dialog, useMediaQuery } from '@mui/material';
import { useLanguage } from 'src/LanguageContext';

function EditTagsDropDown({
  answer,
  tags,
  customerId,
  answerId,
  currentCustomerTags,
  updateTags,
  userAvailableTags,
  addTag,
  modifyTag,
  tagToModify,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { language } = useLanguage();

  return (
    <div>
      <div onClick={handleClick}>
        {answer && answer.tags && answer.tags.length > 0 ? (
          <PlusCircleIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
        ) : (
          <PlusCircleIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
        )}
      </div>
      {!isMobile ? (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: language === 'en' ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: language === 'en' ? 'left' : 'right',
          }}
        >
          <div className="p-3">
            <TagsView
              tags={tags}
              answer={answer}
              customerId={customerId}
              answerId={answerId}
              currentCustomerTags={currentCustomerTags}
              userAvailableTags={userAvailableTags}
              updateTags={updateTags}
              setIsOpen={handleClose}
              addTag={addTag}
              modifyTag={modifyTag}
              tagToModify={tagToModify}
            />
          </div>
        </Popover>
      ) : (
        <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
          <div className=" p-3">
            <TagsView
              tags={tags}
              answer={answer}
              customerId={customerId}
              answerId={answerId}
              currentCustomerTags={currentCustomerTags}
              userAvailableTags={userAvailableTags}
              updateTags={updateTags}
              setIsOpen={handleClose}
              addTag={addTag}
              modifyTag={modifyTag}
              tagToModify={tagToModify}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default EditTagsDropDown;
