import { Fragment, useEffect, useRef, useState } from 'react';
import DialogWats from '../DialogWats';
import DropDownCircle from '../DropDownCircle';
import { Tabs, Tab, useMediaQuery, Dialog } from '@mui/material';
import { translate } from 'src/translate';
import Bookings from '../Bookings';
import { useLanguage } from 'src/LanguageContext';
import { get, off, onValue, ref } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useAuth } from 'src/views/authentication/AuthContext';
import AlertStyled from '../AlertStyled';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import Toggle from 'src/components/material-ui/components/Toggle';
import { Transition } from '@headlessui/react';
import ChargeBooking from './ChargeBooking';
import ChargeCustom from './ChargeCustom';
import ChargeProduct from './ChargeProduct';
import { useCharge } from './ChargeContext';
import CircularProgress from '@mui/material/CircularProgress';
import URLQRCode from './URLQRCode';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';

import { Select, MenuItem } from '@mui/material';
import Utils from 'src/book/src/data/Utils';

export default function Charge({ setDialogOpen, dialogOpen, customer, customerEvents }) {
  const { language } = useLanguage();
  const { bizId, user } = useAuth();

  const [bookingToggle, setBookingToggle] = useState(false);
  const [customToggle, setCustomToggle] = useState(false);
  const [productToggle, setProductToggle] = useState(false);

  const toggleBooking = () => setBookingToggle((prev) => !prev);
  const toggleCustom = () => setCustomToggle((prev) => !prev);
  const toggleProduct = () => setProductToggle((prev) => !prev);

  const { staffChargeUser, getAppoitmentsLineItems, getCustomLineItems, getProductsLineItmes } =
    useCharge();

  const [userEvents, setUserEvents] = useState([]);
  const [products, setProducts] = useState([]);

  const [currentState, setCurrentState] = useState('billingForm'); // 'billingForm', 'loading', 'invoice'
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const [errorDialog, setErrorDialog] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    // Assuming customerEvents is the array you provided in the question
    let sortedCustomerEvents = customerEvents.sort((a, b) => {
      // Convert startTimestamp to a number for comparison
      return Number(b.startTimestamp) - Number(a.startTimestamp);
    });

    // Adding prices to each event
    let customerEventsWithPrices = sortedCustomerEvents.map((event) => {
      // Logic to set the price, you can adjust it based on event properties
      return {
        ...event,
        // price: 100  // Example: setting a static price, modify as needed
      };
    });

    // Keeping only the last two events
    let lastTwoEvents = customerEventsWithPrices.slice(0, 2);

    // Filtering out events that have an invoiceId
    let eventsWithoutInvoiceId = lastTwoEvents.filter((event) => !event.invoiceId);

    setUserEvents(eventsWithoutInvoiceId);
  }, [customerEvents, bookingToggle]);

  const [currency, setCurrency] = useState('ILS'); // Default to USD

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  useEffect(() => {
    let bookingTotal = 0;
    let customTotal = 0;
    let productsTotal = 0;

    if (userEvents && bookingToggle) {
      bookingTotal = userEvents.reduce((sum, item) => {
        const price = Number(item.price);
        return sum + (isNaN(price) ? 0 : price);
      }, 0);
    }

    if (products && customToggle) {
      customTotal = products.reduce((sum, item) => {
        const price = Number(item.price);
        return sum + (isNaN(price) ? 0 : price);
      }, 0);
    }

    if (selectedProducts && productToggle) {
      productsTotal = selectedProducts.reduce((sum, item) => {
        const price = Number(item.price) * (item.quantity || 1);
        return sum + (isNaN(price) ? 0 : price);
      }, 0);
    }

    setTotalPrice(bookingTotal + customTotal + productsTotal);
  }, [userEvents, products, customToggle, bookingToggle, productToggle, selectedProducts]);

  // Function to handle removal of an event
  const handleRemoveEvent = (index) => {
    setUserEvents((prevEvents) => prevEvents.filter((_, i) => i !== index));
  };

  const handleCharge = async () => {
    const appoitmentsLineItems = bookingToggle ? getAppoitmentsLineItems(userEvents, currency) : [];

    const customLineItems = customToggle ? getCustomLineItems(products, currency) : [];

    const productsLineItems = productToggle ? getProductsLineItmes(selectedProducts, currency) : [];

    const mergedLineItems = [...appoitmentsLineItems, ...customLineItems, ...productsLineItems];

    for (const item of mergedLineItems) {
      const { unitPrice } = item;
      if (unitPrice === null || unitPrice === undefined || typeof unitPrice !== 'number') {
        setErrorDialog(true);
        return;
      }
    }

    setCurrentState('loading');

    try {
      const { invoiceId, url } = await staffChargeUser(customer.id, mergedLineItems);

      setInvoiceUrl(url);
      setCurrentState('invoice');
    } catch (er) {
      console.log(er);

      setCurrentState('billingForm');
    }

    // setTimeout(() => {
    //     // Assuming these details are what you expect from the real API call
    //     const url = 'https://invoice.stripe.com/i/acct_1OdcIcJsNeel1fDI/test_YWNjdF8xT2RjSWNKc05lZWwxZkRJLF9ROE5hTnJyaFF1WktzR3M5VmdkcVNZMWtBRDJqcVlyLDEwNjY1NDc1MA0200oAdKQxsI?s=ap'

    //     setInvoiceUrl(url);
    //     setCurrentState('invoice'); // Switch to invoice view
    // }, 3000); //

    // setCurrentState('invoice');
  };

  const getCurrencySymbolLocal = (currency) => {
    if (currency === 'ILS') return `₪`;
    if (currency === 'USD') return `$`;
    if (currency === 'EUR') return `€`;
  };

  return (
    <Dialog
      open={dialogOpen}
      // setOpen={setDialogOpen}
      onClose={() => {
        setDialogOpen(false);
      }}
      fullWidth
      maxWidth="xs"
    >
      <AlertStyled
        title={translate('chargeErrorTitle', language)}
        description={translate('chargeErrorMessage', language)}
        primaryButtonLabel={translate('yes', language)}
        secondaryButtonLabel={null}
        onPrimaryButtonClick={() => console.log('Primary action selected.')}
        onSecondaryButtonClick={() => console.log('Action cancelled.')}
        open={errorDialog}
        setOpen={setErrorDialog}
      />

      <div className={`max-h-[32rem] overflow-y-auto p-4`}>
        <div className="flex justify-between  items-start px-4 py-0 bg-white z-50">
          <div className={`pb-2 ${language === 'en' ? 'text-left' : 'text-right'} `}>
            {currentState === 'invoice' && (
              <div>
                <button
                  onClick={() => {
                    setCurrentState('billingForm');
                  }}
                  className="flex items-center justify-center"
                >
                  <div className="flex items-center justify-center p-1 rounded-full border border-gray-200 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            )}

            <div className="inline-block mt-1">
              <h1
                className={` ${
                  language === 'en' ? 'font-bold' : ''
                } text-3xl  tracking-tight text-gray-900 `}
              >
                {translate('invoice', language)}
              </h1>
              <hr className="border-t-2 border-gray-900 my-2" />
            </div>

            <h1 className="pt-0 text-md font-semibold tracking-tight text-gray-900">
              {translate('billingTo', language)}{' '}
            </h1>
            <h1 className="pt-0 text-md tracking-tight text-gray-900">{customer.name}</h1>
          </div>

          {currentState === 'billingForm' && (
            <div>
              <Select
                value={currency}
                onChange={handleChange}
                variant="outlined"
                style={{
                  borderRadius: 20, // Rounded corners
                  padding: '2px 8px', // Padding inside the dropdown
                  minWidth: 20, // Minimum width for the dropdown
                  fontSize: '10px', // Smaller font size
                }}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EURO</MenuItem>
                <MenuItem value="ILS">ILS</MenuItem>
              </Select>
            </div>
          )}
        </div>

        {currentState === 'billingForm' && (
          <div className="px-4 space-y-4  mt-2">
            <ChargeBooking
              bookingToggle={bookingToggle}
              setBookingToggle={toggleBooking}
              handleRemoveEvent={handleRemoveEvent}
              userEvents={userEvents}
              setUserEvents={setUserEvents}
              currency={currency}
            />

            <ChargeCustom
              customToggle={customToggle}
              setCustomToggle={toggleCustom}
              products={products}
              setProducts={setProducts}
              currency={currency}
            />

            <ChargeProduct
              productToggle={productToggle}
              setProductToggle={toggleProduct}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          </div>
        )}

        {currentState === 'loading' && (
          <div className="flex justify-center items-center mt-2 py-12">
            <CircularProgress style={{ color: '#126656' }} size="5rem" />
          </div>
        )}

        {currentState === 'invoice' && (
          <div className="flex justify-center items-center mt-0 py-5">
            <URLQRCode url={invoiceUrl} phoneNumber={customer.number} />
          </div>
        )}

        <div className="pb-2 px-4 w-full">
          <div className={` ${language === 'en' ? ' text-right' : 'text-left'} `}>
            <div className="inline-block">
              <hr className="border-t-2 border-gray-900 mt-4 w-full" />
              <h1 className="pt-1 text-md font-bold tracking-tight text-gray-900">
                {translate('total', language)}{' '}
                <span>
                  {totalPrice} {getCurrencySymbolLocal(currency)}
                </span>
              </h1>
            </div>
          </div>
        </div>

        {currentState === 'billingForm' &&
          ((customToggle && products.length > 0) ||
            (bookingToggle && userEvents.length > 0) ||
            (productToggle && selectedProducts.length > 0)) && (
            <div className="flex items-center justify-center">
              <button
                type="button"
                onClick={handleCharge}
                className="inline-flex items-center justify-center w-32 rounded-md bg-watsPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-watsPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-watsPrimary"
              >
                {translate('charge', language)}
              </button>
            </div>
          )}
      </div>
    </Dialog>
  );
}
