import React, { createContext, useContext, useEffect, useState } from 'react';
import { ref, onValue, get, set, remove, off, update } from 'firebase/database';
import { useAuth } from 'src/views/authentication/AuthContext';
import { database } from 'src/book/src/firebase';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {

    const { bizId } = useAuth();
    const [notifications, setNotifications] = useState([]);
    const [archive, setArchive] = useState([]);

    // Function to fetch active notifications
    useEffect(() => {
        if (!bizId) {
            setNotifications([]);
            return;
        }

        const notificationsRef = ref(database, `/notifications/biz/active/${bizId}`);

        const onValueChange = onValue(notificationsRef, (snapshot) => {
            if (snapshot.exists()) {
                const notificationsArray = Object.entries(snapshot.val()).map(([key, value]) => ({
                    customerId: value?.details?.uid,
                    time: key,
                    notificationId: key,
                    ...value
                }));
                notificationsArray.sort((b, a) => parseInt(a.time) - parseInt(b.time));

                setNotifications(notificationsArray);
            } else {
                setNotifications([]);
            }
        }, (error) => {
            console.error("Firebase read failed: ", error);
            setNotifications([]);
        });

        // Return function to clean up the subscription
        return () => {
            off(notificationsRef, 'value', onValueChange);
        };

    }, [bizId]);

    const markNotificationAsRead = async (notificationId) => {
        const notificationRef = ref(database, `/notifications/biz/active/${bizId}/${notificationId}`);
        try {
            const timeAdded = new Date();
            await update(notificationRef, {
                readTimestamp: timeAdded.getTime(),
            })
        }
        catch (error) {
            console.log(error)

        }


    };

    // Function to fetch archived notifications
    useEffect(() => {
        if (!bizId) {
            setArchive([]);
            return;
        }

        const archiveRef = ref(database, `/notifications/biz/archive/${bizId}`);

        const onValueChange = onValue(archiveRef, (snapshot) => {
            if (snapshot.exists()) {
                const archiveArray = Object.entries(snapshot.val()).map(([key, value]) => ({
                    customerId: value?.details?.uid,
                    time: key,
                    notificationId: key,
                    archive: true,
                    ...value
                }));
                archiveArray.sort((a, b) => parseInt(a.time) - parseInt(b.time));

                setArchive(archiveArray);
            } else {
                setArchive([]);
            }
        }, (error) => {
            console.error("Firebase read failed: ", error);
            setArchive([]);
        });

        // Return function to clean up the subscription
        return () => {
            off(archiveRef, 'value', onValueChange);
        };

    }, [bizId]);

    // Function to archive a notification
    const archiveNotification = async (notificationId) => {
        const activeRef = ref(database, `/notifications/biz/active/${bizId}/${notificationId}`);
        const archiveRef = ref(database, `/notifications/biz/archive/${bizId}/${notificationId}`);

        try {
            const snapshot = await get(activeRef);
            if (snapshot.exists()) {
                await set(archiveRef, snapshot.val()); // Copy notification to archive
                await remove(activeRef); // Remove notification from active
            }
        } catch (error) {
            console.error('Failed to archive notification:', error);
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, archiveNotification, archive, markNotificationAsRead }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);
