import { Fragment, useEffect, useRef, useState } from 'react';
import DialogWats from '../DialogWats';
import DropDownCircle from '../DropDownCircle';
import { Tabs, Tab } from '@mui/material';
import { translate } from 'src/translate';
import PaymentsTable from '../PaymentsTable';
import Bookings from '../Bookings';
import { useLanguage } from 'src/LanguageContext';
import { get, off, onValue, ref } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useAuth } from 'src/views/authentication/AuthContext';
import AlertStyled from '../AlertStyled';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import Toggle from 'src/components/material-ui/components/Toggle';
import { Transition } from '@headlessui/react';
import CheckBox from 'src/components/material-ui/components/CheckBox';
import ChooseProducts from '../Products/ChooseProducts';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ChargeProduct({
  productToggle,
  setProductToggle,
  selectedProducts,
  setSelectedProducts,
}) {
  const ref = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  const { language } = useLanguage();

  useEffect(() => {
    if (productToggle && ref.current) {
      setMaxHeight(`${ref.current.scrollHeight}px`);
    } else {
      setMaxHeight('0px');
    }
  }, [productToggle, selectedProducts]);

  return (
    <div
      className={classNames(
        'rounded-lg   p-4 transition-all',
        !productToggle && 'border border-gray-200',
        productToggle && 'bg-gray-100',
      )}
      style={{ maxHeight: `calc(${maxHeight} + 4rem)` }} // 4rem accounts for padding and any other static height
    >
      {/* <Toggle enabled={productToggle} setEnabled={setProductToggle} title='Products' /> */}
      {/* <CheckBox enabled={productToggle} setEnabled={setProductToggle} title='Products' /> */}

      <div className="flex items-center  gap-2">
        <div className="flex h-6 items-center">
          <CheckBox
            enabled={productToggle}
            setEnabled={setProductToggle}
            title={translate('products', language)}
          />
        </div>
      </div>

      <div
        ref={ref}
        className="overflow-hidden transition-all duration-600 ease-in-out "
        style={{ maxHeight }}
      >
        {productToggle && (
          <ChooseProducts
            allowBundle={true}
            selectedItems={selectedProducts}
            setSelectedItems={setSelectedProducts}
            choosenLang={language}
          />
        )}
        <div className="w-full h-1"></div>
      </div>
    </div>
  );
}
