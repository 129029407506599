import React, { createContext, useContext, useEffect, useState } from 'react';
import { ref, onValue, get, set, update, push } from 'firebase/database';
import { useAuth } from 'src/views/authentication/AuthContext';
import { database } from 'src/book/src/firebase';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";


const ProductsContext = createContext();

// todo: add locale abilites
//

export const ProductsProvider = ({ children }) => {
    const { bizId } = useAuth();
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);

    // Function to fetch products
    useEffect(() => {
        if (!bizId) {
            setProducts([]);
            return;
        }
        const productsRef = ref(database, `billing/products/${bizId}`);
        onValue(productsRef, (snapshot) => {
            const loadedProducts = [];
            snapshot.forEach(childSnapshot => {
                const key = childSnapshot.key;
                const value = childSnapshot.val();
                loadedProducts.push({ id: key, ...value });
            });
            setProducts(loadedProducts);
        }, {
            onlyOnce: false
        });
    }, [bizId]);

    // Function to fetch orders
    useEffect(() => {
        if (!bizId) {
            setOrders([]);
            return;
        }
        const ordersRef = ref(database, `billing/orders/paid/${bizId}`);
        onValue(ordersRef, (snapshot) => {
            const loadedOrders = [];
            snapshot.forEach(childSnapshot => {
                const key = childSnapshot.key;
                const value = childSnapshot.val();

                // Determine the status based on isCanceled and isFulfilled flags
                let status = 'pending';
                if (value.isCanceled) {
                    status = 'canceled';
                } else if (value.isFulfilled) {
                    status = 'completed';
                }


                loadedOrders.push({ id: key, ...value, status });
            });
            setOrders(loadedOrders);
        }, {
            onlyOnce: false
        });
    }, [bizId]);

    const isOrderStatusPending = (customerId) => {
        const customerOrders = orders.filter((order => order.uid === customerId));

        const pendingOrders = customerOrders.filter(order => order.status === 'pending');

        return pendingOrders.length > 0;
    }

    /**
 * Updates the status of an order in the Firebase database.
 * @param {string} orderId The ID of the order to update.
 * @param {string} operation The operation to perform ('complete' or 'cancel').
 * @returns {Promise<void>} A promise that resolves when the update operation is complete.
 */
    const updateOrderStatus = async (orderId, operation) => {
        if (!bizId || !orderId) {
            console.error('Business ID and order ID are required.');
            return null;
        }

        const orderRef = ref(database, `billing/orders/paid/${bizId}/${orderId}`);

        const updateData = {};
        const now = Date.now(); // Current timestamp

        if (operation === 'complete') {
            updateData['isFulfilled'] = true;
            updateData['fulfillTimestamp'] = now;

        } else if (operation === 'cancel') {

            updateData['isCanceled'] = true;
            updateData['cancelTimestamp'] = now;
        } else {
            return null;
        }

        try {
            await update(orderRef, updateData);
            // console.log(`Order ${operation} successfully for ID:`, orderId);
            return orderId;  // Optionally return the order ID to confirm which order was updated
        }
        catch (error) {
            console.error('Failed to update order:', error);
            return null;
        }
    };


    // Function to update product inventory
    const updateInventory = async (prodId, quantity) => {
        const productRef = ref(database, `billing/products/${bizId}/${prodId}`);
        await update(productRef, {
            currentInventory: quantity
        });
    };

    /**
 * Uploads an image to Firebase Storage and returns the URL of the uploaded image.
 * @param {File} file The image file to upload.
 * @param {string} bizId The business ID to construct the storage path.
 * @returns {Promise<string>} A promise that resolves with the URL of the uploaded image.
 */
    const uploadProductImage = async (file) => {
        if (!file || !bizId) {
            throw new Error("File and business ID are required for uploading.");
        }

        const storage = getStorage();
        const timestamp = Date.now(); // Use timestamp to create a unique file name
        const filePath = `/biz/${bizId}/products/${timestamp}_${file.name}`;
        const fileRef = storageRef(storage, filePath);

        try {
            // Upload the file to Firebase Storage
            const uploadResult = await uploadBytes(fileRef, file);
            console.log('Image uploaded successfully:', uploadResult);

            // Get the URL of the uploaded file
            const downloadURL = await getDownloadURL(uploadResult.ref);
            // console.log('Download URL:', downloadURL);
            return downloadURL;
        } catch (error) {
            console.error('Failed to upload image:', error);
            throw new Error("Failed to upload image.");
        }
    };

    /**
 * Adds a new product to the Firebase database.
 * @param {Object} productDetails The details of the product to add.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
    const addProduct = async (productDetails) => {
        if (!bizId || !productDetails) {
            console.error('Business ID and product details are required.');
            return null;
        }

        const productsRef = ref(database, `billing/products/${bizId}`);

        const newProduct = {
            title: {
                defaultLocal: "en",
                strings: productDetails.title

            },
            desc: {
                defaultLocal: "en",
                strings: productDetails.desc
            },
            img: {
                defaultLocal: "en",
                strings: {
                    en: productDetails.img
                }
            },
            imgName: {
                defaultLocal: "en",
                strings: {
                    en: productDetails.imgName
                }
            },
            price: Number(productDetails.price),
            currentInventory: Number(productDetails.currentInventory),
            isHidden: productDetails.isHidden || false,
            botShowWeightPercent: productDetails.botShowWeightPercent || 50,
            timestampAdded: Date.now(),
            timestampLastChanged: Date.now(),
            productId: (products.length + 1).toString().padStart(3, '0'),
            isHidden: productDetails.isHidden
        };

        if (productDetails.isBundle) {
            newProduct.isBundle = true;
            newProduct.productsInBundle = productDetails.productsInBundle;
        }

        try {
            // Push the new product and get the reference to the newly created node
            const newProductRef = push(productsRef);
            await set(newProductRef, newProduct);
            console.log('Product added successfully with ID:', newProductRef.key);
            return newProductRef.key;
        } catch (error) {
            console.error('Failed to add product:', error);
            return null;
        }
    };

    /**
 * Edits an existing product in the Firebase database.
 * @param {string} productId The ID of the product to edit.
 * @param {Object} updateDetails The details of the product to update.
 * @returns {Promise<void>} A promise that resolves when the update operation is complete.
 */
    const editProduct = async (productKey, updateDetails) => {

        if (!bizId || !productKey || !updateDetails) {
            console.error('Business ID, product ID, and update details are required.');
            return null;
        }

        const productRef = ref(database, `billing/products/${bizId}/${productKey}`);

        const updateData = {};
        if (updateDetails.title) {
            updateData['title/strings'] = updateDetails.title;
        }
        if (updateDetails.desc) {
            updateData['desc/strings'] = updateDetails.desc;
        }
        if (updateDetails.img) {
            updateData['img/strings/en'] = updateDetails.img;
        }
        if (updateDetails.price !== undefined) {
            updateData['price'] = Number(updateDetails.price);
        }
        if (updateDetails.currentInventory !== undefined) {
            updateData['currentInventory'] = Number(updateDetails.currentInventory);
        }
        if (updateDetails.isHidden !== undefined) {
            updateData['isHidden'] = updateDetails.isHidden;
        }
        if (updateDetails.botShowWeightPercent !== undefined) {
            updateData['botShowWeightPercent'] = updateDetails.botShowWeightPercent;
        }

        if (updateDetails.isBundle !== undefined) {
            updateData['isBundle'] = true;
            updateData['productsInBundle'] = updateDetails.productsInBundle;
        }



        updateData['timestampLastChanged'] = Date.now();

        try {
            await update(productRef, updateData);
            console.log('Product updated successfully with ID:', productKey);
            return productKey;  // Optionally return the product ID to confirm which product was edited
        } catch (error) {
            console.error('Failed to update product:', error);
            return null;
        }
    };

    const getProduct = (id) => {
        const product = products.filter(elm => elm.id === id);

        if (product.length === 1) {
            return product[0];
        }
        return {};
    }

    const getOrder = (id) => {
        const order = orders.filter(elm => elm.id === id);

        if (order.length === 1) {
            return order[0];
        }
        
        return {};
    }




    return (
        <ProductsContext.Provider value={{
            products,
            orders,
            updateInventory,
            uploadProductImage,
            addProduct,
            editProduct,
            getProduct,
            updateOrderStatus,
            isOrderStatusPending,
            getOrder
        }}>
            {children}
        </ProductsContext.Provider>
    );
};

export const useProducts = () => useContext(ProductsContext);
