import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';

const colors = [
    { id: 1, hex: '#B06161' },
    { id: 2, hex: '#FFCF81' },
    { id: 3, hex: '#A5DD9B' },
    { id: 4, hex: '#7BD3EA' },
    { id: 5, hex: '#756AB6' },
    { id: 6, hex: '#618264' },
];

export default function ColorPickerDropdown({ setSelectedColor, selectedColor }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <span className="block w-7 h-7 rounded-full" style={{ backgroundColor: selectedColor.hex }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2} display="flex">
                    {colors.map((color) => (
                        <IconButton
                            key={color.id}
                            onClick={() => { setSelectedColor(color); handleClose(); }}
                            style={{
                                backgroundColor: color.hex,
                                margin: '0 4px',
                                width: '24px', // Ensuring the width is the same as height
                                height: '24px', // Making it a perfect square
                                borderRadius: '50%', // This makes the square a circle
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: adding shadow
                                border: selectedColor.id === color.id ? '2px solid #fff' : '1px solid rgba(255,255,255,0.5)' // Optional: enhancing border for selected
                            }}
                            aria-label="Select color"
                        >
                            {selectedColor.id === color.id && <CheckIcon sx={{ color: 'white' }} />}
                        </IconButton>

                    ))}
                </Box>
            </Popover>
        </div>
    );
}
