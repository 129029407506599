import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, database } from '../../book/src/firebase';
import SpinnerOverlay from '../SpinnerOverlay';
import Papa from 'papaparse';
import {
  ref,
  get,
  onValue,
  off,
  onChildChanged,
  update,
  push,
  runTransaction,
  remove,
} from 'firebase/database';
import { useNavigate } from 'react-router';
import { translate } from 'src/translate';

const AuthContext = createContext();

const findOwnerOrStaffIdAndAccessType = (obj) => {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const accessType = obj[key].accessType;
    if (accessType === 'OWNER' || accessType === 'STAFF') {
      return { id: key, accessType };
    }
  }
  return { id: null, accessType: 'NORMAL' };
};

const findAdmins = (obj) => {
  const admins = [];
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const accessType = obj[key].accessType;
    if (accessType === 'OWNER' || accessType === 'STAFF') {
      admins.push({ id: key, accessType });
    }
  }
  return admins;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [businessInfo, setBusinessInfo] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [tags, setTags] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [bizNames, setBizNames] = useState([]);

  const [features, setFeatures] = useState({});

  const [genericDetailsColumns, setGenericDetailsColumns] = useState(null);

  const [customersTagsCount, setCustomersTagsCount] = useState({});

  const [hiddenTagsKeys, setHiddenTagsKeys] = useState({});

  const navigate = useNavigate();

  const [loginTimeStamp, setLoginTimeStamp] = useState(() => {
    const savedTimeStamp = localStorage.getItem('loginTimeStamp');
    return savedTimeStamp ? new Date(savedTimeStamp) : null;
  });

  const [attemptCounter, setAttemptCounter] = useState(() => {
    const savedAttempts = localStorage.getItem('attemptCounter');
    return savedAttempts ? parseInt(savedAttempts, 10) : 0;
  });

  const resetAttemptCounter = () => {
    const newAttemptCount = 0;
    setAttemptCounter(newAttemptCount);
    localStorage.setItem('attemptCounter', newAttemptCount.toString());
  };

  const updateLoginTimeStamp = () => {
    const newTimeStamp = new Date();
    setLoginTimeStamp(newTimeStamp);
    localStorage.setItem('loginTimeStamp', newTimeStamp);

    // Update attempt counter
    const newAttemptCount = attemptCounter + 1;
    setAttemptCounter(newAttemptCount);
    localStorage.setItem('attemptCounter', newAttemptCount.toString());
  };

  const [bizId, setBizId] = useState(() => {
    // Initial state is fetched from localStorage if available
    return localStorage.getItem('bizId');
  });

  useEffect(() => {
    // Whenever bizId changes, update localStorage
    if (bizId !== null) {
      localStorage.setItem('bizId', bizId);
    }
  }, [bizId]);

  const getBizName = (businessId) => {
    const business = bizNames.filter((biz) => biz.id === businessId);
    if (business.length === 1) {
      return business[0]?.name;
    }
    return businessId;
  };

  const isFeatureEnabled = (featureName) => {
    if (features[featureName]) {
      return features[featureName].isEnabled;
    }

    return false;
  };

  // get all of the biz names that the admins have access to
  useEffect(() => {
    const fetchBizNamesForAdmins = async () => {
      try {
        const bizNamesArray = [];
        for (const businessId of admins) {
          const dbRef = ref(database, `/biz/userInfo/${businessId}`);
          const snapshot = await get(dbRef);

          if (snapshot.exists()) {
            const bizName = snapshot.val().bizName;
            bizNamesArray.push({ id: businessId, name: bizName });
          } else {
          }
        }
        setBizNames(bizNamesArray);
      } catch (error) {
        console.error(error);
      }
    };
    // Whenever bizId changes, update localStorage
    if (admins.length > 1) {
      fetchBizNamesForAdmins();
    }
  }, [admins, bizId]);

  useEffect(() => {
    const fetchBizInfo = async () => {
      try {
        const dbRef = ref(database, `/biz/userInfo/${bizId}`);
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          setBusinessInfo(snapshot.val());
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Whenever bizId changes, update localStorage

    if (bizId !== null) {
      fetchBizInfo();
    }
  }, [bizId]);

  useEffect(() => {
    const fetchUserAccessType = async (user) => {
      let bizzId = null;
      try {
        const dbRef = ref(database, `/users/businesses/${user.uid}`);
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const val = snapshot.val();

          const { id, accessType } = findOwnerOrStaffIdAndAccessType(val);

          if (accessType === 'NORMAL') {
            localStorage.removeItem('bizId');
            signOut(auth); // log the user out
            navigate('/auth/404'); // redirect to 404 because snapshot does
            return;
          }

          const admins = findAdmins(val);
          const adminsArray = admins.map((admin) => admin.id);
          setAdmins(adminsArray);

          // in case we have super admin we dont want to override the option he chosed
          if (admins.length > 1 && bizId) {
            const findAdmin = admins.filter((admin) => admin.id === bizId);
            // in case we dont find the bizId in admins
            if (findAdmin.length === 0) {
              setBizId(admins[0].id);
            }

            // do nothing for now
          } else {
            setBizId(id);
          }

          bizzId = id;

          setAccessType(accessType);

          // setUserStatus(Object.values(val)[0]);
          // setUserStatus(val);
          // displayAdminMessageIfNeeded(val);
          // console.log(val);
        } else {
          localStorage.removeItem('bizId');
          signOut(auth); // log the user out
          navigate('/auth/404'); // redirect to 404 because snapshot does
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        fetchUserAccessType(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (bizId) {
      const dbRef = ref(database, `/biz/userData/${bizId}`);

      const listener = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const info = snapshot.val();

            let formattedInfo = Object.entries(info).map(([id, details]) => ({
              id,
              ...details,
            }));
            formattedInfo = formattedInfo.filter((element) => element.name);

            const parsedInfo = formattedInfo.map((customerInfo) => {
              // parse the time stamps
              try {
                if (customerInfo.number?.startsWith('9725')) {
                  customerInfo.numberLocal = customerInfo.number.replace(/^972/, '0');
                }
              } catch (e) {}

              if (customerInfo.prevEvent) {
                customerInfo.prevEventTime = customerInfo.prevEvent.startTimestamp;
              }

              if (customerInfo.nextEvent) {
                customerInfo.nextEventTime = customerInfo.nextEvent.startTimestamp;
              }

              return customerInfo;
            });

            setCustomers(parsedInfo);

            // if (tags && tags.length > 0) {
            //   const hiddenTags = tags.filter((tag) => tag.isHidden);

            //   const hiddenTagsKeys = hiddenTags.reduce((obj, tag) => {
            //     obj[tag.value] = true; // Set each key to true or could be the tag object itself
            //     return obj;
            //   }, {});

            //   const updatedCustomers = parsedInfo.map((customer) => {
            //     // Proceed with filtering only if the customer has tags
            //     if (customer.tags && customer.tags.length > 0) {
            //       const filteredTags = customer.tags.filter((tag) => {
            //         const tagKey = Object.keys(tag)[0];
            //         return !hiddenTagsKeys[tagKey];
            //       });

            //       // Only add the tags property if there are filtered tags remaining
            //       if (customer.tags) {
            //         return { ...customer, tags: filteredTags };
            //       }
            //     }

            //     // Return the customer object without the tags property if no tags exist or all were filtered out
            //     return { ...customer };
            //   });

            //   setCustomers(updatedCustomers);
            // } else {
            // }

            // setCustomers(parsedInfo);
            // setRows(formattedInfo);
            // console.log(formattedInfo);
          } else {
            // Handle the case where there is no data at the path
            console.log('No data available customers');
            setCustomers([]); // Or however you wish to handle this case
          }
        },
        (error) => {
          console.error(error);
        },
      );

      // Clean up the listener when the component unmounts or bizId changes
      return () => listener();
    }
  }, [bizId, database, tags]);

  useEffect(() => {
    if (bizId) {
      const dbRef = ref(database, `/biz/configs/${bizId}/features`);

      const listener = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const info = snapshot.val();
            setFeatures(info);

            // const xx = 'xx';

            // let formattedInfo = Object.entries(info).map(([id, details]) => ({
            //     id,
            //     ...details
            // }));
            // formattedInfo = formattedInfo.filter(element => element.name);

            // const parsedInfo = formattedInfo.map(customerInfo => {

            // // parse the time stamps

            //     return customerInfo;
            // })

            // setRows(formattedInfo);
            // console.log(formattedInfo);
          } else {
            setFeatures({});
            // Handle the case where there is no data at the path
            // console.log('No data available customers');
          }
        },
        (error) => {
          console.error(error);
        },
      );

      // Clean up the listener when the component unmounts or bizId changes
      return () => listener();
    }
  }, [bizId, database]);

  React.useEffect(() => {
    const fetchBizTags = () => {
      const dbRef = ref(database, `/biz/tags/${bizId}/users`);

      const handleSnapshot = (snapshot) => {
        if (snapshot.exists()) {
          processSnapshot(snapshot);
        }
      };

      const handleChildChanged = (childSnapshot, prevChildKey) => {
        get(dbRef).then((fullSnapshot) => {
          if (fullSnapshot.exists()) {
            processSnapshot(fullSnapshot);
          }
        });
      };

      const processSnapshot = (snapshot) => {
        try {
          const info = snapshot.val();

          // todo make this empty array
          let transformedTags = null;

          if (info) {
            transformedTags = Object.keys(info)?.map((key) => ({
              value: key,
              label: info[key].name,
              color: info[key].color,
              isHidden: info[key].isHidden ?? false,

              isEditable: info[key].isEditable ?? true,
            }));
          }

          if (transformedTags && transformedTags.length > 0) {
            const hiddenTags = transformedTags.filter((tag) => tag.isHidden);

            const hiddenTagsKeys = hiddenTags.reduce((obj, tag) => {
              obj[tag.value] = true; // Set each key to true or could be the tag object itself
              return obj;
            }, {});

            setHiddenTagsKeys(hiddenTagsKeys);
          }

          setTags(transformedTags);
        } catch (error) {
          console.error(error);
        }
      };

      onValue(dbRef, handleSnapshot, {
        onlyOnce: false,
      });

      onChildChanged(dbRef, handleChildChanged, {
        onlyOnce: false,
      });
    };

    if (bizId) {
      fetchBizTags();
    }

    return () => {
      const dbRef = ref(database, `/biz/tags/${bizId}/users`);
      off(dbRef, 'value');
      off(dbRef, 'child_changed');
    };
  }, [bizId]);

  useEffect(() => {
    const counterObject = {};

    if (tags && tags.length > 0 && customers) {
      tags.forEach((tag) => {
        counterObject[tag.value] = 0;
      });

      customers.forEach((customer) => {
        if (customer.tags && customer.tags.length > 0) {
          customer.tags.forEach((tag) => {
            const tagKey = Object.keys(tag)[0];
            counterObject[tagKey] = counterObject[tagKey] + 1;
          });
        }
      });

      setCustomersTagsCount(counterObject);
    }
  }, [tags, customers]);

  // useEffect(() => {
  //     const fetchCustomers = async (user) => {
  //         try {
  //             const dbRef = ref(database, `/users/businesses`);
  //             const snapshot = await get(dbRef);

  //             if (snapshot.exists()) {
  //                 const customersUnfiltered = snapshot.val();

  //                 const filteredCustomers = Object.entries(customersUnfiltered).reduce((acc, [customerId, businesses]) => {
  //                     // Check if the customer has the specified businessId
  //                     if (businesses.hasOwnProperty(bizId)) {
  //                         // Add customer to the accumulator with minimal data
  //                         acc[customerId] = {
  //                             // [bizId]: {
  //                             accessType: businesses[bizId].accessType,
  //                             blacklist: businesses[bizId].blacklist,
  //                             defaultLanguage: businesses[bizId].defaultLanguage,
  //                             displayName: businesses[bizId].displayName,
  //                             firstAppearance: businesses[bizId].firstAppearance
  //                             // }
  //                         };
  //                     }
  //                     return acc;
  //                 }, {});

  //                 setCustomers(filteredCustomers);
  //                 console.log(filteredCustomers);

  //             } else {
  //                 return;
  //             }

  //         } catch (error) {
  //             console.error(error);
  //         }
  //     };

  //     if (user && bizId) {
  //         fetchCustomers()
  //     }

  // }, [bizId]);

  // React.useEffect(() => {
  //     if (user) {
  //         const fetchUserAccessType = async () => {
  //             try {
  //                 const dbRef = ref(database, `/users/businesses/${user.uid}`);
  //                 const snapshot = await get(dbRef);

  //                 if (snapshot.exists()) {
  //                     const val = snapshot.val();

  //                     const {id, accessType} = findOwnerOrStaffIdAndAccessType(val);
  //                     setUserId(id);
  //                     setAccessType(accessType);

  //                     // setUserStatus(Object.values(val)[0]);
  //                     // setUserStatus(val);
  //                     // displayAdminMessageIfNeeded(val);
  //                     console.log(val);

  //                 } else {

  //                 }
  //             } catch (error) {
  //                 console.error(error);
  //             }
  //         };

  //         fetchUserAccessType();
  //     } else {
  //         // setUserStatus(null);
  //     }
  // }, []);

  const updatePhoneNumber = (newPhoneNumber) => {
    setPhoneNumber(newPhoneNumber);
  };

  const getCustomerUid = (phoneNumber) => {
    if (customers === null) return null;
    const customersFiltered = customers.filter((customer) => customer.number === phoneNumber);
    if (customersFiltered.length === 0) return null;
    return customersFiltered[0].id;
  };

  const getCustomer = (uid) => {
    if (customers === null) return null;
    const customersFiltered = customers.filter((customer) => customer.id === uid);
    if (customersFiltered.length === 0) return null;
    return customersFiltered[0];
  };

  // tags and notes context

  const getUserAvailableTags = (tags, answer) => {
    try {
      if (!tags) {
        return [];
      }

      const answerKeys = answer ? answer?.map((obj) => Object.keys(obj)[0]) : [];

      if (!answerKeys) {
        return [];
      }

      // Filter tags not in answer and format them in an array of objects
      return tags?.reduce((acc, tag) => {
        if (!answerKeys.includes(tag.value)) {
          const obj = {};
          obj[tag.value] = 1; // Set timestamp to 1
          acc.push(obj);
        }
        return acc;
      }, []);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const updateTags = (customerId, answerId, tagId, tags, operation) => {
    // if there isnt any tag, init an empty array
    if (!tags) {
      tags = [];
    }

    let filteredTags;
    const timeAdded = new Date();

    // if its delete op, we delete the relevant tag
    if (operation === 'delete') {
      filteredTags = tags.filter((tag) => !(tagId in tag));
    }
    // if its add operation, we add to the tags
    else {
      tags.push({ [tagId]: timeAdded.getTime() });
      filteredTags = tags;
    }

    const dbRef = ref(database, `biz/userData/${bizId}/${customerId}`);

    try {
      update(dbRef, { tags: filteredTags });
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to update tag', error);
    }
  };

  const addTag = (tagName, tagColor) => {
    const timeAdded = new Date();
    const dbRef = ref(database, `/biz/tags/${bizId}/users`);

    try {
      const tagRef = push(dbRef, {
        createdTimestamp: timeAdded.getTime(),
        name: tagName,
        color: tagColor,
      });
      const tagId = tagRef.key;
      return tagId;
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to update tag', error);
    }
  };

  // Function to edit or delete a tag based on the operation specified
  const modifyTag = async (operation, tagId, tagName = null, tagColor = null) => {
    const dbRef = ref(database, `/biz/tags/${bizId}/users/${tagId}`);

    try {
      if (operation === 'edit') {
        // Check if both tagName and tagColor are provided for editing
        if (tagName === null || tagColor === null) {
          console.error('Both tag name and color must be provided for editing.');
          return;
        }
        // Perform update operation
        await update(dbRef, { name: tagName, color: tagColor });
      } else if (operation === 'delete') {
        // Perform delete operation
        await update(dbRef, { name: tagName, color: tagColor, isHidden: true });
      } else {
        console.error('Invalid operation. Please specify "edit" or "delete".');
      }
    } catch (error) {
      console.error(`Failed to ${operation} tag`, error);
    }
  };

  // Example usage:
  // modifyTag('edit', 'someTagId', 'New Tag Name', 'New Tag Color');
  // modifyTag('delete', 'someTagId');

  const addNote = (customerId, note) => {
    const timeAdded = new Date();
    const notesRef = ref(database, `/biz/userData/${bizId}/${customerId}/notes`);

    const formattedNote = note.replace(/\n/g, '<br />');

    // Use a transaction to safely append a note
    runTransaction(notesRef, (currentNotes) => {
      if (currentNotes === null) {
        return [{ timestamp: timeAdded.getTime(), note: formattedNote, color: 'blue' }];
      } else {
        currentNotes.push({ timestamp: timeAdded.getTime(), note: formattedNote, color: 'blue' });
        return currentNotes;
      }
    })
      .then((result) => {
        if (result.committed) {
          console.log('Note added successfully.');
        } else {
          console.log('Transaction not committed.');
        }
      })
      .catch((error) => {
        console.error('Transaction failed:', error);
      });
  };

  const removeNote = (customerId, timestampToRemove) => {
    const notesRef = ref(database, `/biz/userData/${bizId}/${customerId}/notes`);

    // Use a transaction to safely remove a note
    runTransaction(notesRef, (currentNotes) => {
      if (currentNotes === null) {
        // If there are no notes, there's nothing to remove
        return; // No need to update the notes array
      } else {
        // Filter out the note with the specific timestamp
        const filteredNotes = currentNotes.filter((note) => note.timestamp !== timestampToRemove);
        return filteredNotes;
      }
    })
      .then((result) => {
        if (result.committed) {
          console.log('Note removed successfully.');
        } else {
          console.log('Transaction not committed, no changes made.');
        }
      })
      .catch((error) => {
        console.error('Transaction failed:', error);
      });
  };

  // business details context

  const changeCustomerName = (customerId, newName) => {
    const dbRef = ref(database, `biz/userData/${bizId}/${customerId}`);

    try {
      update(dbRef, { name: newName });
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to customer name', error);
    }

    const dbRefName = ref(database, `users/businesses/${customerId}/${bizId}`);

    try {
      update(dbRefName, { displayName: newName });
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to customer name', error);
    }
  };

  const updateCustomerGenericDetails = (customerId, newGenericDetails) => {
    const dbRef = ref(database, `biz/userData/${bizId}/${customerId}`);

    try {
      update(dbRef, { genericDetails: newGenericDetails });
      // console.log('Working hours saved successfully.');
    } catch (error) {
      console.error('Failed to customer name', error);
    }
  };

  useEffect(() => {
    const fetchGenericDetailsColumns = () => {
      const dbRef = ref(database, `/biz/configs/${bizId}/genericDetails`);

      const onValueChange = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setGenericDetailsColumns(snapshot.val());
          } else {
            // Handle the case where there is no data
            setGenericDetailsColumns(null);
          }
        },
        (error) => {
          console.error(error);
        },
      );

      return () => off(dbRef, 'value', onValueChange);
    };

    if (bizId) {
      const unsubscribe = fetchGenericDetailsColumns();
      return unsubscribe;
    }
  }, [bizId]);

  const getGenericDetailsColumnHeader = (headerName, language) => {
    if (!genericDetailsColumns) {
      return 'Error';
    }

    if (genericDetailsColumns[headerName][language]) {
      return genericDetailsColumns[headerName][language];
    }
    return genericDetailsColumns[headerName][Object.keys(genericDetailsColumns[headerName])[0]];
  };

  const exportToCsv = (sortedCustomers, language) => {
    try {
      const csvArray = [];

      // Collect all unique keys from genericDetails
      const allKeys = new Set();
      sortedCustomers.forEach((customer) => {
        if (customer.genericDetails) {
          Object.keys(customer.genericDetails).forEach((key) => {
            allKeys.add(key);
          });
        }
      });

      for (const customer of sortedCustomers) {
        const genericDetails = customer.genericDetails || {};

        const objectToAdd = {};

        objectToAdd[translate('name', language)] = customer.name;
        objectToAdd[translate('phoneNumber', language)] = customer.number;

        // Add each detail or a placeholder if missing
        allKeys.forEach((key) => {
          const header = getGenericDetailsColumnHeader(key, language);
          objectToAdd[header] = genericDetails[key] ? genericDetails[key].toString() : '-';
        });

        csvArray.push(objectToAdd);
      }

      // Function to collect all unique question keys from the dataset

      // const allQuestionKeys = Array.from(getAllQuestionKeys());

      // const processedData = sortedAnswers.map((item) => {
      //   // Fill in each question, using a dash "-" where data is missing

      //   const filledQuestions = allQuestionKeys.reduce((acc, key) => {
      //     const headerKey = Utils.getLanguageOrDefault({ strings: questions[key] }, language);

      //     acc[headerKey] = item.questions[key] ? item.questions[key].val : '-';
      //     return acc;
      //   }, {});

      //   return {
      //     ...filledQuestions,

      //     [translate('phoneNumber', language)]: getCustomer(item.customerId)?.number,
      //     [translate('name', language)]: getCustomer(item.customerId)?.name,
      //   };
      // });

      // const processedData = { name: 'George', phone: '05473737' };

      const csv = Papa.unparse(csvArray, {
        header: true,
        delimiter: ',', // You can specify a custom delimiter
      });

      // Create a Blob from the CSV String
      // Create a Blob with UTF-8 encoding and BOM
      const BOM = '\uFEFF';
      const csvBlob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvBlob);

      return csvUrl;
    } catch (err) {
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        phoneNumber,
        updatePhoneNumber,
        bizId,
        accessType,
        businessInfo,
        customers,
        getCustomerUid,
        getCustomer,
        tags,
        getUserAvailableTags,
        updateTags,
        addTag,
        admins,
        setBizId,
        getBizName,
        isFeatureEnabled,
        addNote,
        removeNote,
        genericDetailsColumns,
        changeCustomerName,
        updateCustomerGenericDetails,
        getGenericDetailsColumnHeader,
        updateLoginTimeStamp,
        loginTimeStamp,
        customersTagsCount,
        modifyTag,
        hiddenTagsKeys,
        exportToCsv,
        attemptCounter,
        resetAttemptCounter,
      }}
    >
      {!loading ? children : <SpinnerOverlay />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
