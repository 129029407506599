import { enUS, ar, he } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { useLanguage } from 'src/LanguageContext';
import SentryLogger from 'src/components/logging/SentryLogger';

function RelativeDateWithOptions({ date }) {
    const { language } = useLanguage();

    const logger = new SentryLogger();

    // Initialize chosen locale
    let chosenLocale = enUS;

    // Adjust locale based on language context
    switch (language) {
        case 'en':
            chosenLocale = enUS;
            break;
        case 'ar':
            chosenLocale = ar;
            break;
        case 'heb':
            chosenLocale = he;
            break;
        default:
            chosenLocale = enUS; // Default to English if language is unsupported
    }

    try {
        // Check if date is a string, attempt to parse it
        if (typeof date === 'string') {
            const timestamp = Number(date);
            date = new Date(timestamp);
            if (isNaN(date.getTime())) {
                return <></>; // Return empty fragment if no date
            }
        }

        if (!date) {
            return <></>; // Return empty fragment if no date
        }

        return <>{formatDistanceToNow(date, { addSuffix: true, locale: chosenLocale })}</>;
    } catch (error) {
        logger.error(error);
        console.error("Date conversion error:", error);
        return <></>; // Return empty fragment on error
    }
}

export default RelativeDateWithOptions;
