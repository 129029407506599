import { Fragment } from 'react';
import { Popover, PopoverButton, Transition } from '@headlessui/react';
import { ChevronDownIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';

export default function Filters({
  setSelectedFilters,
  selectedFilters,
  filters,
  filtersCount = null,
  // options:
  selectAllOption = true,
  showNumberOption = false,
  isSortBy = false,
}) {
  const { language } = useLanguage();

  const handleFilterChange = (filterValue, filterLabel, isChecked) => {
    if (isChecked) {
      setSelectedFilters((prev) => [
        ...(Array.isArray(prev) ? prev : []),
        { value: filterValue, label: filterLabel },
      ]);
    } else {
      setSelectedFilters((prev) => prev.filter((filter) => filter.value !== filterValue));
    }
  };

  const handleSortFilterChange = (filterValue, filterLabel, isChecked) => {
    if (isChecked) {
      setSelectedFilters((prev) => [{ value: filterValue, label: filterLabel }]);
    }
    // we dont want to allow un checking
  };

  const countSelectedFilters = (section) => {
    if (!section.options || section.options.length === 0) return '(0)';
    return '(' + section.options.filter((opt) => isFilterSelected(opt.value)).length + ')';
  };

  const toggleAllFilters = (section, isChecked) => {
    if (isChecked) {
      const newFilters = section.options.map((opt) => ({ value: opt.value, label: opt.label }));
      setSelectedFilters((prev) => [...newFilters]);
    } else {
      // const valuesToRemove = section.options.map(opt => opt.value);
      setSelectedFilters([]);
    }
  };

  const removeFilter = (filterValue) => {
    setSelectedFilters((prev) => prev.filter((filter) => filter.value !== filterValue));
  };

  const isFilterSelected = (filterValue) =>
    selectedFilters?.some((filter) => filter.value === filterValue);

  const areAllSelected = (section) =>
    section.options?.every((opt) => selectedFilters?.some((f) => f.value === opt.value));

  return (
    <div className="mt-2 mb-2">
      <div className="mt-1">
        <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
          {filters &&
            filters.length > 0 &&
            filters.map((section) => (
              <Popover key={section.name} className="relative inline-block px-4 text-left">
                <PopoverButton
                  className={`group inline-flex ${
                    language === 'en' ? 'gap-1' : 'gap-2'
                  } justify-center text-sm font-medium text-gray-700 hover:text-gray-900 no-focus-outline `}
                >
                  <span>
                    {translate(section.name, language)}
                    {showNumberOption && countSelectedFilters(section)}
                  </span>

                  {!isSortBy ? (
                    <ChevronDownIcon
                      className={`-mr-1 ${
                        language === 'en' ? 'mr-2' : 'ml-2'
                      }  h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500`}
                      aria-hidden="true"
                    />
                  ) : (
                    <AdjustmentsHorizontalIcon
                      className={`-mr-1 ${
                        language === 'en' ? 'mr-2' : 'ml-2'
                      }  h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500`}
                      aria-hidden="true"
                    />
                  )}
                </PopoverButton>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Popover.Panel
                    className={` fixed z-50 mt-2 rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right`}
                  >
                    <div className="space-y-4 ">
                      {selectAllOption && (
                        <div className="flex items-center gap-2">
                          <input
                            id={`select-all-${section.id}`}
                            type="checkbox"
                            checked={areAllSelected(section)}
                            onChange={(e) => toggleAllFilters(section, e.target.checked)}
                            className="h-4 w-4 rounded border-gray-300 text-watsPrimary focus:ring-watsPrimary"
                          />
                          <label
                            htmlFor={`select-all-${section.id}`}
                            className=" text-sm font-medium text-gray-900"
                          >
                            {areAllSelected(section)
                              ? translate('deSelectAll', language)
                              : translate('selectAll', language)}
                          </label>
                        </div>
                      )}
                      {section?.options?.map((option, optionIdx) => (
                        <div
                          key={option.value}
                          className={`flex items-center ${
                            language === 'en' ? ' gap-2' : ' gap-2'
                          } `}
                        >
                          <input
                            id={`filter-${section.id}-${optionIdx}`}
                            type="checkbox"
                            checked={isFilterSelected(option.value)}
                            onChange={(e) =>
                              !isSortBy
                                ? handleFilterChange(option.value, option.label, e.target.checked)
                                : handleSortFilterChange(
                                    option.value,
                                    option.label,
                                    e.target.checked,
                                  )
                            }
                            className="h-4 w-4 rounded border-gray-300 text-watsPrimary focus:ring-watsPrimary"
                          />
                          <label
                            // dir={language === 'ar' || language === 'he' ? 'rtl' : 'ltr'}
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className={`flex items-center gap-x-1 text-sm font-medium text-gray-900`}
                          >
                            <span>
                              {option.needsTranslation
                                ? translate(option.label, language)
                                : option.label}
                            </span>

                            {filtersCount && (
                              <span className="min-w-max">({filtersCount[option.value]})</span>
                            )}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            ))}
        </Popover.Group>
      </div>
    </div>
  );
}
