export async function executeApiRequest(url, method, data, token) {
    const headers = {
      'Content-Type': 'application/json',
    };
  
    // Add Authorization header if token is provided
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    const options = {
      method: method,
      headers: headers,
      body: JSON.stringify(data),
    };
  
    // Do not include the body for GET requests
    if (method === 'GET') {
      delete options.body;
    }
  
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error making the API request:', error);
    }
  }
