import {
  BoltIcon,
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  TagIcon,
  CreditCardIcon,
  EyeDropperIcon,
  LinkIcon,
  NoSymbolIcon,
  PhoneIcon,
  UserCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Modal } from '@mui/material';
import { PlusIcon } from '@heroicons/react/20/solid';

import React, { useState, useEffect, Fragment } from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  addMonths,
  subMonths,
  startOfDay,
  isBefore,
} from 'date-fns';
import ComboBox from 'src/layouts/full/horizontal/ComboBox';
import ScheduleManager from 'src/book/src/data/ScheduleManager';
import { useLanguage } from 'src/LanguageContext';
import { useAuth } from 'src/views/authentication/AuthContext';
import { database } from 'src/book/src/firebase';
import { ref, get, onValue } from 'firebase/database';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import { element } from 'prop-types';
import ScheduleAppointmentDialog from './ScheduleAppointmentDialog';
import DropDown from 'src/layouts/full/horizontal/DropDown';
import DropDownAptType from 'src/layouts/full/horizontal/DropDownAptType';
import { translate } from 'src/translate';

import { ReactComponent as WhatsappIcon } from '../../assets/images/svgs/whatsappIcon.svg';
import SpinnerOverlay from 'src/views/SpinnerOverlay';
import { ScheduleRounded } from '@mui/icons-material';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ScheduleAppointment({
  setCancelAppointmentOpen,
  setAlreadyScheduledTimeSlot,
  setUserPaidDeposit,
  setSelectedTime,
  selectedTime,
  alreadyScheduledOpen,
  setAlreadyScheduledOpen,
  setMultiBookOpen,
  selectedCustomerBooked,
  setSelectedCustomerBooked,
  customers,
  upcomingEvents,
  isOpen,
  toggleAppoitmentDialog,
  selectedEvent,
  setSelectedEvent,
  addCustomerOpen,
  setAddCustomerOpen,
  setScheduleNoCustomerOpen,
  selectedCustomer,
  setSelectedCustomer,
}) {
  const { language } = useLanguage();
  const { bizId, user, getCustomerUid, isFeatureEnabled } = useAuth();

  const [currentDate, setCurrentDate] = useState(startOfMonth(new Date()));

  // Function to go to the next month
  const nextMonth = () => {
    setCurrentDate((prevMonth) => addMonths(prevMonth, 1));
  };

  // Function to go to the previous month
  const prevMonth = () => {
    setCurrentDate((prevMonth) => subMonths(prevMonth, 1));
  };

  const [days, setDays] = useState([]);

  const [bizOptions, setBizOptions] = useState(null);

  const [timeOptions, setTimeOptions] = useState(null);
  // const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isNewNumber, setIsNewNumber] = useState(false);

  const [showRedRing, setShowRedRing] = useState(false);

  const [selectedServiceValue, setSelectedServiceValue] = useState(null);

  let scheduleManager = new ScheduleManager(bizOptions, language);

  React.useEffect(() => {
    if (selectedCustomer) {
      const scheduledEventRes = ScheduleManager.isCustomerScheduled(
        upcomingEvents,
        customers,
        selectedCustomer,
      );

      if (scheduledEventRes !== null) {
        if (scheduledEventRes.length === 1) {
          setSelectedCustomerBooked(scheduledEventRes[0]);
        } else {
          const selectedEventfiltered = scheduledEventRes.filter(
            (ev) => ev?.eventId === selectedEvent?.eventId,
          );

          if (selectedEventfiltered.length === 1) {
            setSelectedCustomerBooked(selectedEventfiltered[0]);
          } else {
            setSelectedCustomerBooked(null);
          }
        }
      }
    }
  }, [selectedCustomer]);

  const appoitmentDetails = {
    footerText: bizOptions
      ? ScheduleManager.getFooterText(bizOptions, selectedStaff, selectedService, language)
      : null,
    selectedDayFormated:
      selectedDay === null ? null : ScheduleManager.getChosenDateFormatted(selectedDay, language),
    selectedTimeFormated:
      selectedTime === null ? null : ScheduleManager.getChosenTimeFormatted(selectedTime),
    staffId: selectedStaff,
    aptTypeId: selectedService,
    startTimestamp: selectedTime,
  };

  // const [] = useState();

  const fetchBusinessData = () => {
    try {
      const dbRef = ref(database, `/biz/options/${bizId}`);
      const unsubscribe = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setBizOptions(snapshot.val());
            scheduleManager = new ScheduleManager(snapshot.val(), language);

            const startTime = performance.now();
          } else {
            console.log('No data available');
          }
        },
        (error) => {
          console.error(error);
        },
      );

      // Returning unsubscribe function for cleanup
      return unsubscribe;
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (bizId) {
      const unsubscribe = fetchBusinessData();

      // Cleanup function to unsubscribe from changes when the component unmounts or bizId changes
      return () => unsubscribe();
    }
  }, [bizId]);

  useEffect(() => {
    if (bizId) {
      executeApiRequest(
        `${apiBaseUrl}/refreshDates`,
        'POST',
        {
          bizId: bizId,
          uid: user.uid,
        },
        user.accessToken,
      )
        .then(() => {
          const endTime = performance.now();
          // console.log(`API call took ${(endTime - startTime) / 1000} seconds.`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [bizId]);

  const todayTime = new Date();

  // Generate the calendar days whenever the currentDate changes
  useEffect(() => {
    const { DateTime } = require('luxon');

    if (bizOptions && selectedStaff && selectedService) {
      const scheduleManager = new ScheduleManager(bizOptions, language);

      const services = scheduleManager.getStaffServices(selectedStaff);
      const selectedServiceDetails = services.find((e) => e.id === selectedService) || services[0];
      const duration = 'dur-' + selectedServiceDetails.duration;

      const availableDays = scheduleManager.getServiceTimeSlots(selectedStaff, duration, language);

      const convertedAvailableDays = Object.entries(availableDays).reduce(
        (acc, [dayKey, slots]) => {
          // Convert the dayKey to a DateTime object in Asia/Jerusalem timezone
          const dayDateTime = DateTime.fromMillis(parseInt(dayKey))
            .setZone('UTC')
            .setZone('Asia/Jerusalem');
          const formattedDay = dayDateTime.toFormat('yyyy-MM-dd');

          // Map each slot to the new timezone and format if needed
          const convertedSlots = slots.map((slot) => {
            const slotDateTime = DateTime.fromMillis(parseInt(slot.time))
              .setZone('UTC')
              .setZone('Asia/Jerusalem');
            return {
              ...slot,
              time: slotDateTime.toFormat('yyyy-MM-dd HH:mm'),
            };
          });

          acc[formattedDay] = convertedSlots;
          return acc;
        },
        {},
      );

      const startDay = startOfWeek(startOfMonth(currentDate));
      const endDay = endOfWeek(endOfMonth(currentDate));
      const interval = eachDayOfInterval({ start: startDay, end: endDay });

      const formattedDays = interval.map((day) => ({
        date: format(day, 'yyyy-MM-dd'),
        isCurrentMonth: format(day, 'MM-yyyy') === format(currentDate, 'MM-yyyy'),
        isPastDay: isBefore(day, startOfDay(todayTime)),
        isAvailableDay: convertedAvailableDays.hasOwnProperty(format(day, 'yyyy-MM-dd')),

        // Add more properties as needed, e.g., isSelected
      }));

      if (format(currentDate, 'MM-yyyy') === format(new Date(), 'MM-yyyy')) {
        const updatedDays = formattedDays.map((day) => {
          if (day.date === format(new Date(), 'yyyy-MM-dd')) {
            return { ...day, isSelected: true };
          } else if (day.isSelected) {
            const { isSelected, ...rest } = day;
            return rest;
          }
          return day;
        });
        setDays(updatedDays);
      } else {
        setDays(formattedDays, selectedStaff, selectedService);
      }
    }
  }, [currentDate, bizOptions, selectedService, selectedStaff]);

  useEffect(() => {
    if (
      currentDate &&
      selectedService &&
      selectedStaff &&
      format(currentDate, 'MM-yyyy') === format(new Date(), 'MM-yyyy')
    ) {
      handleClickDate({ date: format(new Date(), 'yyyy-MM-dd') });
    }
  }, [currentDate, selectedService, selectedStaff]);

  // useEffect(() => {

  //   if (selectedService && selectedStaff && format(currentDate, 'MM-yyyy') === format(new Date(), 'MM-yyyy')) {
  //     handleClickDate({ date: format(currentDate, 'yyyy-MM-dd') });
  //   }

  // }, [currentDate, selectedStaff, selectedService]);

  // useEffect(() => {

  //   if (selectedService && selectedStaff && days && currentDate && format(currentDate, 'MM-yyyy') === format(new Date(), 'MM-yyyy')) {
  //     handleClickDate({ date: format(new Date(), 'yyyy-MM-dd') });
  //   }

  // }, [ selectedService, selectedStaff]);

  // useEffect(() => {
  //   if (selectedEvent) {
  //     console.log(selectedEvent);
  //   }
  // }, [selectedEvent]);

  useEffect(() => {
    // console.log(format(currentDate, 'MMMM yyyy')); // Debugging
    // console.log('selelcted day is:')
    // console.log(getSelectedDay());
  }, [days]);

  const getSelectedDay = () => {
    const { DateTime } = require('luxon');

    const selectedDay = days.filter((day) => day.isSelected);
    // return selectedDay.length === 0 ? null : selectedDay[0];

    const dateTimeObject = DateTime.fromISO(selectedDay[0]?.date);
    return dateTimeObject.toMillis();
  };

  function handleClickDate(clickedDate) {
    const scheduleMngr = new ScheduleManager(bizOptions, language);

    const services = scheduleManager.getStaffServices(selectedStaff);
    const selectedServiceDetails = services.find((e) => e.id === selectedService) || services[0];
    const duration = 'dur-' + selectedServiceDetails.duration;

    // const choosenDay = clickedDate.date;
    let apiChoosenDay = scheduleMngr.getServiceDaysSlots(selectedStaff, duration, language);
    apiChoosenDay = apiChoosenDay.filter((day) => day.dateFormatted === clickedDate.date);

    if (apiChoosenDay.length) {
      const choseenDayTimeSlots = scheduleMngr.getServiceTimeSlots(
        selectedStaff,
        duration,
        language,
      )[apiChoosenDay[0].key];
      setTimeOptions(choseenDayTimeSlots);
      setSelectedDay(apiChoosenDay[0].key);
    } else {
      setTimeOptions([]);
    }
  }

  return (
    // <AlertDialog>
    // <Modal
    //   open={isOpen}
    //   onClose={() => { }}
    //   aria-labelledby="popup-title"
    //   aria-describedby="popup-description"
    //   style={{ zIndex: 1400 }} // Dialog's z-index is 1300 by default
    // >>

    <Dialog
      open={isOpen}
      onClose={() => {
        toggleAppoitmentDialog();
        setSelectedEvent(null);
        setSelectedTime(null);
        setSelectedCustomer(null);
        setSelectedCustomerBooked(null);
      }}
      aria-labelledby="user-profile-title"
      aria-describedby="user-profile-description"
      fullWidth
      maxWidth="xs"
      style={{ zIndex: 1200 }}
      PaperProps={{
        style: {
          minHeight: '25vh', // Adjust this value as needed
          padding: '5px',
        },
      }}
    >
      <DialogTitle id="user-profile-title">
        <span className={` ${language === 'ar' ? 'font-cairo' : ''}`}>
          {translate(
            selectedCustomerBooked && !isFeatureEnabled('multi_book')
              ? 'reSchedule'
              : 'appointment',
            language,
          )}
        </span>
      </DialogTitle>

      <DialogContent>
        <div className=" font-cairo">
          {/* ScheduleAppointmentDialog */}

          {!selectedTime && (
            <>
              <div className="mt-2 flex flex-col items-start text-md text-gray-500">
                <div className="w-full flex items-center">
                  <TagIcon
                    className={`${
                      language === 'en' ? 'mr-1.5' : 'ml-1.5'
                    } h-5 w-5 flex-shrink-0 text-gray-400`}
                    aria-hidden="true"
                  />
                  {selectedServiceValue && <span> {selectedServiceValue.name} </span>}
                </div>

                <div className="w-full flex items-center mt-2">
                  <CalendarIcon
                    className={`${
                      language === 'en' ? 'mr-1.5' : 'ml-1.5'
                    } h-5 w-5 flex-shrink-0 text-gray-400`}
                    aria-hidden="true"
                  />

                  {new Date(
                    parseInt(
                      selectedCustomerBooked
                        ? selectedCustomerBooked.startTimestamp
                        : isNaN(getSelectedDay())
                        ? currentDate.getTime()
                        : getSelectedDay(),
                    ),
                  ).toLocaleDateString(language + '-us', {
                    weekday: 'long',
                    month: 'long',
                    year: 'numeric',
                    day: '2-digit',
                  })}

                  {selectedCustomerBooked && (
                    <button
                      className={` text-watsPrimary font-bold underline  ${
                        language === 'en' ? 'ml-2' : 'mr-2'
                      } `}
                      onClick={() => {
                        if (selectedCustomerBooked.invoiceId) {
                          setUserPaidDeposit(true);
                        } else {
                          setUserPaidDeposit(false);
                        }
                        setCancelAppointmentOpen(true);

                        // const sss = selectedCustomerBooked;
                      }}
                    >
                      {translate('cancel', language)}
                    </button>
                  )}
                </div>

                {selectedCustomerBooked && (
                  <div className="w-full flex items-center mt-2">
                    <ClockIcon
                      className={`${
                        language === 'en' ? 'mr-1.5' : 'ml-1.5'
                      } h-5 w-5 flex-shrink-0 text-gray-400`}
                      aria-hidden="true"
                    />
                    {`${selectedCustomerBooked.startFormatted} - ${selectedCustomerBooked.endFormatted}`}
                    {/* {selectedServiceValue && <span> {selectedServiceValue.name} </span>} */}
                  </div>
                )}

                {selectedEvent && selectedEvent.eventNotes && (
                  <div className="w-full flex items-center mt-2">
                    <PencilSquareIcon
                      className={`${
                        language === 'en' ? 'mr-1.5' : 'ml-1.5'
                      } h-5 w-5 flex-shrink-0 text-gray-400`}
                      aria-hidden="true"
                    />
                    <span> {selectedEvent.eventNotes} </span>
                  </div>
                )}
              </div>
              <div className="h-px flex-auto bg-gray-100 mt-3" />

              <div className=" flex justify-center items-center lg:ml-4 lg:mt-0 pt-3">
                <span className="ml-3 hidden sm:block">
                  <button
                    onClick={() => {
                      const whatsappURL = `https://wa.me/${selectedCustomer}`;
                      window.open(whatsappURL, '_blank');
                    }}
                    disabled={selectedCustomer === null}
                    type="button"
                    className="inline-flex items-center rounded-xl bg-white px-3 py-2 text-xs font-semibold text-gray-700  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-3"
                  >
                    <WhatsappIcon className=" h-5 w-5 text-gray-700 " aria-hidden="true" />
                    {/* Whatsapp */}
                  </button>

                  <button
                    disabled={selectedCustomer === null}
                    onClick={() => {
                      const phoneUrl = `tel:${selectedCustomer}`;
                      window.open(phoneUrl, '_blank');
                    }}
                    type="button"
                    className="inline-flex items-center rounded-xl bg-white px-3 py-2 text-xs font-semibold text-gray-700  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-3"
                  >
                    <PhoneIcon className="h-5 w-5 text-[#616161]" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </>
          )}

          {selectedTime && (
            <>
              <div className="flex items-center justify-center ">
                <div className="lg:col-start-3 lg:row-end-1">
                  <div className="rounded-lg shadow-sm">
                    <dl className="flex flex-wrap justify-center">
                      <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                        <dt className="flex-none">
                          <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd className="text-sm font-medium leading-6 text-gray-700">
                          {appoitmentDetails.footerText}
                        </dd>
                      </div>
                      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                        <dt className="flex-none">
                          <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          <time dateTime="2023-01-31">{appoitmentDetails.selectedDayFormated}</time>
                        </dd>
                      </div>
                      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                        <dt className="flex-none">
                          <span className="sr-only">Status</span>
                          <ClockIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          {appoitmentDetails.selectedTimeFormated}
                        </dd>
                      </div>
                    </dl>
                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6 flex justify-center">
                      {isLoadingSchedule ? (
                        isSuccess ? (
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-watsPrimary">
                            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </div>
                        ) : (
                          <SpinnerOverlay />
                        )
                      ) : (
                        <button
                          onClick={async () => {
                            setIsLoadingSchedule(true);

                            const cutomerUid = getCustomerUid(selectedCustomer);
                            try {
                              if (selectedCustomerBooked && !isFeatureEnabled('multi_book')) {
                                const response = await executeApiRequest(
                                  `${apiBaseUrl}/rescheduleBooking`,
                                  'POST',
                                  {
                                    staffId: selectedStaff,
                                    bizId: bizId,
                                    uid: cutomerUid,
                                    aptId: selectedService,
                                    startTimestamp: Number(selectedTime),
                                    prevEventId: selectedCustomerBooked.eventId,
                                  },
                                  user.accessToken,
                                );

                                const temp = 'breakpointHolder';
                              } else {
                                const response = await executeApiRequest(
                                  `${apiBaseUrl}/bookAppointment`,
                                  'POST',
                                  {
                                    staffId: selectedStaff,
                                    bizId: bizId,
                                    uid: cutomerUid,
                                    aptTypeId: selectedService,
                                    startTimestamp: Number(selectedTime),
                                  },
                                  user.accessToken,
                                );

                                const temp = 'breakpointHolder';
                              }
                            } catch (error) {
                              console.log(error);
                            }

                            // make is succes for 2 seconds
                            setIsSuccess(true);

                            executeApiRequest(
                              `${apiBaseUrl}/refreshDates`,
                              'POST',
                              {
                                bizId: bizId,
                                uid: user.uid,
                              },
                              user.accessToken,
                            )
                              .then(() => {
                                const endTime = performance.now();
                                // console.log(`API call took ${(endTime - startTime) / 1000} seconds.`);
                              })
                              .catch((error) => {
                                return;
                              });

                            setTimeout(() => {
                              toggleAppoitmentDialog();

                              setIsLoadingSchedule(false);
                              setSelectedTime(null);
                              setSelectedCustomer(null);
                              setSelectedCustomerBooked(null);
                            }, 2000);
                          }}
                          type="button"
                          className="rounded-md bg-[#126656] px-8 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                          {translate('schedule', language)}
                        </button>
                      )}
                      {/* <CircularProgress></CircularProgress> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!selectedTime && (
            <>
              <div className="my-4">
                <ComboBox
                  setShowRedRing={setShowRedRing}
                  showRedRing={showRedRing}
                  addCustomerOpen={addCustomerOpen}
                  setAddCustomerOpen={setAddCustomerOpen}
                  toggleAppoitmentDialog={toggleAppoitmentDialog}
                  setIsNewNumber={setIsNewNumber}
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                />
                <DropDown
                  title={translate('staff', language)}
                  choosenStaffId={selectedEvent ? selectedEvent.staffId : null}
                  updateStaff={setSelectedStaff}
                  staffList={bizOptions ? scheduleManager.getStaffList() : null}
                ></DropDown>

                {bizOptions && selectedStaff && (
                  <DropDownAptType
                    selected={selectedServiceValue}
                    setSelected={setSelectedServiceValue}
                    selectedStaff={selectedStaff}
                    title={translate('service', language)}
                    choosenServiceId={selectedEvent ? selectedEvent.aptTypeId : null}
                    updateSelectedService={setSelectedService}
                    servicesList={
                      bizOptions && selectedStaff
                        ? scheduleManager.getStaffServices(selectedStaff)
                        : null
                    }
                  />
                )}
              </div>

              <div>
                <div className="flex items-center" style={{ direction: 'ltr' }}>
                  <h2 className="flex-auto text-sm font-semibold text-gray-700">
                    {currentDate.toLocaleDateString(language + '-us', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </h2>
                  <button
                    onClick={prevMonth}
                    type="button"
                    className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={nextMonth}
                    type="button"
                    className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                  <div>{translate('sundayFormatted', language)}</div>
                  <div>{translate('mondayFormatted', language)}</div>
                  <div>{translate('tuesdayFormatted', language)}</div>
                  <div>{translate('wednesdayFormatted', language)}</div>
                  <div>{translate('thursdayFormatted', language)}</div>
                  <div>{translate('fridayFormatted', language)}</div>
                  <div>{translate('saturdayFormatted', language)}</div>
                </div>
                <div
                  className="mt-2 grid grid-cols-7 text-sm"
                  style={{ direction: language === 'en' ? 'ltr' : 'rtl' }}
                >
                  {days.map((day, dayIdx) => (
                    <div
                      key={day.date}
                      className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}
                    >
                      <button
                        disabled={
                          !day.isSelected &&
                          (!day.isCurrentMonth || day.isPastDay || !day.isAvailableDay)
                        }
                        type="button"
                        onClick={() => {
                          handleClickDate(day);

                          const updatedDays = days.map((elem) => {
                            if (elem.date === day.date) {
                              return { ...elem, isSelected: true };
                            } else if (elem.isSelected) {
                              const { isSelected, ...rest } = elem;
                              return rest;
                            }
                            return elem;
                          });

                          setDays(updatedDays);
                        }}
                        className={classNames(
                          day.isSelected && 'text-white',
                          // !day.isSelected && day.isToday && 'text-indigo-600',
                          !day.isSelected &&
                            (!day.isCurrentMonth || day.isPastDay || !day.isAvailableDay) &&
                            'text-gray-400',
                          !day.isSelected &&
                            day.isCurrentMonth &&
                            !day.isPastDay &&
                            day.isAvailableDay &&
                            'text-gray-700',

                          // day.isSelected && day.isToday && 'bg-indigo-600',
                          day.isSelected && 'bg-[#126656]',
                          !day.isSelected && 'hover:bg-gray-200',
                          (day.isSelected || day.isToday) && 'font-semibold',
                          'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                        )}
                      >
                        <time dateTime={day.date}>
                          {day.date.split('-').pop().replace(/^0/, '')}
                        </time>
                      </button>
                    </div>
                  ))}
                </div>
                <section className="mt-12">
                  {selectedDay && (
                    <h2 className="text-base font-semibold leading-6 text-gray-700">
                      {translate('scheduleFor', language)}{' '}
                      <time dateTime="2022-01-21">
                        {new Date(parseInt(selectedDay)).toLocaleDateString(language + '-us', {
                          month: 'long',
                          year: 'numeric',
                          day: '2-digit',
                        })}
                      </time>
                    </h2>
                  )}

                  <ul role="list" className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {timeOptions &&
                      timeOptions.map((timeSlot, personIdx) => (
                        <li key={timeSlot.time} className="flex justify-center">
                          <button
                            onClick={() => {
                              if (selectedCustomerBooked) {
                                setAlreadyScheduledTimeSlot(timeSlot.time);
                                setAlreadyScheduledOpen(true);
                                setMultiBookOpen(true);
                              } else if (!selectedCustomer) {
                                setScheduleNoCustomerOpen(true);
                                setShowRedRing(true);
                              } else {
                                setTimeout(() => {
                                  setSelectedTime(timeSlot.time);
                                }, 1500);
                              }
                            }}
                            type="button"
                            className={`group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2  shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#126656] focus:ring-offset-2 ${
                              language === 'en' ? 'text-left' : 'text-right'
                            }`}
                          >
                            <span className="flex min-w-0 flex-1 items-center space-x-3 px-3">
                              {/* Conditional rendering elements commented out */}
                              <span className="block min-w-0 flex-1">
                                <span className="block truncate text-md font-medium text-gray-700">
                                  {timeSlot.title}
                                </span>
                              </span>
                            </span>
                            <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                              {/* Icon or additional elements can be added here */}
                            </span>
                          </button>
                        </li>
                      ))}
                  </ul>
                </section>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
