/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useEffect, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { useAuth } from 'src/views/authentication/AuthContext'
import { translate } from 'src/translate'
import { useLanguage } from 'src/LanguageContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComboBox({ setSelectedCustomer, addNewCustomer, selectedCustomer, toggleAppoitmentDialog, setAddCustomerOpen, addCustomerOpen, showRedRing, setShowRedRing }) {
  const [query, setQuery] = useState('')
  const [selectedPerson, setSelectedPerson] = useState(null);
  const { language } = useLanguage();

  const { customers } = useAuth();

  const filteredPeople = query === ''
    ? customers
    : customers ? customers.filter((person) => {
      // Adjust the number by removing the first three characters and adding '0' at the start
      const adjustedNumber = '0' + person.number.slice(3);

      return person.name.toLowerCase().includes(query.toLowerCase())
        || adjustedNumber.includes(query.toLowerCase())
        || person.number.toLowerCase().includes(query.toLowerCase());
    })
      : null;

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedPerson(customers.filter(customer => customer.number === selectedCustomer)[0]);
    }
  }, []);

  return (
    <Combobox className='' as="div" value={selectedPerson} onChange={(val) => {

      if (showRedRing) {
        setShowRedRing(false);
      }

      if (val === "add-new-customer") {
        toggleAppoitmentDialog();
        setAddCustomerOpen(true);
      }
      else {
        setSelectedPerson(val);
        setSelectedCustomer(val.number);
      }

    }}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">{translate('chooseCustomer', language)}</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Button className='w-full'>
          <Combobox.Input
            className={`w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 shadow-sm ring-1 ring-inset ${showRedRing ? 'ring-red-400' : 'ring-gray-300'} focus:ring-2 focus:ring-inset focus:ring-watsPrimary outline-none sm:leading-6`}
            onChange={(event) => {
              if (showRedRing) {
                setShowRedRing(false);
              }
              setQuery(event.target.value)
            }}
            displayValue={(person) => person?.name}
          />
        </Combobox.Button>

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredPeople && filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

            <Combobox.Option
              key="add-new-customer"
              value="add-new-customer"
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-watsPrimary text-white' : 'text-gray-900'
                )
              }
            >
              {({ active }) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className={classNames('truncate ', active && 'text-white')}>
                      {translate('addNewCustomer', language)}                    </span>
                    <PlusIcon className={classNames('h-4 w-4 ml-1 ', active && 'text-white', !active && 'text-black')} />
                  </div>
                </div>
              )}
            </Combobox.Option>

            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.number}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? ' bg-watsPrimary text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className={`flex  items-center gap-1 ${language === 'en' ? 'justify-left' : 'justify-right'}`}>
                      <span className={classNames('truncate', selected && 'font-semibold')}>{person.name}</span>
                      <span
                        className={classNames(
                          ' truncate text-gray-500',
                          active ? 'text-white' : 'text-gray-500'
                        )}
                      >
                        {person.number}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-watsPrimary'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}

          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
