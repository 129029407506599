import { useState } from 'react';
import {
  CreditCardIcon,
  KeyIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CustomerDetails from './CustomerDetails';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function EditDetailsDialog({ open, setOpen, user, setProfileCardDialogOpen }) {
  // const [profileCardDialogOpen, setProfileCardDialogOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <ProfileCard
                selectedUser={user}
                setDialogOpen={setProfileCardDialogOpen}
                dialogOpen={profileCardDialogOpen}
            /> */}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        {/* <DialogTitle>Edit Your Details</DialogTitle> */}
        <DialogContent>
          <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
            <CustomerDetails
              user={user}
              setProfileCardDialogOpen={setProfileCardDialogOpen}
              handleClose={handleClose}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
