import { CircularProgress } from "@mui/material";
import "./spinner.css";

const Spinner = () => (

  <div className="fixed top-0 left-0 z-[1500] w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-50">
    {/* <div className={`px-16 py-6 bg-white rounded-xl flex flex-col justify-center items-center w-80`}> */}
    <div className=' z-[1600]'>
      <CircularProgress style={{ color: '#126656' }} size="5rem" />


    </div>
    {/* Set a fixed width or use a monospace font to ensure consistent spacing */}
    {/* <span ref={loadingRef} className="mt-4 font-mono" style={{ ...(language === 'en' ? { width: '75px'} : { width: '45px'}) }} >{translate('updating', language)}</span> */}
    {/* <span>ssnsjsjsjsj</span> */}
    {/* </div> */}
  </div>

);
export default Spinner;
