import { useEffect, useRef, useState } from 'react';
import { format, addDays, startOfDay, startOfWeek } from 'date-fns';
import ScheduleManager from 'src/book/src/data/ScheduleManager';
import Utils from 'src/book/src/data/Utils';
import { useAuth } from 'src/views/authentication/AuthContext';
import { useLanguage } from 'src/LanguageContext';

export default function CalendarWeekView({
  upcomingEvents,
  pastEvents,
  staffCalendarInfo,
  selectedStaff,
  startDate,
  setStartDate,
  setSelectedEvent,
  toggleAppoitmentDialog,
  setSelectedCustomer,
  customEvents,
  setSelectedVacation,
  toggleVacationDialog,
}) {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  const [events, setEvents] = useState([]);

  const { customers } = useAuth();
  const { language } = useLanguage();

  const getWeekDates = () => {
    const dates = [];
    // const startDatee = new Date();
    for (let i = 0; i < 7; i++) {
      const date = addDays(startDate ?? new Date(), i);
      dates.push({
        date,
        col: i + 1,
        isToday: format(date, 'yyyy-MM-dd') === format(startOfDay(new Date()), 'yyyy-MM-dd'),
      });
    }
    return dates;
  };

  const [weekDates, setWeekDates] = useState(getWeekDates());
  useEffect(() => {
    setWeekDates(getWeekDates());
  }, [startDate]);

  useEffect(() => {
    if (staffCalendarInfo && selectedStaff) {
      const weeklyEvents = ScheduleManager.getCalendarWeeklyEvents(
        upcomingEvents ? upcomingEvents : [],
        pastEvents ? pastEvents : [],
        selectedStaff,
        staffCalendarInfo,
        weekDates,
        customEvents,
      );

      setEvents(weeklyEvents);
    }
  }, [upcomingEvents, pastEvents, staffCalendarInfo, selectedStaff, weekDates, customEvents]);

  return (
    <>
      <div
        ref={containerNav}
        className="sticky top-0 z-10 flex-none bg-white shadow  ring-black ring-opacity-5 pr-8 "
      >
        <div className="-mr-px  grid-cols-7 divide-x divide-gray-100 border-r  border-gray-100 text-sm leading-6 text-gray-500 grid">
          <div className="col-end-1 w-14" />

          {weekDates.map((date, index) => (
            <div key={index} className="flex items-center justify-center py-3">
              <div className="flex flex-col items-center justify-center">
                <span className="">{format(date.date, 'EEE')}</span>

                {/* <span className=" text-xs">{translate(format(date.date, 'EEEE').toLocaleLowerCase(), language)}</span> */}

                <span
                  className={Utils.classNames(
                    'font-semibold text-gray-900 mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    date.isToday && 'bg-watsPrimary text-white',
                  )}
                >
                  {format(date.date, 'd')}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex h-full flex-col">
        <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
          <div className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full overflow-auto">
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div
                  className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                  id="calendarcnt"
                  style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                >
                  <div ref={containerOffset} className="row-end-1 h-7"></div>
                  <div>
                    <div
                      id="12AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      12AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="1AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      1AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="2AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      2AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="3AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      3AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="4AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      4AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="5AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      5AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="6AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      6AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="7AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      7AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="8AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      8AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="9AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      9AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="10AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      10AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="11AM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      11AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="12PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      12PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="1PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      1PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="2PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      2PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="3PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      3PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="4PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      4PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="5PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      5PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="6PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      6PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="7PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      7PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="8PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      8PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="9PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      9PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="10PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      10PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div
                      id="11PM"
                      className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400"
                    >
                      11PM
                    </div>
                  </div>

                  <div />
                </div>
                <div className="h-16 "></div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1   grid-rows-1 divide-x divide-gray-100 grid grid-cols-7">
                  <div className="col-start-1 row-span-full" />
                  <div className="col-start-2 row-span-full" />
                  <div className="col-start-3 row-span-full" />
                  <div className="col-start-4 row-span-full" />
                  <div className="col-start-5 row-span-full" />
                  <div className="col-start-6 row-span-full" />
                  <div className="col-start-7 row-span-full" />
                  <div className="col-start-8 row-span-full w-8" />
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid  grid-cols-7 pr-8"
                  style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
                >
                  {/* <div className="flex">
                    <div> */}
                  {events.map((event, index) => {
                    const diffInMinutes = Utils.diffInMinutes(event.startISO, event.endISO);

                    const currentCustomer = customers.filter((cust) => cust.id === event.uid)[0];

                    return (
                      <li
                        onClick={() => {
                          if (event.eventType === 'breaks') {
                            setSelectedVacation(event);
                            toggleVacationDialog();
                          } else if (event.aptTypeId && !Utils.hasTimePassed(event.startISO)) {
                            setSelectedEvent(event);
                            toggleAppoitmentDialog();
                            const customer = customers.filter((cust) => cust.id === event.uid)[0];
                            setSelectedCustomer(customer.number);
                          }
                        }}
                        key={index}
                        className={`relative mt-px flex ${event.col}`}
                        style={{
                          gridRow: Utils.calculateGridRow(event.start.dateTime, event.end.dateTime),
                        }}
                      >
                        <a
                          href="#"
                          style={{
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                          }}
                          className={`${language === 'en' ? '' : ''} ${
                            diffInMinutes > 0 && diffInMinutes < 30 ? 'p-2' : 'p-2'
                          }  group absolute inset-1 flex flex-col overflow-hidden  rounded-lg ${
                            event.bgColor
                          } p-2 text-xs leading-5 ${event.hoverBgColor}`}
                        >
                          <p
                            className={`order-1  ${event.titleColor} ${
                              diffInMinutes > 0 && diffInMinutes < 30 ? '' : ''
                            }`}
                          >
                            {diffInMinutes > 0 && diffInMinutes < 30
                              ? currentCustomer
                                ? currentCustomer.name
                                : event.summary.slice(0, 18)
                              : event.summary}
                          </p>
                        </a>
                      </li>
                    );
                  })}
                  {/* </div>

                    <div></div> */}
                  {/* </div> */}

                  {/* <li className="relative mt-px flex sm:col-start-3" style={{ gridRow: '74 / span 12' }}>
                                    <a
                                        href="#"
                                        className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
                                    >
                                        <p className="order-1 font-semibold text-blue-700">Breakfast</p>
                                        <p className="text-blue-500 group-hover:text-blue-700">
                                            <time dateTime="2022-01-12T06:00">6:00 AM</time>
                                        </p>
                                    </a>
                                </li>
                                <li className="relative mt-px flex sm:col-start-3" style={{ gridRow: '92 / span 30' }}>
                                    <a
                                        href="#"
                                        className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-pink-50 p-2 text-xs leading-5 hover:bg-pink-100"
                                    >
                                        <p className="order-1 font-semibold text-pink-700">Flight to Paris</p>
                                        <p className="text-pink-500 group-hover:text-pink-700">
                                            <time dateTime="2022-01-12T07:30">7:30 AM</time>
                                        </p>
                                    </a>
                                </li>
                                <li className="relative mt-px hidden sm:col-start-6 sm:flex" style={{ gridRow: '122 / span 24' }}>
                                    <a
                                        href="#"
                                        className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-gray-200"
                                    >
                                        <p className="order-1 font-semibold text-gray-700">Meeting with design team at Disney</p>
                                        <p className="text-gray-500 group-hover:text-gray-700">
                                            <time dateTime="2022-01-15T10:00">10:00 AM</time>
                                        </p>
                                    </a>
                                </li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
