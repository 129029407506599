import React, { useState } from 'react';
import { translate } from 'src/translate';
import { useLanguage } from 'src/LanguageContext';
import { PlusCircleIcon, CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import Utils from 'src/book/src/data/Utils';

export default function CustomChargeOption({ products, setProducts, currency }) {
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState(1);
  const { language } = useLanguage();

  const [errors, setErrors] = useState({
    description: false,
    price: false,
    quantity: false,
  });

  const addProduct = () => {
    let hasError = false;
    const newErrors = {
      description: !description,
      price: !price || isNaN(Number(price)) || Number(price) <= 0,
      quantity: !quantity || isNaN(Number(quantity)) || Number(quantity) <= 0,
    };

    // Check for any errors
    Object.keys(newErrors).forEach((key) => {
      if (newErrors[key]) {
        hasError = true;
      }
    });

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    const newProduct = {
      description,
      price: Number(price) * Number(quantity),
      quantity: Number(quantity),
      id: Math.random().toString(36).substr(2, 9), // unique id for key purposes
    };
    setProducts([...products, newProduct]);
    setDescription('');
    setPrice('');
    setQuantity(1);
    setErrors({
      description: false,
      price: false,
      quantity: false,
    });
  };
  const removeProduct = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const getCurrencySymbolLocal = (currency) => {
    if (currency === 'ILS') return `₪`;
    if (currency === 'USD') return `$`;
    if (currency === 'EUR') return `€`;
  };

  return (
    <div className="py-0">
      <div className="relative mt-3 flex gap-2 justify-center items-center">
        <input
          type="text"
          name="description"
          id="description"
          className={`block w-4/6 py-1.5 text-sm sm:leading-6 rounded-md border-0 ring-1 ring-inset ${
            errors.description ? 'ring-red-300' : 'ring-gray-300'
          } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary text-gray-900`}
          placeholder={translate('description', language)}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <input
          type="text"
          name="price"
          id="price"
          className={`block w-1/6 py-1.5 text-sm sm:leading-6 rounded-md border-0 ring-1 ring-inset ${
            errors.price ? 'ring-red-300' : 'ring-gray-300'
          } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary text-gray-900`}
          placeholder={Utils.getCurrencySymbol(currency).replace(/\./g, '')}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          onKeyDown={(e) => {
            // Allow backspace, tab, escape, enter, and numbers
            if (
              !/[\d]/.test(e.key) &&
              e.key !== 'Backspace' &&
              e.key !== 'Tab' &&
              e.key !== 'Escape' &&
              e.key !== 'Enter'
            ) {
              e.preventDefault();
            }
          }}
        />

        <input
          type="number"
          name="quantity"
          id="quantity"
          className={`block w-1/6 py-1.5 text-sm sm:leading-6 rounded-md border-0 ring-1 ring-inset ${
            errors.quantity ? 'ring-red-300' : 'ring-gray-300'
          } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary text-gray-900`}
          placeholder={translate('quantity', language)}
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />

        <button onClick={addProduct} className="flex-shrink-0  flex justify-center items-center  ">
          {/* <CheckCircleIcon className="h-6 w-6 text-white " /> */}

          {!products || products.length === 0 ? (
            <CheckCircleIcon className="h-5 w-5  text-gray-400 " />
          ) : (
            <PlusCircleIcon className="h-5 w-5  text-gray-400 " />
          )}

          {/* <span className="text-xs font-semibold leading-none align-middle" style={{ transform: 'translateY(-6%)' }}>+</span> */}
        </button>
      </div>

      {/* Render products list */}
      {products.map((product, index) => (
        <div key={product.id} className="flex items-center gap-x-3 mt-2">
          <div className="flex-grow">
            <div className="rounded-lg border border-gray-300 bg-white px-6 py-2 shadow-sm hover:border-gray-400 flex items-center space-x-3">
              <div className="flex flex-col min-w-0 flex-1">
                <div className="flex justify-between items-center w-full">
                  <p className="truncate text-sm text-gray-900">
                    <span className="font-semibold text-xs">{product.quantity}x </span>
                    {product.description}
                  </p>
                  <div className="flex items-center">
                    <p className="text-xs text-gray-500 cursor-pointer">{product.price}</p>
                    <span className={` ${language === 'en' ? 'ml-1' : 'mr-1'}`}>
                      {getCurrencySymbolLocal(currency)}{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => removeProduct(product.id)}
            className="flex-shrink-0 rounded-full  flex justify-center items-center "
          >
            <MinusCircleIcon className="h-5 w-5  text-red-800 " />

            {/* <span className="text-xs font-semibold leading-none align-middle" style={{ transform: 'translateY(-0%)' }}>-</span> */}
          </button>
        </div>
      ))}
    </div>
  );
}
