import { Fragment, useEffect, useRef, useState } from 'react';
import DialogWats from '../DialogWats';
import DropDownCircle from '../DropDownCircle';
import { Tabs, Tab } from '@mui/material';
import { translate } from 'src/translate';
import Bookings from '../Bookings';
import { useLanguage } from 'src/LanguageContext';
import { get, off, onValue, ref } from 'firebase/database';
import { database } from 'src/book/src/firebase';
import { useAuth } from 'src/views/authentication/AuthContext';
import AlertStyled from '../AlertStyled';
import { executeApiRequest } from 'src/book/src/utilities/utilities';
import { apiBaseUrl } from 'src/getConfig';
import Toggle from 'src/components/material-ui/components/Toggle';
import { Transition } from '@headlessui/react';
import CheckBox from 'src/components/material-ui/components/CheckBox';
import CustomChargeOption from './CustomChargeOption';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ChargeCustom({
  customToggle,
  setCustomToggle,
  setProducts,
  products,
  currency,
}) {
  const ref = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  const { language } = useLanguage();

  useEffect(() => {
    if (customToggle && ref.current) {
      setMaxHeight(`${ref.current.scrollHeight + 5}px`);
    } else {
      setMaxHeight('0px');
    }
  }, [customToggle, products]);

  return (
    <div
      className={classNames(
        'rounded-lg   p-4 transition-all',
        !customToggle && 'border border-gray-200',
        customToggle && 'bg-gray-100',
      )}
      style={{ maxHeight: `calc(${maxHeight} + 4rem)` }} // 4rem accounts for padding and any other static height
    >
      <CheckBox
        enabled={customToggle}
        setEnabled={setCustomToggle}
        title={translate('custom', language)}
      />

      <div
        ref={ref}
        className="overflow-hidden transition-all duration-600 ease-in-out"
        style={{ maxHeight }}
      >
        {customToggle && (
          <CustomChargeOption products={products} setProducts={setProducts} currency={currency} />
        )}
      </div>
    </div>
  );
}
