import React from 'react';
import { Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setDir, setLanguage } from 'src/store/customizer/CustomizerSlice';
import FlagEn from 'src/assets/images/flag/icon-flag-en.svg';
import FlagFr from 'src/assets/images/flag/icon-flag-fr.svg';
import FlagCn from 'src/assets/images/flag/icon-flag-cn.svg';
import FlagSa from 'src/assets/images/flag/icon-flag-sa.svg';

import { IconWorld } from '@tabler/icons';

import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLanguage } from 'src/LanguageContext';




const langTexts = {
  en: {
    title: "English (US)"
  },
  ar: {
    title: "عربي (Arabic)"
  },
  heb: {
    title: 'עברית (Hebrew)'

  }
}

const Languages = [
  {
    flagname: 'English (US)',
    icon: FlagEn,
    value: 'en',
  },
  {
    flagname: 'عربي (Arabic)',
    icon: FlagSa,
    value: 'ar',
  },
  {
    flagname: 'עברית (Hebrew)',
    icon: FlagCn,
    value: 'heb',
  },
  // {
  //   flagname: 'français (French)',
  //   icon: FlagFr,
  //   value: 'fr',
  // },

  // {
  //   flagname: 'عربي (Arabic)',
  //   icon: FlagSa,
  //   value: 'ar',
  // },
];

const Language = () => {
  const { language, switchLanguage, supportedLanguages } = useLanguage();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const customizer = useSelector((state) => state.customizer);

  const currentLang =
    Languages.find((_lang) => _lang.value === customizer.isLanguage) || Languages[1];
  const { i18n } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    i18n.changeLanguage(customizer.isLanguage);
  }, []);

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          marginTop:'2px'
        }}
      >
        <IconWorld color='#2a3547'/>
        {/* <Avatar src={currentLang.icon} alt={currentLang.value} sx={{ width: 20, height: 20 }} /> */}

      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            width: '200px',
          },
        }}
      >
        {supportedLanguages.map((option, index) => (
          <MenuItem
          style={{ justifyContent: 'center' }}
            key={index}
            sx={{ py: 2, px: 3 }}
            onClick={() => {
              switchLanguage(option);
              // dispatch(setDir(option.value === 'en' ? 'ltr' : 'rtl'))
              handleClose();
            }}>

            <Stack direction="row" spacing={1} alignItems="center">
              {/* <Avatar src={option.icon} alt={option.icon} sx={{ width: 20, height: 20 }} /> */}
              <Typography> {langTexts[option].title}</Typography>
            </Stack>


          </MenuItem>
        ))}

      </Menu>
    </>
  );
};

export default Language;
