import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useState, useRef, useEffect } from 'react';
import ColorPickerDropdown from './ColorPickerDropdown';
import { translate } from 'src/translate';
import { useLanguage } from 'src/LanguageContext';
import { PlusCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { useQuestionnaires } from 'src/views/authentication/QuestionnairesContex';
import AddEditTag from './AddEditTag';

function NewTag({
  setEditTagOpen,
  customerId,
  currentCustomerTags,
  answerId,
  updateTags,
  addTag,
  modifyTag,
  tagToModify = null
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!tagName || tagName === '') {
        return;
      }
      const tagId = addTag(tagName, selectedColor.hex);
      setEditTagOpen(false);
      updateTags(customerId, answerId, tagId, currentCustomerTags, 'add');
    }
  };

  const [editMode, setEditMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState({ id: 6, hex: '#618264' });
  const editContainerRef = useRef(null);
  const [tagName, setTagName] = useState('');

  const { language } = useLanguage();

  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);

  //   const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);

  // useEffect(() => {
  //     function handleClickOutside(event) {
  //         if (editContainerRef.current && !editContainerRef.current.contains(event.target)) {
  //             // setEditMode(false);
  //         }
  //     }

  //     // Bind the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //         // Unbind the event listener on clean up
  //         document.removeEventListener("mousedown", handleClickOutside);
  //     };
  // }, [editContainerRef]);

  return (
    <>
      <AddEditTag
        addEditDialogOpen={addEditDialogOpen}
        setAddEditDialogOpen={setAddEditDialogOpen}
        addTag={addTag}
        updateTags={updateTags}
        customerId={customerId}
        currentCustomerTags={currentCustomerTags}
        answerId={answerId}
        setEditTagOpen={setEditTagOpen}
      />
      <div className=" ">
        <div className="p-2 pb-0 flex justify-center">
          {!editMode ? (
            <div
              className="inline-flex items-center justify-center px-3 py-1 mb-2 text-xs font-semibold text-gray-800 cursor-pointer rounded-full border-dotted border-2 border-gray-400"
              onClick={
                () => setAddEditDialogOpen(true)

                // setEditMode(true)
              } // Set edit mode on click
            >
              <PlusIcon className="h-4 w-4 text-gray-400 mr-2" />
              {translate('addTag', language)}{' '}
            </div>
          ) : (
            <div ref={editContainerRef} className=" flex items-center gap-1">
              <ColorPickerDropdown
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />

              <input
                type="text"
                className="block w-4/6  py-0.5 text-xs sm:leading-6 rounded-md border-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary text-gray-900"
                autoFocus
                placeholder={translate('tagName', language)}
                value={tagName}
                onChange={(event) => {
                  setTagName(event.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <div
                className=" cursor-pointer"
                onClick={() => {
                  if (!tagName || tagName === '') {
                    return;
                  }
                  const tagId = addTag(tagName, selectedColor.hex);
                  setEditTagOpen(false);
                  updateTags(customerId, answerId, tagId, currentCustomerTags, 'add');
                }}
              >
                <CheckCircleIcon className="h-6 w-6 text-gray-400 " />
              </div>
            </div>
          )}
        </div>
      </div>{' '}
    </>
  );
}

export default NewTag;
