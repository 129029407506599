import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user, accessType } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user !== undefined && accessType !== undefined) {
      setIsLoading(false);
    }
  }, [user, accessType]); 

  if (isLoading) {
    return <div></div>;
  }

  if (!user || accessType === 'NORMAL') {
    return <Navigate to="/auth/login" />;
  }

  return children;
};

export default ProtectedRoute;
