// src/logging/SentryLogger.js

import * as Sentry from "@sentry/react";

import Logger from "./Logger";

class SentryLogger extends Logger {
    static instance = null;
    isEnabled = false; // Default state of Sentry logging

    constructor() {
        super();
        if (!SentryLogger.instance) {
            this.initializeSentry();
            SentryLogger.instance = this;
        }
        return SentryLogger.instance;
    }

    initializeSentry() {
        if (this.isEnabled) {
            // Console logs can be uncommented for debugging during development
            // console.log("Initializing Sentry for the first time");
            Sentry.init({
                dsn: "https://db67df0b3d6a9d70d3d60a6f51087a38@o4507306638508032.ingest.us.sentry.io/4507306640080896",
                integrations: [
                    // Check Sentry's documentation for correct integration setup for replay if available
                ],
                tracesSampleRate: 0.2, // Set this to a reasonable rate for your traffic and needs
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }
    }

    setEnabled(state) {
        this.isEnabled = state;
        // Reinitialize Sentry when toggling state to reflect the new setting
        this.initializeSentry();
    }

    info(message) {
        if (this.isEnabled) {
            Sentry.captureMessage(message);
        }
    }

    error(message) {
        if (this.isEnabled) {
            Sentry.captureException(new Error(message));
        }
        // Optionally, use console.error to log locally when Sentry is disabled
        // console.error(message);
    }

    debug(message) {
        if (this.isEnabled) {
            Sentry.captureMessage(message);
        }
    }
}

export default SentryLogger;
