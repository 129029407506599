export default function CheckBox({ enabled, setEnabled, title }) {
    const handleCheckboxChange = (event) => {
        setEnabled(event.target.checked);
    }

    return (
        <div className="flex items-center  gap-2">
            <div className="flex h-6 items-center">
                <input
                    id={title}
                    aria-describedby={`${title}-description`}
                    name={title}
                    type="checkbox"
                    checked={enabled}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 rounded border-gray-300 text-watsPrimary focus:outline-none focus:ring-1 focus:border-gray-300"
                />
            </div>
            <label htmlFor={title} className={`flex items-center ${enabled ? 'text-watsPrimary' : ''}`}>
                {title}
            </label>
        </div>
    );
}
