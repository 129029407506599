import React, { createContext, useContext, useEffect, useState } from 'react';
import { ref, onValue, get, set, remove, off, update } from 'firebase/database';
import { useAuth } from 'src/views/authentication/AuthContext';
import { database } from 'src/book/src/firebase';
import Utils from 'src/book/src/data/Utils';
import { useLanguage } from 'src/LanguageContext';

const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
  const { bizId } = useAuth();

  const { language } = useLanguage();
  // const [notifications, setNotifications] = useState([]);
  // const [archive, setArchive] = useState([]);

  const [customersData, setCustomersData] = useState([]);
  const [avgActivityDays, setAvgActivityDays] = useState(null);

  const [statusTagsCount, setStatusTagsCount] = useState({});

  const { customers } = useAuth();

  useEffect(() => {
    if (bizId) {
      const dbRef = ref(database, `biz/configs/${bizId}/avgActivityDays`);

      const listener = onValue(
        dbRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const info = snapshot.val();
            setAvgActivityDays(info);
          }
        },
        (error) => {
          console.error(error);
        },
      );

      // Clean up the listener when the component unmounts or bizId changes
      return () => listener();
    }
  }, [bizId, database]);

  const isAdvancedStatusEnabled = () => {
    if (!avgActivityDays) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const counterObject = {
      needsAssistance: 0,
      slightlyActive: 0,
      active: 0,
      notActive: 0,
    };

    if (customers && avgActivityDays) {
      customers.forEach((customer) => {
        const customerStatus = calculateCustomerStatus(customer);
        counterObject[customerStatus]++;
      });

      setStatusTagsCount(counterObject);
    }
  }, [customers, avgActivityDays]);

  const calculateCustomerStatus = (customerData, latestActivity) => {
    const oneDaysInMilliseconds = 1 * 24 * 60 * 60 * 1000; // 8 days in milliseconds
    const currentTime = Date.now();

    const { DateTime } = require('luxon');
    const nowUTC = DateTime.utc();
    const nowJerusalem = nowUTC.setZone('Asia/Jerusalem');
    const now = nowJerusalem.toMillis();

    const latestActivityTime = customerData.latestActivity
      ? Utils.convertUTCmillisToJerusalem(customerData.latestActivity)
      : null;

    if (!avgActivityDays) {
      return null;
    }

    // If user needs assistance, then they're marked as needing assistance no matter what
    if (customerData.userRequestedHelp) {
      return 'needsAssistance';
    }

    // This is for Rami Molla only for now
    // If a user responded to their last checkup/template,
    // we want to mark him as Active without considering the usual status calc

    const lastTemplateSentTimestamp = customerData.specialTemplate?.sentTimestamp
      ? Utils.convertUTCmillisToJerusalem(customerData.specialTemplate.sentTimestamp)
      : null;

    const lastTemplateSentResponseTimestamp = customerData.specialTemplate?.respondedTimestamp
      ? Utils.convertUTCmillisToJerusalem(customerData.specialTemplate.respondedTimestamp)
      : null;

    const daysDiffLatestActivityTime = (now - latestActivityTime) / (1000 * 60 * 60 * 24);

    if (
      customerData.tags &&
      customerData.tags.length > 0 &&
      customerData.tags.filter((id) => Object.keys(id)[0] === '-Nz3JXotGkaVoB-yeSiz')
    ) {
      // check if the feature is enabled
      if (lastTemplateSentTimestamp && lastTemplateSentResponseTimestamp) {
        // if we dont do this, the logic will always apply...
        // now the real check
        if (lastTemplateSentResponseTimestamp - lastTemplateSentTimestamp > 0) {
          // console.log('active');
          return 'active';
        } else {
          if (
            lastTemplateSentTimestamp &&
            now - lastTemplateSentTimestamp > oneDaysInMilliseconds
          ) {
            return 'slightlyActive';
          }

          return 'active';
        }
      } else {
        return 'active';
      }
    }

    // console.log(
    //   'response: ' + Utils.formatUnixTimestamp(lastTemplateSentResponseTimestamp, language),
    // );
    // console.log('sent: ' + Utils.formatUnixTimestamp(lastTemplateSentTimestamp, language));
    const prevEventTime = customerData.prevEventTime
      ? Utils.convertUTCmillisToJerusalem(customerData.prevEventTime)
      : null;
    const nextEventTime = customerData.nextEventTime
      ? Utils.convertUTCmillisToJerusalem(customerData.nextEventTime)
      : null;

    const maxTime = Math.max(prevEventTime || 0, nextEventTime || 0, latestActivityTime || 0);

    const daysDiff = (now - maxTime) / (1000 * 60 * 60 * 24);
    const score = daysDiff / avgActivityDays;

    if (score <= 1) {
      return 'active';
    } else if (score > 1 && score <= 2) {
      return 'slightlyActive';
    } else {
      return 'notActive';
    }
  };

  const markUserGotHelp = async (userId) => {
    const userRequestedHelpRef = ref(
      database,
      `/biz/userData/${bizId}/${userId}/userRequestedHelp`,
    );
    try {
      await remove(userRequestedHelpRef);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const markUserisActive = async (user) => {
    const now = new Date();
    const userRequestedHelpRef = ref(database, `/biz/userData/${bizId}/${user.id}/specialTemplate`);

    let respondedTimestamp = user.specialTemplate?.respondedTimestamp || null;
    let sentTimestamp = user.specialTemplate?.sentTimestamp || null;

    if (sentTimestamp && respondedTimestamp) {
      respondedTimestamp = sentTimestamp + 1;
    } else if (sentTimestamp && respondedTimestamp === null) {
      respondedTimestamp = sentTimestamp + 1;
    } else {
      sentTimestamp = Date.now();
      respondedTimestamp = sentTimestamp + 1;
    }

    try {
      await update(userRequestedHelpRef, { sentTimestamp, respondedTimestamp });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // useEffect(() => {
  //     if (bizId && avgActivityDays) {
  //         const dbRef = ref(database, `/biz/userData/${bizId}`);

  //         const listener = onValue(dbRef, (snapshot) => {
  //             if (snapshot.exists()) {
  //                 const info = snapshot.val();

  //                 let formattedInfo = Object.entries(info).map(([id, details]) => ({
  //                     id,
  //                     ...details
  //                 }));

  //                 formattedInfo = formattedInfo.filter(element => element.name);

  //                 const parsedInfo = formattedInfo.map(customerInfo => {

  //                     // parse the time stamps
  //                     try {

  //                         if (customerInfo.number?.startsWith("9725")) {
  //                             customerInfo.numberLocal = customerInfo.number.replace(/^972/, "0");
  //                         }

  //                     }
  //                     catch (e) {
  //                         e = e
  //                     }

  //                     if (customerInfo.prevEvent) {
  //                         customerInfo.prevEventTime = customerInfo.prevEvent.startTimestamp;
  //                     }

  //                     if (customerInfo.nextEvent) {
  //                         customerInfo.nextEventTime = customerInfo.nextEvent.startTimestamp;
  //                     }

  //                     customerInfo.status = calculateCustomerStatus(customerInfo);

  //                     return customerInfo;
  //                 })

  //                 setCustomersData(parsedInfo);
  //                 // setRows(formattedInfo);
  //                 // console.log(formattedInfo);
  //             } else {
  //                 // Handle the case where there is no data at the path
  //                 console.log('No data available customers');
  //                 setCustomersData([]); // Or however you wish to handle this case
  //             }
  //         }, (error) => {
  //             console.error(error);
  //         });

  //         // Clean up the listener when the component unmounts or bizId changes
  //         return () => listener();
  //     }
  // }, [avgActivityDays, bizId, database]);

  // const markNotificationAsRead = async (notificationId) => {
  //     const notificationRef = ref(database, `/notifications/biz/active/${bizId}/${notificationId}`);
  //     try {
  //         const timeAdded = new Date();
  //         await update(notificationRef, {
  //             readTimestamp: timeAdded.getTime(),
  //         })
  //     }
  //     catch (error) {
  //         console.log(error)

  //     }

  // };

  // // Function to fetch archived notifications
  // useEffect(() => {
  //     if (!bizId) {
  //         setArchive([]);
  //         return;
  //     }

  //     const archiveRef = ref(database, `/notifications/biz/archive/${bizId}`);

  //     const onValueChange = onValue(archiveRef, (snapshot) => {
  //         if (snapshot.exists()) {
  //             const archiveArray = Object.entries(snapshot.val()).map(([key, value]) => ({
  //                 customerId: value.details.uid,
  //                 time: key,
  //                 notificationId: key,
  //                 archive: true,
  //                 ...value
  //             }));
  //             archiveArray.sort((a, b) => parseInt(a.time) - parseInt(b.time));

  //             setArchive(archiveArray);
  //         } else {
  //             setArchive([]);
  //         }
  //     }, (error) => {
  //         console.error("Firebase read failed: ", error);
  //         setArchive([]);
  //     });

  //     // Return function to clean up the subscription
  //     return () => {
  //         off(archiveRef, 'value', onValueChange);
  //     };

  // }, [bizId]);

  // // Function to archive a notification
  // const archiveNotification = async (notificationId) => {
  //     const activeRef = ref(database, `/notifications/biz/active/${bizId}/${notificationId}`);
  //     const archiveRef = ref(database, `/notifications/biz/archive/${bizId}/${notificationId}`);

  //     try {
  //         const snapshot = await get(activeRef);
  //         if (snapshot.exists()) {
  //             await set(archiveRef, snapshot.val()); // Copy notification to archive
  //             await remove(activeRef); // Remove notification from active
  //         }
  //     } catch (error) {
  //         console.error('Failed to archive notification:', error);
  //     }
  // };

  return (
    <StatusContext.Provider
      value={{
        calculateCustomerStatus,
        isAdvancedStatusEnabled,
        markUserGotHelp,
        statusTagsCount,
        markUserisActive,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const useStatus = () => useContext(StatusContext);
