import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { CircularProgress } from '@mui/material'
import { useLanguage } from 'src/LanguageContext';

// Modify the component to accept props for dynamic content and actions
export default function AlertStyled({ isLoading,
    setIsLoading,
    title,
    description,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    open,
    setOpen,
    checkboxLabel,
    isCheckboxChecked,
    onCheckboxChange }) {
    // const [open, setOpen] = useState(true)



    const cancelButtonRef = useRef(null);

    const { language } = useLanguage();

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-[1600]" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full justify-center p-2 text-center items-center ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-0 scale-95"
                            enterTo="opacity-100 translate-y-0 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 scale-100"
                            leaveTo="opacity-0 translate-y-0 scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-lg p-6">
                                {isLoading ? (<div className=' flex justify-center items-center'>
                                    <CircularProgress />
                                </div>) : (<>
                                    <div className="">
                                        {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div> */}
                                        <div className={`mt-0  w-full ${language === 'en' ? 'text-left' : 'text-right'} `}>
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                {title}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <span className="text-sm text-gray-500">
                                                    {description}
                                                </span>

                                                {checkboxLabel && (
                                                    <div className="mt-2 flex items-center gap-x-1">
                                                        <input
                                                            type="checkbox"
                                                            checked={isCheckboxChecked}
                                                            onChange={onCheckboxChange}
                                                            className="h-4 w-4 text-watsPrimary focus:ring-watsPrimary border-gray-300 rounded"
                                                        />
                                                        <label htmlFor="checkbox" className=" text-sm text-gray-500">
                                                            {checkboxLabel}
                                                        </label>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 flex justify-center gap-2">
                                        {primaryButtonLabel && <button
                                            type="button"
                                            className="inline-flex  justify-center  whitespace-nowrap rounded-md max-w-16 bg-watsPrimary px-4 py-2 text-sm font-medium text-white shadow-sm  "
                                            onClick={async () => {
                                                await onPrimaryButtonClick();
                                                setOpen(false);
                                            }}
                                        >
                                            {primaryButtonLabel}
                                        </button>}
                                        {secondaryButtonLabel && <button
                                            type="button"
                                            className={` inline-flex max-w-16 justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                                            onClick={async () => {
                                                await onSecondaryButtonClick();
                                                setOpen(false);
                                            }}
                                            ref={cancelButtonRef}
                                        >
                                            {secondaryButtonLabel}
                                        </button>}
                                    </div>
                                </>)}


                            </Dialog.Panel>

                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
