import React, { useState, useRef, useEffect } from 'react';
import { PencilIcon, PlusIcon, TrashIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import NewTag from './NewTag';

export default function TagsView({
  tags,
  answer,
  customerId,
  answerId,
  currentCustomerTags,
  userAvailableTags,
  updateTags,
  setIsOpen,
  addTag,
  modifyTag,
  tagToModify,
}) {
  return (
    <>
      {/* {
                answer?.tags?.length > 0 && answer?.tags.map((tag, index) => {
                    const tagKey = Object.keys(tag)[0];
                    const tagData = tags.find(tagD => tagD.value === tagKey);

                    return (
                        <React.Fragment key={tagKey + 'Av'}>
                            <div key={tagKey} className="mb-1 flex justify-center">
                                <div style={{ backgroundColor: tagData?.color }} className="px-3 py-1 text-xs font-semibold text-white rounded-full inline-block">
                                    {tagData?.label}

                                </div>

                                <div onClick={() => {
                                    updateTags(customerId, answerId, tagKey, currentCustomerTags, 'delete');
                                    setIsOpen(false);
                                }}>
                                    <TrashIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
                                </div>
                            </div>


                        </React.Fragment>
                    );
                })
            } */}

      {userAvailableTags?.length > 0 &&
        userAvailableTags?.map((tag, index) => {
          const tagKey = Object.keys(tag)[0];
          const tagData = tags.find((tagD) => tagD.value === tagKey);

          return (
            <React.Fragment key={tagKey + 'Av'}>
              <div
                key={tagKey + 'Av'}
                className="mb-1 flex justify-center cursor-pointer"
                onClick={() => {
                  updateTags(customerId, answerId, tagKey, currentCustomerTags, 'add');
                  setIsOpen(false);
                }}
              >
                <div
                  style={{ backgroundColor: tagData?.color }}
                  className="px-3 py-1 text-xs font-semibold text-white rounded-full inline-block"
                >
                  {tagData?.label}
                </div>

                {/* <div onClick={() => {
                        updateTags(customerId, answerId, tagKey, currentCustomerTags, 'add');
                        setIsOpen(false);
                    }}>

                        <PlusIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
                    </div> */}
              </div>
            </React.Fragment>
          );
        })}

      <NewTag
        customerId={customerId}
        answerId={answerId}
        currentCustomerTags={currentCustomerTags}
        setEditTagOpen={setIsOpen}
        addTag={addTag}
        updateTags={updateTags}
        modifyTag={modifyTag}
        tagToModify={tagToModify}
      />
    </>
  );
}
