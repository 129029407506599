import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import DashboardCard from '../shared/DashboardCard'
import ScheduleAppointment from './ScheduleAppointment'
import { DateTime } from 'luxon';

import ScheduleAppointmentDialog from './ScheduleAppointmentDialog'
import { startOfWeek, startOfMonth, endOfMonth, eachDayOfInterval, endOfWeek, format, addMonths, subMonths, startOfDay, addDays } from 'date-fns'
import { database } from 'src/book/src/firebase'
import { useAuth } from 'src/views/authentication/AuthContext'
import { ref, onValue, off, get } from 'firebase/database';
import ScheduleManager from 'src/book/src/data/ScheduleManager'
import DropdownMenu from 'src/layouts/full/horizontal/DropdownMenu'
import { useLanguage } from 'src/LanguageContext'
import Slider from "react-slick";
import { translate } from 'src/translate'
import { ThemeSettings } from 'src/theme/Theme'
import { AppBar, Fab, IconButton, Stack, Toolbar, Tooltip, styled, useMediaQuery } from '@mui/material'
import AddCustomer from './AddCustomer'
import AlertStyled from './AlertStyled'
import { IconMenu2 } from '@tabler/icons'
import { toggleMobileSidebar, toggleSidebar } from 'src/store/customizer/CustomizerSlice'
import { useSelector, useDispatch } from 'react-redux';
import Language from 'src/layouts/full/vertical/header/Language'
import Notification from './Notification'
import { executeApiRequest } from 'src/book/src/utilities/utilities'
import { apiBaseUrl } from 'src/getConfig'
import CalendarWeekView from './Appointments/CalendarWeekView'
import Utils from 'src/book/src/data/Utils'
import DateNavigator from './Appointments/DateNavigator'
import { addWeeks } from 'date-fns'
import Notifications from 'src/layouts/full/vertical/header/Notifications'

function ScheduleAppointmentWrapper({ appoitmentDialogOpen, toggleAppoitmentDialog, setSelectedCustomer, selectedCustomer }) {



    const { customers } = useAuth();

    const { bizId, user, accessType,isFeatureEnabled  } = useAuth();
    const { language } = useLanguage();


    const [events, setEvents] = React.useState(null);
    const [pastEvents, setPastEvents] = React.useState(null);



    const [selectedStaff, setSelectedStaff] = React.useState(null);

    const [availableStaff, setAvailableStaff] = React.useState(null);


    const [staffCalendarInfo, setStaffCalendarInfo] = React.useState(null);

    // const [appoitmentDialogOpen, setAppoitmentDialogOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState(null);

    const [addCustomerOpen, setAddCustomerOpen] = React.useState(false);
    const [scheduleNoCustomerOpen, setScheduleNoCustomerOpen] = React.useState(false);
    const [isCancelUserLoading, setIsCancelUserLoading] = React.useState(false);

    const [cancelAppointmentOpen, setCancelAppointmentOpen] = React.useState(false);


    const [selectedCustomerBooked, setSelectedCustomerBooked] = React.useState(null);

    const [alreadyScheduledOpen, setAlreadyScheduledOpen] = React.useState(false);

    const [multiBookOpen, setMultiBookOpen] = React.useState(false);


    const [alreadyScheduledTimeSlot, setAlreadyScheduledTimeSlot] = React.useState(null);

    const [selectedTime, setSelectedTime] = React.useState(null);

    const [userPaidDeposit, setUserPaidDeposit] = React.useState(false);

    const [forceRefund, setForceRefund] = React.useState(false);
    const toggleForceRefund = () => {
        setForceRefund(current => !current); // Toggle the state
    };



    useEffect(() => {
        if (bizId && selectedStaff && staffCalendarInfo) {
            // Define path references for current and past events
            const currentEventsRef = ref(database, `/events/biz/${bizId}`);
            const pastEventsRef = ref(database, `/events/past/biz/${bizId}`);

            // Listener for current events
            const currentEventListener = onValue(currentEventsRef, (snapshot) => {
                if (snapshot.exists()) {
                    // Process and update current events
                    // const currentEventsData2 = ScheduleManager.flattenEvents(snapshot.val(), 'upcomingEvent');

                    const currentEventsData = ScheduleManager.flattenEvents(snapshot.val(), 'upcomingEvent');
                    setEvents(currentEventsData);
                }
                else {
                    setEvents([]);

                }
            }, { onlyOnce: false });

            // Listener for past events
            const pastEventListener = onValue(pastEventsRef, (snapshot) => {
                if (snapshot.exists()) {
                    // Process and update past events
                    const currentEventsData = ScheduleManager.flattenEvents(snapshot.val(), 'pastEvent');
                    setPastEvents(currentEventsData);
                }
                else {
                    setPastEvents([]);
                }
            }, { onlyOnce: false });

            // Cleanup function to unsubscribe from the listeners when the component unmounts or dependencies change
            return () => {
                off(currentEventsRef, 'value', currentEventListener);
                off(pastEventsRef, 'value', pastEventListener);
            };
        }
    }, [bizId, selectedStaff, staffCalendarInfo, database]);


    React.useEffect(() => {
        const fetchStaffInfo = async () => {
            try {
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const dbRef = ref(database, `/biz/configs/${bizId}/staff`);
                const snapshot = await get(dbRef);

                if (snapshot.exists()) {
                    const info = snapshot.val();

                    for (const day of daysOfWeek) {
                        for (const staff of Object.keys(info)) {
                            if (!info[staff]['workingHours']) {
                                info[staff]['workingHours'] = {};
                            }
                            if (!info[staff]['workingHours'][day]) {
                                info[staff]['workingHours'][day] = [];
                            }
                        }
                    }

                    const yestrday = new Date();
                    yestrday.setUTCHours(0, 0, 0, 0);
                    yestrday.setDate(yestrday.getDate() - 1);

                    const allStaff = [];

                    for (const staff of Object.keys(info)) {
                        allStaff.push({ id: staff, title: info[staff]['title'] });

                        if (!info[staff]['specificOOOHours']) {
                            info[staff]['specificOOOHours'] = {};
                        }
                        else {
                            info[staff]['specificOOOHours'] = Object.keys(info[staff]['specificOOOHours']).reduce((acc, timestamp) => {


                                const utcDate = DateTime.fromMillis(parseInt(timestamp), { zone: 'utc' });

                                const date = utcDate.setZone('Asia/Jerusalem');


                                const formattedDate = date.toFormat('dd/MM/yyyy');

                                if (info[staff]['specificOOOHours'][timestamp].closed) {
                                    acc[formattedDate] = [];
                                    return acc;
                                }

                                if (date <= yestrday || !info[staff]['specificOOOHours'][timestamp]) {
                                    return acc;
                                }

                                acc[formattedDate] = info[staff]['specificOOOHours'][timestamp].map(slot => ({
                                    start: slot.start,
                                    end: slot.end,
                                }));

                                return acc;
                            }, {});
                        }

                    }

                    const selectedStaff = Object.entries(info).find(([key, value]) => value.uid === user.uid) === undefined ?
                        Object.entries(info)[0][0] : Object.entries(info).find(([key, value]) => value.uid === user.uid)[0]

                    info['selectedStaff'] = selectedStaff;

                    setSelectedStaff(selectedStaff);
                    setStaffCalendarInfo(info);
                    setAvailableStaff(allStaff);

                    // setSelectedDays(info[selectedStaff]['workingHours']);
                    // setScheduleData(info[selectedStaff]['specificOOOHours']);

                    // console.log(`setStaffCalendarInfo is triggered with: ${selectedStaff} ${bizId} and ${JSON.stringify(info, 2)}`);

                }
            } catch (error) {
                console.error(error);
            }
        };
        if (bizId) {
            fetchStaffInfo();
        }
    }, [bizId, accessType]);






    return (
        <div className={`${language === 'ar' ? 'font-cairo' : ''}`}>

            <AddCustomer open={addCustomerOpen} setOpen={setAddCustomerOpen} setSelectedCustomer={setSelectedCustomer} toggleAppoitmentDialog={toggleAppoitmentDialog} />

            <AlertStyled
                title={translate('confirmAction', language)}
                description={translate('scheduleWithoutCustomer', language)}
                primaryButtonLabel={translate('yes', language)}
                secondaryButtonLabel={null}
                onPrimaryButtonClick={() => console.log('Primary action selected.')}
                onSecondaryButtonClick={() => console.log('Action cancelled.')}
                open={scheduleNoCustomerOpen}
                setOpen={setScheduleNoCustomerOpen}
            />

            <AlertStyled
                isLoading={isCancelUserLoading}
                setLoading={setIsCancelUserLoading}
                title={translate('cancelAppointmentTitle', language)}
                description={translate('cancelAppointmentDescription', language)}
                primaryButtonLabel={translate('yes', language)}
                secondaryButtonLabel={translate('cancel', language)}
                onPrimaryButtonClick={async () => {
                    try {
                        setIsCancelUserLoading(true);

                        const response = await executeApiRequest(`${apiBaseUrl}/cancelBooking`, 'POST', {
                            bizId: selectedCustomerBooked.bizId,
                            uid: selectedCustomerBooked.uid,
                            eventId: selectedCustomerBooked.eventId,
                            shouldForceRefund: forceRefund,
                        }, user.accessToken);

                        setIsCancelUserLoading(false);
                        toggleAppoitmentDialog();
                        setSelectedCustomer(null);
                        setSelectedCustomerBooked(null);

                        // Handle the response as needed
                    } catch (error) {
                        // Handle any errors
                    } finally {
                        const xx = 'dd';
                    }
                }}
                onSecondaryButtonClick={() => console.log('Action cancelled.')}
                open={cancelAppointmentOpen}
                setOpen={setCancelAppointmentOpen}
                checkboxLabel={userPaidDeposit ? translate('refundUserDeposit', language) : null}
                isCheckboxChecked={forceRefund}
                onCheckboxChange={toggleForceRefund}
            />

            {!isFeatureEnabled('multi_book') && <AlertStyled
                title={translate('alreadyScheduledCustomerTitle', language)}
                description={selectedCustomerBooked ? ScheduleManager.getCustomerAlreadyScheduledTxt(selectedCustomerBooked.startTimestamp, language) : ''}
                primaryButtonLabel={translate('schedule', language)}
                secondaryButtonLabel={translate('no', language)}
                onPrimaryButtonClick={() => {
                    setSelectedTime(alreadyScheduledTimeSlot);
                    setAlreadyScheduledOpen(false);
                    setAlreadyScheduledTimeSlot(null);

                }}
                onSecondaryButtonClick={() => {
                    setAlreadyScheduledOpen(false);
                    setAlreadyScheduledTimeSlot(null);
                }}

                open={alreadyScheduledOpen}
                setOpen={setAlreadyScheduledOpen}
            />}

            {isFeatureEnabled('multi_book') && <AlertStyled
                title={translate('scheduleAnotherAptTitle', language)}
                description={translate('scheduleAnotherAptText', language)}
                primaryButtonLabel={translate('schedule', language)}
                secondaryButtonLabel={translate('no', language)}
                onPrimaryButtonClick={() => {

                    setSelectedTime(alreadyScheduledTimeSlot);

                    // setSelectedTime(alreadyScheduledTimeSlot);
                    // setAlreadyScheduledOpen(false);
                    // setAlreadyScheduledTimeSlot(null);

                }}
                onSecondaryButtonClick={() => {
                    // setAlreadyScheduledOpen(false);
                    // setAlreadyScheduledTimeSlot(null);
                }}

                open={multiBookOpen}
                setOpen={setMultiBookOpen}
            />}

            <ScheduleAppointment
                // cancel appointment dialog
                cancelAppointmentOpen={cancelAppointmentOpen}
                setCancelAppointmentOpen={setCancelAppointmentOpen}

                // 
                setAlreadyScheduledTimeSlot={setAlreadyScheduledTimeSlot}


                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}

                alreadyScheduledOpen={alreadyScheduledOpen}
                setAlreadyScheduledOpen={setAlreadyScheduledOpen}

                setMultiBookOpen={setMultiBookOpen}

                selectedCustomerBooked={selectedCustomerBooked}
                setSelectedCustomerBooked={setSelectedCustomerBooked}

                customers={customers}
                upcomingEvents={events}

                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}

                setScheduleNoCustomerOpen={setScheduleNoCustomerOpen}

                addCustomerOpen={addCustomerOpen}
                setAddCustomerOpen={setAddCustomerOpen}

                isOpen={appoitmentDialogOpen}
                toggleAppoitmentDialog={toggleAppoitmentDialog}

                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}

                setUserPaidDeposit={setUserPaidDeposit} />

        </div>

    )
}

export default ScheduleAppointmentWrapper;