import { useState } from 'react';
import {
  CreditCardIcon,
  KeyIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import BirthdayPicker from './BirthdayPicker';
import { useAuth } from 'src/views/authentication/AuthContext';
import { useLanguage } from 'src/LanguageContext';
import { translate } from 'src/translate';
import { useQuestionnaires } from 'src/views/authentication/QuestionnairesContex';
// import ProfileCard from '../profile/ProfileCard';

export default function EditAnswer({ user, handleClose, questionnaireId, answerId }) {
  const [customerName, setCustomerName] = useState(user.name);

  const { language } = useLanguage();

  const {
    getCustomerQuestionnaire,
    questions,
    allQuestionnairesData,
    updateCustomerQuestionnaireAnswer,
  } = useQuestionnaires();

  const customerAnswer = getCustomerQuestionnaire(user.id, questionnaireId, answerId);

  const questionsKeys = Object.keys(customerAnswer);

  const getQuestion = (questionId) => {
    if (questions[questionId][language]) {
      return questions[questionId][language];
    } else {
      return questions[questionId][Object.keys(questions[questionId])[0]];
    }
  };

  const getOptionText = (lstString) => {
    if (lstString[language]) {
      return lstString[language];
    } else {
      return lstString[Object.keys(lstString)[0]];
    }
  };

  const [customerAnswerVar, setCustomerAnswerVar] = useState(customerAnswer);

  // if (!allQuestionnairesData) return <></>

  // this is used for buttons in order to know the other available options

  const questionsButtonOptions = allQuestionnairesData.questions;

  // const [genericDetails, setGenericDetails] = useState(user.genericDetails);

  // const [profileCardDialogOpen, setProfileCardDialogOpen] = useState(false);

  const handleInputChange = (key, value, btnOptionId = null) => {
    setCustomerAnswerVar((prevDetails) => ({
      ...prevDetails,
      [key]: {
        ...prevDetails[key],
        val: value,
        ...(btnOptionId && { btnOption: btnOptionId }), // Conditionally add btnOption only if btnOptionId is provided
      },
    }));
  };

  return (
    <>
      <div>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-4">
            <div className="divide-gray-900 border-b border-gray-900/10 pb-2">
              <h3 className=" text-lg text-center font-semibold leading-6 text-gray-900">
                {translate('editQuestionnaire', language)}
              </h3>
              {/* <p className="mt-1 text-sm text-gray-500">
                            This information will be displayed publicly so be careful what you share.
                        </p> */}
            </div>

            <div className="grid grid-cols-3 gap-6">
              {/* 
                            <div className="col-span-6 sm:col-span-3 border-b border-gray-900/10">
                            </div> */}

              {questionsKeys &&
                questionsKeys.map((key) => {
                  if (customerAnswerVar[key].isButton) {
                    const options = questionsButtonOptions[key].btnOptions;
                    return (
                      <div className="col-span-6 sm:col-span-1" key={key}>
                        <label
                          htmlFor={key}
                          className={`block text-sm ${
                            language === 'en' ? 'text-left' : 'text-right'
                          } font-medium leading-4 text-gray-900`}
                        >
                          {getQuestion(key)}
                        </label>
                        <select
                          id={key}
                          value={customerAnswerVar[key].val}
                          onChange={(e) => {
                            const selectedOption = options.find(
                              (option) => getOptionText(option.lstr.strings) === e.target.value,
                            );
                            handleInputChange(key, e.target.value, selectedOption.btnId);
                          }}
                          className="mt-2 block w-full text-center rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6"
                        >
                          {options.map((option) => (
                            <option key={option.btnId} value={getOptionText(option.lstr.strings)}>
                              {getOptionText(option.lstr.strings)}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  } else {
                    return (
                      <div className="col-span-6 sm:col-span-1" key={key}>
                        <label
                          htmlFor={key}
                          className={`block text-sm ${
                            language === 'en' ? ' text-left' : 'text-right'
                          }  font-medium leading-4 text-gray-900`}
                        >
                          {/* {getGenericDetailsColumnHeader(key, language)} */}
                          {getQuestion(key)}
                        </label>
                        <textarea
                          name={key}
                          id={key}
                          value={customerAnswerVar[key].val}
                          className="mt-2 block w-full text-center rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary sm:text-sm sm:leading-6 resize-y overflow-auto"
                          onChange={(e) => handleInputChange(key, e.target.value)}
                          style={{ height: 'auto', maxHeight: '400px' }}
                          onInput={(e) => {
                            // e.target.style.height = 'auto';
                            // e.target.style.height = `${e.target.scrollHeight}px`;
                          }}
                        />
                      </div>
                    );
                  }
                })}

              {/* <div className="col-span-3 sm:col-span-2">

                            <label htmlFor="company-website" className="block text-sm font-medium leading-6 text-gray-900">
                                Birthday
                            </label>
                            <div className=' w-4/5'>

                                <BirthdayPicker />
                            </div>


                        </div>

                        <div className="col-span-6 sm:col-span-3 border-b border-gray-900/10">
                        </div> */}

              {/* <div className="col-span-6 sm:col-span-1 ">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Phone
                            </label>
                            <input
                                disabled={true}
                                type="text"
                                name="phone-number"
                                id="phone-number"
                                placeholder={user.number}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-watsPrimary   sm:text-sm sm:leading-6"
                            />
                        </div>


                        <div className="col-span-6 sm:col-span-3 border-b border-gray-900/10">
                        </div> */}
            </div>
          </div>

          <div
            className={`bg-gray-50 px-4 py-3 ${
              language === 'en' ? 'text-center' : 'text-center'
            }  sm:px-6`}
          >
            <button
              onClick={() => {
                // if (user.name !== customerName) {
                //     changeCustomerName(user.id, customerName);
                // }

                // if (genericDetails) {
                //     updateCustomerGenericDetails(user.id, genericDetails);

                // }

                updateCustomerQuestionnaireAnswer(
                  user.id,
                  questionnaireId,
                  answerId,
                  customerAnswerVar,
                );

                handleClose();

                // setProfileCardDialogOpen(true);
              }}
              type="submit"
              className="inline-flex justify-center rounded-md bg-watsPrimary px-12 py-2 text-sm font-semibold text-white shadow-sm hover:bg-watsPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-watsPrimary"
            >
              {translate('save', language)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
